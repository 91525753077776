import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { Avatar, Button, Card as DefaultCard, useTheme } from 'react-native-paper';

import Col from '../layouts/Col';
import Config from '../../../Config';

function Card(props) {
  const icon = props.hasOwnProperty('icon') && typeof props.icon === 'string' ? props.icon : null;
  const iconBgColor = props.hasOwnProperty('iconBgColor') && typeof props.iconBgColor === 'string' ? props.iconBgColor : null;
  const avatar = props.hasOwnProperty('avatar') && typeof props.avatar === 'string' ? props.avatar : null;
  const actions = props.hasOwnProperty('actions') && Array.isArray(props.actions) ? props.actions : [];
  const content = props.hasOwnProperty('children') && typeof props.children !== 'undefined' ? props.children : null;
  const contentLeftPaddingDisabled = props.hasOwnProperty('contentLeftPaddingDisabled') && props.contentLeftPaddingDisabled === true;
  const theme = useTheme();
  const styles = StyleSheet.create({
    card: {
      flex: 1,
      height: Dimensions.get('window').width >= Config.dimensions.md ? '100%' : null,
      minHeight: Dimensions.get('window').width >= Config.dimensions.md ? ( actions.length > 0 ? 120 : null ) : null,
    },
    buttonCol: {
      paddingLeft: 2,
      paddingRight: 2,
      paddingBottom: 8
    },
    content: {
      paddingTop: props.hasOwnProperty('subtitle') ? 10 : 0,
      paddingBottom: 16,
      paddingLeft: contentLeftPaddingDisabled ? 0 : 72,
      paddingRight: 0,
      marginTop: -15,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    icon: {
      backgroundColor: iconBgColor !== null ? iconBgColor : theme.colors.primary
    }
  });
  const leftContent = function (props) {
    if (avatar !== null) {
      return <Avatar.Image {...props} source={{ uri: avatar }} />;
    }
    if (icon !== null) {
      return <Avatar.Icon {...props} icon={icon} style={styles.icon} />;
    }
    return null;
  };

  return (
    <DefaultCard mode="elevated" elevation={5} style={styles.card}>
      { props.hasOwnProperty('cover') && typeof props.cover !== 'undefined' && props.cover !== null ? (
        <DefaultCard.Cover source={{ uri: props.cover }} />
      ) : <></> }
      <DefaultCard.Title title={props.title} subtitle={props.hasOwnProperty('subtitle') ? props.subtitle : null} left={icon !== null || avatar !== null ? leftContent : null} />
      {
        content !== null ? (
          <View style={styles.content}>
            {content}
          </View>
        ) : null
      }
      {
        actions.length > 0 ? (
          <View>
            { actions.length === 1 ? (
              <DefaultCard.Actions>
                <Col style={styles.buttonCol} xs={12} sm={12} md={12} lg={12}>
                  <Button mode={actions[0].mode} onPress={actions[0].onPress}>{ actions[0].title }</Button>
                </Col>
              </DefaultCard.Actions>
            ) : <></> }
            { actions.length > 1 ? (
              <DefaultCard.Actions>
                { actions.map((action, actionKey) => (
                  <Col style={styles.buttonCol} xs={12 / actions.length} sm={12 / actions.length} md={12 / actions.length} lg={12 / actions.length} key={actionKey}>
                    <Button mode={action.mode} onPress={action.onPress}>{ action.title }</Button>
                  </Col>
                )) }
              </DefaultCard.Actions>
            ) : <></> }
          </View>
        ) : null
      }
    </DefaultCard>
  );
}

export default Card;

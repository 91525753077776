import React from 'react';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import { Provider as ReactReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { AppearanceProvider } from 'react-native-appearance';

import Store from './src/redux/Store';
import App from './App';

const persistor = persistStore(Store);

function Main() {
  return (
    <ReactReduxProvider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppearanceProvider>
          <App />
        </AppearanceProvider>
      </PersistGate>
    </ReactReduxProvider>
  );
}

registerRootComponent(Main);

import React, { useEffect, useState } from 'react';
import { Button, DataTable } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Col from '../atoms/layouts/Col';
import Row from '../atoms/layouts/Row';
import View from '../atoms/layouts/View';
import TextInput from '../atoms/inputs/TextInput';
import LocaleInput from '../molecules/inputs/LocaleInput';
import Translator from '../../modules/Translator';
import Languages from '../../modules/Languages';
import {
  changeIsLoading,
  changeSelect,
  changeTranslations,
  createSelect,
} from '../../redux/reducers/PageReducer';
import { prepareLocale } from '../../modules/Helper';

function Translations(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.page.translations);
  const selects = useSelector((state) => state.page.selects);
  const [disabledForm, setDisabledForm] = useState(true);
  const [name, setName] = useState('');
  const languages = Languages();
  const translationsId = props.route.params.id;
  const input = translations.hasOwnProperty(translationsId) && translations[translationsId].hasOwnProperty('input') ? translations[translationsId].input : TextInput;
  const value = translations.hasOwnProperty(translationsId) && translations[translationsId].hasOwnProperty('value') ? translations[translationsId].value : null;
  const handleSubmit = function () {
    if (!selects.hasOwnProperty(translationsId) || !selects[translationsId].hasOwnProperty('value') || selects[translationsId].value === null) {
      alert(Translator.t('pages.Translations.other.error.missingRequiredField'));
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    dispatch(changeTranslations({
      id: translationsId,
      translations: {
        value: {
          ...value,
          [selects[translationsId].value]: name,
        },
      },
    }));
    setName('');
    dispatch(changeSelect({
      id: translationsId,
      select: {
        value: null,
      },
    }));
    setDisabledForm(false);
    dispatch(changeIsLoading(false));
  };

  useEffect(() => {
    const options = [];
    for (const [locale, text] of Object.entries(languages.getNames(Translator.locale))) {
      if (value !== null && value.hasOwnProperty(locale)) {
        continue;
      }
      options.push({
        label: text,
        value: locale,
      });
    }
    dispatch(createSelect({
      id: translationsId,
      select: {
        options,
        enableSearch: true,
      },
    }));
    setDisabledForm(false);
  }, [value]);

  return (
    <View>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          { React.createElement(input, {
            value: name,
            onChange(name) {
              setName(name);
            },
            disabled: disabledForm,
          }) }
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <LocaleInput value={selects.hasOwnProperty(translationsId) && selects[translationsId].hasOwnProperty('value') ? selects[translationsId].value : null} id={translationsId} options={selects.hasOwnProperty([translationsId]) && selects[translationsId].hasOwnProperty('options') ? selects[translationsId].options : []} disabled={disabledForm} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('pages.Translations.other.submit')}</Button>
        </Col>
      </Row>
      {
        value !== null && Object.keys(value).length > 0 ? (
          <Row>
            <DataTable>
              <DataTable.Header>
                <DataTable.Title>{Translator.t('pages.Translations.other.table.language.title')}</DataTable.Title>
                <DataTable.Title>{Translator.t('pages.Translations.other.table.translation.title')}</DataTable.Title>
                <DataTable.Title />
              </DataTable.Header>
              {
                Object.keys(value).map((locale, index) => (
                  <DataTable.Row key={index}>
                    <DataTable.Cell>{languages.getName(locale, Translator.locale)}</DataTable.Cell>
                    <DataTable.Cell>{value[locale]}</DataTable.Cell>
                    <DataTable.Cell>
                      <Button
                        mode="outlined"
                        onPress={() => {
                          const localeToRemove = locale;
                          const newValue = {};
                          for (const locale in translations[translationsId].value) {
                            if (locale !== localeToRemove) {
                              newValue[locale] = translations[translationsId].value[locale];
                            }
                          }
                          dispatch(changeTranslations({
                            id: translationsId,
                            translations: {
                              value: newValue,
                            },
                          }));
                        }}
                      >
                        Delete
                      </Button>
                    </DataTable.Cell>
                  </DataTable.Row>
                ))
              }
            </DataTable>
          </Row>
        ) : null
      }
    </View>
  );
}

export default Translations;

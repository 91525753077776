import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import BrandLogoInput from '../../molecules/inputs/BrandLogoInput';
import BrandNameInput from '../../molecules/inputs/BrandNameInput';
import CompanyInput from '../../molecules/inputs/CompanyInput';
import { changeIsLoading, createSelect, changeSelect } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import BrandTagsInput from '../../molecules/inputs/BrandTagsInput';

function BrandCreateForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const parentPath = props.hasOwnProperty('path') && props.path !== null ? props.path : null;
  const [disabledForm, setDisabledForm] = useState(true);
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState('');
  const [tags, setTags] = useState('');
  const [errors, setErrors] = useState({});
  const uploadLogo = async function (brandId) {
    const response = await fetch(logo.uri);
    const blob = await response.blob();

    const ref = Firebase.storage().ref().child(`images/brand/${brandId}`);
    return ref.put(blob);
  };
  const validate = function () {
    const errors = {};
    if (!selects.hasOwnProperty('brandCreateFormCompany') || !selects.brandCreateFormCompany.hasOwnProperty('value') || selects.brandCreateFormCompany.value === null) {
      errors["CompanyInput"] = {
        "requiredField": {}
      };
    }
    if (typeof name !== 'string' || name.length === 0) {
      errors["BrandNameInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const companyDocParsedPath = parseDocPath(selects.brandCreateFormCompany.value);
    const newDoc = Firebase.firestore().collection('company').doc(companyDocParsedPath.company).collection('brand')
      .doc();
    newDoc.set({
      name,
      tags: tags.trim().split(',').filter((tag) => tag.length > 0),
      activeMenu: null,
      logo: null,
      rights: {
        [user]: 7,
      },
    }).then(() => {
      if (logo !== null) {
        uploadLogo(newDoc.id).then((task) => {
          task.ref.getDownloadURL().then((url) => {
            newDoc.set({
              logo: url,
            }, {
              merge: true,
            }).then(() => {
              setLogo(null);
              setName('');
              setTags('');
              dispatch(changeSelect({
                id: 'brandCreateFormCompany',
                select: {
                  value: parentPath,
                },
              }));
              alert(Translator.t('components.organisms.forms.BrandCreateForm.success.title'));
              setDisabledForm(false);
              dispatch(changeIsLoading(false));
            }).catch((error) => {
              alert(error.message);
            });
          }).catch((error) => {
            alert(error.message);
          });
        }).catch((error) => {
          alert(error.message);
        });
      } else {
        setName('');
        setTags('');
        dispatch(changeSelect({
          id: 'brandCreateFormCompany',
          select: {
            value: parentPath,
          },
        }));
        alert(Translator.t('components.organisms.forms.BrandCreateForm.success.title'));
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      }
    }).catch((error) => {
      alert(error.message);
    });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('company').where(`rights.${user}`, '>=', 7).onSnapshot((companiesRef) => {
      const companiesDocs = companiesRef.docs;
      const companySelectOptions = [];
      companiesDocs.forEach((companiesDoc) => {
        const companyDocPath = companiesDoc.ref.path;
        const company = companiesDoc.data();
        companySelectOptions.push({
          icon: 'file-document',
          label: company.name,
          description: `ID: #${companiesDoc.id}`,
          value: companyDocPath,
        });
      });
      dispatch(createSelect({
        id: 'brandCreateFormCompany',
        select: {
          options: companySelectOptions,
          value: parentPath
        },
      }));
      setDisabledForm(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <BrandLogoInput value={logo} setValue={setLogo} disabled={disabledForm} />
      <BrandNameInput errors={errors.hasOwnProperty('BrandNameInput') ? errors.BrandNameInput : {}} value={name} onChange={(name) => setName(name)} disabled={disabledForm} />
      <BrandTagsInput value={tags} onChange={(tags) => setTags(tags)} disabled={disabledForm} />
      <CompanyInput errors={errors.hasOwnProperty('CompanyInput') ? errors.CompanyInput : {}} value={selects.hasOwnProperty('brandCreateFormCompany') && selects.brandCreateFormCompany.hasOwnProperty('value') ? selects.brandCreateFormCompany.value : null} id="brandCreateFormCompany" options={selects.hasOwnProperty('brandCreateFormCompany') && selects.brandCreateFormCompany.hasOwnProperty('options') ? selects.brandCreateFormCompany.options : []} disabled={disabledForm || parentPath !== null} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.BrandCreateForm.submit.title')}</Button>
    </View>
  );
}

export default BrandCreateForm;

import React from 'react';

import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import View from '../atoms/layouts/View';
import CompanyCreateForm from '../organisms/forms/CompanyCreateForm';
import DashboardLayout from '../atoms/layouts/DashboardLayout';

function CompanyCreateItem(props) {
  const path = typeof props.route.params === 'object' && props.route.params.hasOwnProperty('path') && typeof props.route.params.path === 'string' ? props.route.params.path : null;

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <CompanyCreateForm path={path} />
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default CompanyCreateItem;

import React, { useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import { Provider as ReactNativePaperProvider, Portal } from 'react-native-paper';
import { useColorScheme } from 'react-native-appearance';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts, Inter_300Light, Inter_400Regular, Inter_500Medium, Inter_600SemiBold } from '@expo-google-fonts/inter';
import * as Notifications from 'expo-notifications';

import { LightTheme, DarkTheme } from './src/modules/Theme';
import Config from './src/Config';
import Linking from './src/modules/Linking';
import MainStackNavigation from './src/components/MainStackNavigation';
import Firebase from './src/modules/Firebase';
import { changeUser } from './src/redux/reducers/AuthReducer';
import Loading from './src/components/atoms/elements/Loading';
import { changeCurrency, changeLocale, changeTimezone } from './src/redux/reducers/IntlReducer';
import Modal from './src/components/atoms/elements/Modal';
import { changeTheme } from './src/redux/reducers/DesignReducer';
import { prepareTheme, getDevice } from './src/modules/Helper';
import firebase from 'firebase/app';

// import * as FirebaseAnalytics from 'expo-firebase-analytics';
// FirebaseAnalytics.logEvent('app_loaded');

// TODO When deleting product make sure the product is deleted also from the activeOrders.
// TODO When deleting section, from cloud functions I have to select the products, where has reference with the deleted section, and delete the reference.
// TODO Make the header fixed (on web)

function App() {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [userDoc, setUserDoc] = useState(null);
  const dispatch = useDispatch();
  const colorScheme = useColorScheme();
  const theme = useSelector((state) => state.design.theme);
  const locale = useSelector((state) => state.intl.locale);
  const currency = useSelector((state) => state.intl.currency);
  const timezone = useSelector((state) => state.intl.timezone);
  let [fontsLoaded] = useFonts({
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold
  });

  useEffect(() => {
    let userUnsubscribe = null;
    const authUnsubscribe = Firebase.auth().onAuthStateChanged((user) => {
      dispatch(changeUser(user !== null ? user.uid : null));
      if (user !== null) {
        userUnsubscribe = Firebase.firestore().collection('user').doc(user.uid).onSnapshot((userDoc) => {
          const user = userDoc.data();
          setUserDoc(userDoc);

          dispatch(changeTheme(user.hasOwnProperty('theme') ? user.theme : Config.defaultTheme));

          dispatch(changeLocale(user.hasOwnProperty('locale') ? user.locale : Config.defaultLocale));
          dispatch(changeCurrency(user.hasOwnProperty('currency') ? user.currency : Config.defaultCurrency));
          dispatch(changeTimezone(user.hasOwnProperty('timezone') ? user.timezone : Config.defaultTimezone));
        });
      }
      else {
        dispatch(changeTheme(Config.defaultTheme));

        dispatch(changeLocale(Config.defaultLocale));
        dispatch(changeCurrency(Config.defaultCurrency));
        dispatch(changeTimezone(Config.defaultTimezone));
      }
      if (isAuthLoading) {
        setIsAuthLoading(false);
      }
    });
    return () => {
      authUnsubscribe();
      if (userUnsubscribe !== null) {
        userUnsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'web') {
        return;
      }
      if (userDoc !== null) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          // alert('Failed to get push token for push notification!');
          return;
        }
        const token = (await Notifications.getExpoPushTokenAsync()).data;
        const user = userDoc.data();
        if (!user.hasOwnProperty('devices') || !user.devices.includes(token)) {
          await Firebase.firestore().collection('user').doc(userDoc.id).set({
            devices: firebase.firestore.FieldValue.arrayUnion(token)
          }, {
            merge: true,
          });
        }
      }
    })();
  }, [userDoc]);

  const themeObject = prepareTheme(theme, colorScheme) === 'dark' ? DarkTheme : LightTheme;

  if (isAuthLoading || !fontsLoaded) {
    return <Loading theme={themeObject} />;
  }

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.keyboardAvoidingView}>
      <StatusBar style="light" />
      <ReactNativePaperProvider theme={themeObject}>
        <Portal>
          <Modal/>
        </Portal>
        <NavigationContainer linking={Linking} theme={themeObject} fallback={<Loading theme={themeObject} />}>
          <MainStackNavigation />
        </NavigationContainer>
      </ReactNativePaperProvider>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
});

export default App;

import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import MapView from 'react-native-maps';
import { HelperText } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../modules/Translator';
import Config from '../../Config';
import GoogleMapInput from "../atoms/elements/GoogleMapInput";
import { changeLocation } from '../../redux/reducers/PageReducer';
import { prepareLocale } from '../../modules/Helper';

function Location(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.page.locations);
  const locationId = props.route.params.id;
  const markerTitle = locations.hasOwnProperty(locationId) && locations[locationId].hasOwnProperty('markerTitle') ? locations[locationId].markerTitle : Translator.t('pages.Location.other.markerTitle');
  const markerDescription = locations.hasOwnProperty(locationId) && locations[locationId].hasOwnProperty('markerDescription') ? locations[locationId].markerDescription : Translator.t('pages.Location.other.markerDescription');
  const infos = locations.hasOwnProperty(locationId) && locations[locationId].hasOwnProperty('info') ? locations[locationId].info : [Translator.t('pages.Location.info')];
  let value = locations.hasOwnProperty(locationId) && locations[locationId].hasOwnProperty('value') ? locations[locationId].value : Config.defaultLocation;
  const infosNodes = [];

  infos.forEach((info, infoNodeKey) => infosNodes.push(<HelperText key={infoNodeKey} type="success">{info}</HelperText>));

  if (value === null) {
    value = Config.defaultLocation;
  }

  return (
    <View style={styles.container}>
      <View style={styles.mapContainer}>
        {
          Platform.OS !== 'web' ? (
            <MapView style={styles.map}>
              <MapView.Marker title={markerTitle} description={markerDescription} draggable coordinate={value} onDrag={(props) => {
                dispatch(changeLocation({
                  id: locationId,
                  location: {
                    value: {
                      latitude: props.nativeEvent.coordinate.latitude,
                      longitude: props.nativeEvent.coordinate.longitude
                    },
                  },
                }));
              }} />
            </MapView>
          ) : (
            <GoogleMapInput style={styles.map} markerTitle={markerTitle} markerDescription={markerDescription} value={value} onDrag={(props) => {
              dispatch(changeLocation({
                id: locationId,
                location: {
                  value: {
                    latitude: props.latitude,
                    longitude: props.longitude
                  },
                },
              }));
            }}/>
          )
        }
      </View>
      { infosNodes.length > 0 ? (
        <View>
          {infosNodes}
        </View>
      ) : null }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  mapContainer: {
    width: '100%',
    height: '90%',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  }
});

export default Location;

import React from 'react';
import { Title } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Col from '../../atoms/layouts/Col';
import Row from '../../atoms/layouts/Row';
import TableCard from '../items/TableCard';
import Translator from '../../../modules/Translator';
import AddNewButton from '../../atoms/buttons/AddNewButton';
import { prepareLocale } from '../../../modules/Helper';

function TableList({ tables, cols, hasAddNewButton, addNewButtonParams }) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  cols = typeof cols === 'undefined' ? 2 : cols;
  hasAddNewButton = typeof hasAddNewButton !== 'boolean' ? false : hasAddNewButton;
  addNewButtonParams = typeof addNewButtonParams !== 'object' ? { } : addNewButtonParams;

  if (tables.length === 0) {
    return (
      <Row>
        <Col xs={12} sm={12} md={12 / cols} lg={12 / (cols + 1)}>
          <Title>{Translator.t('components.organisms.lists.TableList.empty.title')}</Title>
        </Col>
        { hasAddNewButton ? (<AddNewButton cols={cols} parameters={addNewButtonParams} entity='Table'/>) : null }
      </Row>
    );
  }

  return (
    <Row>
      { tables.map((table, tableKey) => (
        <Col xs={12} sm={12} md={12 / cols} lg={12 / (cols + 1)} key={tableKey}>
          <TableCard {...table} />
        </Col>
      )) }
      { hasAddNewButton ? (<AddNewButton cols={cols} parameters={addNewButtonParams} entity='Table'/>) : null }
    </Row>
  );
}

export default TableList;

import React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { SvgXml } from 'react-native-svg';
import SVG from 'react-inlinesvg';

function SvgFromXml(props) {
  const xml = props.hasOwnProperty('xml') ? props.xml : null;
  const content = props.hasOwnProperty('content') ? props.content : null;

  if (xml === null) {
    return null;
  }

  if (Platform.OS === 'web') {
    return (
      <View>
        <SVG src={xml} />
        {
          content !== null ? (
            <View style={styles.content}>
              {content}
            </View>
          ) : null
        }
      </View>
    );
  }

  return (
    <View>
      <SvgXml xml={xml}/>
      {
        content !== null ? (
          <View style={styles.content}>
            {content}
          </View>
        ) : null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    marginTop: 10,
    width: 256
  }
});

export default SvgFromXml;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TableList from './TableList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MyTableList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [tables, setTables] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('table').where(`rights.${user}`, '>', 0).onSnapshot((tablesRef) => {
      const tablesDocs = tablesRef.docs;
      const tables = [];
      tablesDocs.forEach((tablesDoc) => {
        const tablesDocParsedPath = parseDocPath(tablesDoc.ref.path);
        const table = tablesDoc.data();
        tables.push({
          name: table.waiterName,
          companyId: tablesDocParsedPath.company,
          brandId: tablesDocParsedPath.brand,
          storeId: tablesDocParsedPath.store,
          tableId: tablesDoc.id,
          enableView: table.rights[user] >= 1,
          enableEdit: table.rights[user] >= 4,
          enableDelete: table.rights[user] >= 7,
        });
      });
      setTables(tables);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MyTableList.section.title')} description={Translator.t('components.organisms.lists.MyTableList.section.description')}>
      <TableList tables={tables} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MyTableList;

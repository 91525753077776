import React from 'react';
import { StyleSheet, ScrollView as DefaultScrollView } from 'react-native';

function View(props) {
  return (
    <DefaultScrollView contentContainerStyle={styles.container} scrollIndicatorInsets={{ right: 1 }}>
      {props.children}
    </DefaultScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 25,
    paddingBottom: 50,
    paddingLeft: '3%',
    paddingRight: '3%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  },
});

export default View;

import React from 'react';
import { StyleSheet, View as DefaultView, Dimensions } from 'react-native';
import Config from '../../../Config';

function CenterView(props) {
  return (
    <DefaultView style={styles.container}>
      {props.children}
    </DefaultView>
  );
}

const padding = function () {
  if (Dimensions.get('window').width <= Config.dimensions.sm) {
    return '2%';
  }
  else if (Dimensions.get('window').width > Config.dimensions.sm && Dimensions.get('window').width <= Config.dimensions.md) {
    return '5%';
  }
  else if (Dimensions.get('window').width > Config.dimensions.md && Dimensions.get('window').width <= Config.dimensions.lg) {
    return '7%';
  }
  else if (Dimensions.get('window').width > Config.dimensions.lg && Dimensions.get('window').width <= Config.dimensions.xl) {
    return '10%';
  }
  else if (Dimensions.get('window').width > Config.dimensions.xl && Dimensions.get('window').width <= Config.dimensions.xxl) {
    return '15%';
  }
  else {
    return '20%';
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: padding(),
    paddingRight: padding(),
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  }
});

export default CenterView;

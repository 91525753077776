import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import MenuWaiterNameInput from '../../molecules/inputs/MenuWaiterNameInput';
import { changeIsLoading } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MenuEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const companyId = parsedDocPath.company;
  const brandId = parsedDocPath.brand;
  const menuId = parsedDocPath.menu;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [disabledForm, setDisabledForm] = useState(true);
  const [waiterName, setWaiterName] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["MenuWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('menu')
      .doc(menuId)
      .set({
        waiterName,
      }, {
        merge: true,
      })
      .then(() => {
        alert(Translator.t('components.organisms.forms.MenuEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('menu')
      .doc(menuId)
      .onSnapshot((menuDoc) => {
        if (menuDoc.exists) {
          const menu = menuDoc.data();
          if (menu.rights.hasOwnProperty(user) && menu.rights[user] >= 4) {
            setWaiterName(menu.waiterName);
            setDisabledForm(false);
          } else {
            setWaiterName('');
            setDisabledForm(true);
            alert(Translator.t('components.organisms.forms.MenuEditForm.error.noPermissions'));
          }
        }
      });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <MenuWaiterNameInput errors={errors.hasOwnProperty('MenuWaiterNameInput') ? errors.MenuWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.MenuEditForm.submit.title')}</Button>
    </View>
  );
}

export default MenuEditForm;

import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Card from '../../atoms/elements/Card';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { showConfirmAlert } from '../../../modules/Alert';
import { prepareLocale } from '../../../modules/Helper';

function SectionCard({
  cover, avatar, name, companyId, brandId, menuId, sectionId, enableView, enableEdit, enableDelete, customActions,
}) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  enableView = typeof enableView !== 'undefined' && enableView;
  enableEdit = typeof enableEdit !== 'undefined' && enableEdit;
  enableDelete = typeof enableDelete !== 'undefined' && enableDelete;

  const actions = [];

  if (enableView) {
    actions.push({
      title: Translator.t('words.view'),
      mode: 'contained',
      onPress() {
        navigation.push('SectionGetItem', {
          companyId,
          brandId,
          menuId,
          sectionId,
        });
      },
    });
  }

  if (enableEdit) {
    actions.push({
      title: Translator.t('words.edit'),
      mode: 'outlined',
      onPress() {
        navigation.push('SectionUpdateItem', {
          companyId,
          brandId,
          menuId,
          sectionId,
        });
      },
    });
  }

  if (enableDelete) {
    actions.push({
      title: Translator.t('words.delete'),
      mode: 'outlined',
      onPress: function () {
        showConfirmAlert(Translator, function () {
          Firebase.firestore().collection('company').doc(companyId).collection('brand')
            .doc(brandId)
            .collection('menu')
            .doc(menuId)
            .collection('section')
            .doc(sectionId)
            .delete()
            .then(() => {
              alert(Translator.t('components.organisms.items.SectionCard.enableDelete.success.title'));
            })
            .catch((error) => {
              alert(error.message);
            });
        });
      },
    });
  }

  return (
    <Card avatar={avatar} cover={cover} title={name} subtitle={`ID: #${sectionId}`} icon="format-list-bulleted" actions={actions.concat(typeof customActions === 'undefined' ? [] : customActions)} />
  );
}

export default SectionCard;

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import FirstNameInput from '../../molecules/inputs/FirstNameInput';
import LastNameInput from '../../molecules/inputs/LastNameInput';
import { changeIsLoading } from '../../../redux/reducers/PageReducer';
import { Button } from 'react-native-paper';
import { prepareLocale } from '../../../modules/Helper';

function UpdateAccount() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [disabledForm, setDisabledForm] = useState(true);
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof firstName !== 'string' || firstName.length === 0) {
      errors["FirstNameInput"] = {
        "requiredField": {}
      };
    }
    if (typeof lastName !== 'string' || lastName.length === 0) {
      errors["LastNameInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('user').doc(user).set({
      firstName,
      lastName
    }, {
      merge: true
    }).then(() => {
      alert(Translator.t('components.organisms.forms.UpdateAccount.success.title'));
    }).catch((error) => {
      alert(error.message);
    }).then(() => {
      setDisabledForm(false);
      dispatch(changeIsLoading(false));
    });
  }

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('user').doc(user).onSnapshot((userDoc) => {
      if (userDoc.exists) {
        const user = userDoc.data();
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setDisabledForm(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <FirstNameInput errors={errors.hasOwnProperty('FirstNameInput') ? errors.FirstNameInput : {}} value={firstName} onChange={(firstName) => setFirstName(firstName)} disabled={disabledForm} />
      <LastNameInput errors={errors.hasOwnProperty('LastNameInput') ? errors.LastNameInput : {}} value={lastName} onChange={(lastName) => setLastName(lastName)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.UpdateAccount.submit.title')}</Button>
    </View>
  );
}

export default UpdateAccount;

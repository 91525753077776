import React from 'react';
import { View, Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { Appbar, ProgressBar } from 'react-native-paper';
import Config from '../../../Config';

function Header(props) {
  const isLoading = useSelector((state) => state.page.isLoading);
  const isLoggedIn = useSelector((state) => state.auth.user) !== null;
  const title = typeof props.options.title !== 'undefined' ? props.options.title : props.route.name;
  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <Appbar.Header>
        {isLoggedIn && props.route.name !== 'Dashboard' ? <Appbar.Action icon="home" onPress={() => props.navigation.navigate('Dashboard')} /> : null }
        {typeof props.progress === 'undefined' || props.progress.previous == null ? null : <Appbar.BackAction onPress={props.navigation.goBack} />}
        <Appbar.Content title={title} />
        { user !== null && Dimensions.get('window').width < Config.dimensions.xl && props.route.name !== 'MyOrders' ? (<Appbar.Action icon="food" onPress={() => props.navigation.navigate('MyOrders')} />) : null }
        { user !== null && Dimensions.get('window').width < Config.dimensions.xl && props.route.name !== 'MyAccount' ? (<Appbar.Action icon="account-circle" onPress={() => props.navigation.navigate('MyAccount')} />) : null }
      </Appbar.Header>
      {isLoading ? <ProgressBar indeterminate /> : <></>}
    </>
  );
}

export default Header;

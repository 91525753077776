import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Config from '../../../Config';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import DropdownInput from '../../atoms/inputs/DropdownInput';
import { prepareLocale } from '../../../modules/Helper';

function ThemeSelector(props) {
  const mode = props.hasOwnProperty('mode') ? props.mode : 'contained';
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [theme, setTheme] = useState(Config.defaultTheme);
  const options = {
    auto: Translator.t('themes.auto')
  };
  const onChange = function (theme) {
    Firebase.firestore().collection('user').doc(user).set({
      theme: theme
    }, {
      merge: true
    });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('user').doc(user).onSnapshot((userDoc) => {
      const user = userDoc.data();
      setTheme(user.hasOwnProperty('theme') ? user.theme : Config.defaultTheme);
    });
    return () => unsubscribe();
  }, []);

  Config.supportedThemes.forEach(function (supportedTheme, supportedThemeKey) {
    options[supportedTheme] = Translator.t('themes.' + supportedTheme);
  });

  return (
    <DropdownInput mode={mode} value={theme} options={options} onChange={onChange}/>
  );
}

export default ThemeSelector;

import React from 'react';

import TextInput from '../../atoms/inputs/TextInput';

function TextAreaInput(props) {
  const numberOfLines = props.hasOwnProperty('lines') ? props.lines : 3;

  return (
    <TextInput
      multiline={true}
      numberOfLines={numberOfLines}
      {...props}
    />
  );
}

export default TextAreaInput;

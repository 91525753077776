import i18n from 'i18n-js';
import Config from '../Config';

const bgTranslations = require('../localization/bg.json');
const deTranslations = require('../localization/de.json');
const enTranslations = require('../localization/en.json');
const frTranslations = require('../localization/fr.json');
const itTranslations = require('../localization/it.json');
const ruTranslations = require('../localization/ru.json');
const translations = {
  bg: bgTranslations,
  de: deTranslations,
  en: enTranslations,
  fr: frTranslations,
  it: itTranslations,
  ru: ruTranslations,
}

i18n.translations = translations;
i18n.fallback = true;
i18n.locale = Config.defaultLocale;

const Translator = i18n;

export default Translator;

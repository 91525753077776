import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { useSelector } from 'react-redux';
import Chip from '../elements/Chip';

import TextInput from './TextInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function TagsInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const [tags, setTags] = useState([]);
  const tagsAsString = props.hasOwnProperty('value') ? props.value : '';

  useEffect(() => {
    if (typeof tagsAsString === 'string') {
      let tags = tagsAsString.trim().split(',');
      tags = tags.filter((tag) => tag.length > 0);
      setTags(tags);
    }
  }, [tagsAsString]);

  return (
    <View>
      <TextInput
        {...props}
        onChange={function (tagsAsString) {
          if (typeof tagsAsString !== 'string') {
            return;
          }
          tagsAsString = tagsAsString.trim();
          props.onChange(tagsAsString);
          let tags = tagsAsString.split(',');
          tags = tags.filter((tag) => tag.length > 0);
          setTags(tags);
        }}
        infos={[Translator.t('components.atoms.inputs.TagsInput.info')]}
      />
      { tags.length > 0 ? (
        <View style={styles.chipContainer}>
          {tags.map((tag, tagKey) => (
            <Chip key={tagKey} size={30}>
              <Text>
                #
                {tag}
              </Text>
            </Chip>
          ))}
        </View>
      ) : null }
    </View>
  );
}

const styles = StyleSheet.create({
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
});

export default TagsInput;

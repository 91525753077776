import codes from 'country-calling-code';

function PhoneCodes() {
  const phoneCodes = {};
  for (const [codeKey, code] of Object.entries(codes)) {
    phoneCodes[code.isoCode2] = code.countryCodes[0];
  }
  return phoneCodes;
}

export default PhoneCodes;

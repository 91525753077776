import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import SectionList from './SectionList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MySectionList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('section').where(`rights.${user}`, '>', 0).onSnapshot((sectionsRef) => {
      const sectionsDocs = sectionsRef.docs;
      const sections = [];
      sectionsDocs.forEach((sectionsDoc) => {
        const sectionsDocParsedPath = parseDocPath(sectionsDoc.ref.path);
        const section = sectionsDoc.data();
        sections.push({
          name: section.waiterName,
          companyId: sectionsDocParsedPath.company,
          brandId: sectionsDocParsedPath.brand,
          menuId: sectionsDocParsedPath.menu,
          sectionId: sectionsDoc.id,
          enableView: section.rights[user] >= 1,
          enableEdit: section.rights[user] >= 4,
          enableDelete: section.rights[user] >= 7,
        });
      });
      setSections(sections);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MySectionList.section.title')} description={Translator.t('components.organisms.lists.MySectionList.section.description')}>
      <SectionList sections={sections} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MySectionList;

import React from 'react';
import { useSelector } from 'react-redux';

import TextInput from '../../atoms/inputs/TextInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function FirstNameInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <TextInput
      label={Translator.t('components.molecules.inputs.FirstNameInput.label')}
      placeholder={Translator.t('components.molecules.inputs.FirstNameInput.placeholder')}
      icon="account-circle"
      autoCompleteType="name"
      keyboardType="default"
      textContentType="givenName"
      {...props}
    />
  );
}

export default FirstNameInput;

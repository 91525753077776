import * as DefaultLinking from 'expo-linking';
import Config from '../Config';

const Linking = {
  prefixes: [Config.baseUrl, Config.protocolId + '://', DefaultLinking.createURL('/')],
  config: {
    screens: {
      MyAccount: {
        path: '/my/account',
      },
      MyAccountUpdate: {
        path: '/my/account/update',
      },
      MyOrders: {
        path: '/my/orders',
      },
      BrandCreateItem: {
        path: '/brand/new',
      },
      BrandGetItem: {
        path: '/company/:companyId/brand/:brandId',
      },
      BrandUpdateItem: {
        path: '/company/:companyId/brand/:brandId/update',
      },
      CompanyCreateItem: {
        path: '/company/new',
      },
      CompanyGetItem: {
        path: '/company/:companyId',
      },
      CompanyUpdateItem: {
        path: '/company/:companyId/update',
      },
      Dashboard: {
        path: '/',
      },
      Login: {
        path: '/login',
      },
      Location: {
        path: '/location/:id',
      },
      MenuCreateItem: {
        path: '/menu/new',
      },
      MenuGetItem: {
        path: '/company/:companyId/brand/:brandId/menu/:menuId',
      },
      MenuUpdateItem: {
        path: '/company/:companyId/brand/:brandId/menu/:menuId/update',
      },
      ProductCreateItem: {
        path: '/product/new',
      },
      ProductGetItem: {
        path: '/product/:productId',
      },
      ProductUpdateItem: {
        path: '/product/:productId/update',
      },
      Register: {
        path: '/register',
      },
      Reset: {
        path: '/reset',
      },
      SectionCreateItem: {
        path: '/section/new',
      },
      SectionGetItem: {
        path: '/company/:companyId/brand/:brandId/menu/:menuId/section/:sectionId',
      },
      SectionUpdateItem: {
        path: '/company/:companyId/brand/:brandId/menu/:menuId/section/:sectionId/update',
      },
      Select: {
        path: '/select/:id',
      },
      StoreCreateItem: {
        path: '/store/new',
      },
      StoreGetItem: {
        path: '/company/:companyId/brand/:brandId/store/:storeId',
      },
      StoreUpdateItem: {
        path: '/company/:companyId/brand/:brandId/store/:storeId/update',
      },
      TableCreateItem: {
        path: '/table/new',
      },
      TableGetItem: {
        path: '/company/:companyId/brand/:brandId/store/:storeId/table/:tableId',
      },
      TableUpdateItem: {
        path: '/company/:companyId/brand/:brandId/store/:storeId/table/:tableId/update',
      },
      Translations: {
        path: '/translations/:id',
      },
    },
  },
};

export default Linking;

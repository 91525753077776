import React, { useState, useEffect } from 'react';
import { Title, Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import View from '../atoms/layouts/View';
import Section from '../atoms/layouts/Section';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Firebase from '../../modules/Firebase';
import { buildDocPath, parseDocPath, prepareLocale } from '../../modules/Helper';
import Translator from '../../modules/Translator';
import { useSelector } from 'react-redux';
import { capitalize } from 'stringulation';
import Card from '../atoms/elements/Card';
import SvgFromXml from '../atoms/elements/SvgFromXml';
import PrintPdfFromHtml from '../atoms/buttons/PrintPdfFromHtml';
import { generateTableQrCodeHtml } from '../../modules/Templates';
import RightsManager from '../atoms/buttons/RightsManager';
import NotificationListManager from '../atoms/buttons/NotificationListManager';
import DashboardLayout from '../atoms/layouts/DashboardLayout';

function TableGetItem(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const companyId = props.route.params.companyId;
  const brandId = props.route.params.brandId;
  const storeId = props.route.params.storeId;
  const tableId = props.route.params.tableId;
  const path = buildDocPath({
    company: companyId,
    brand: brandId,
    store: storeId,
    table: tableId,
  });
  const user = useSelector((state) => state.auth.user);
  const [table, setTable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [qrCodePdfHtml, setQrCodePdfHtml] = useState(null);

  useEffect(() => {
    const unsubscribeTable = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('store').doc(storeId).collection('table').doc(tableId).onSnapshot((tableDoc) => {
      if (tableDoc.exists) {
        const table = tableDoc.data();
        setTable(table);
        setIsFound(true);
        setIsAllowed(table.rights.hasOwnProperty(user) && table.rights[user] >= 1);
        setIsEditAllowed(table.rights.hasOwnProperty(user) && table.rights[user] >= 4);
        props.navigation.setOptions({
          title: table.waiterName
        });
        setQrCodePdfHtml(generateTableQrCodeHtml(table));
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribeTable();
    };
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isFound) {
    return (
      <DashboardLayout>
        <View>
          <Title>{capitalize(Translator.t('words.notFound'), true)}</Title>
        </View>
      </DashboardLayout>
    );
  }

  if (!isAllowed) {
    return (
      <DashboardLayout>
        <View>
          <Title>{Translator.t('messages.notAllowed')}</Title>
        </View>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <View>
        <Row>
          {
            table.hasOwnProperty('qrCode') ? (
              <Section title={Translator.t('pages.TableGetItem.content.qrCode.title')} description={Translator.t('pages.TableGetItem.content.qrCode.description')}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <SvgFromXml xml={table.qrCode} content={qrCodePdfHtml === null ? null : (
                      <PrintPdfFromHtml html={qrCodePdfHtml}/>
                    )}/>
                  </Col>
                </Row>
              </Section>
            ) : null
          }
          <Section title={Translator.t('pages.TableGetItem.content.generalInformation.title')} description={Translator.t('pages.TableGetItem.content.generalInformation.description')}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.TableGetItem.content.generalInformation.seats.title')} subtitle={Translator.t('pages.TableGetItem.content.generalInformation.seats.description')} icon="seat">
                  <Title>{table.seats}</Title>
                </Card>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.TableGetItem.content.management.title')} description={Translator.t('pages.TableGetItem.content.management.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Button mode='contained' onPress={() => {
                  navigation.push('TableUpdateItem', {
                    companyId,
                    brandId,
                    storeId,
                    tableId,
                  });
                }}>{Translator.t('words.edit')}</Button>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.TableGetItem.content.rights.title')} description={Translator.t('pages.TableGetItem.content.rights.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <RightsManager path={path} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.TableGetItem.content.notificationList.title')} description={Translator.t('pages.TableGetItem.content.notificationList.description')} hidden={!isEditAllowed} hideBottomDivider={true}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <NotificationListManager path={path} />
              </Col>
            </Row>
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default TableGetItem;

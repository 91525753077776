import React from 'react';

import TextInput from '../../atoms/inputs/TextInput';

function FloatInput(props) {
  let value = props.hasOwnProperty('value') && typeof props.value === 'string' ? props.value : '';
  value = value.replace(',', '.');

  return (
    <TextInput
      keyboardType="decimal-pad"
      {...props}
      value={value}
    />
  );
}

export default FloatInput;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import StoreList from './StoreList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MyStoreList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('store').where(`rights.${user}`, '>', 0).onSnapshot((storesRef) => {
      const storesDocs = storesRef.docs;
      const stores = [];
      storesDocs.forEach((storesDoc) => {
        const storesDocParsedPath = parseDocPath(storesDoc.ref.path);
        const store = storesDoc.data();
        stores.push({
          name: store.name,
          companyId: storesDocParsedPath.company,
          brandId: storesDocParsedPath.brand,
          storeId: storesDoc.id,
          enableView: store.rights[user] >= 1,
          enableEdit: store.rights[user] >= 4,
          enableDelete: store.rights[user] >= 7,
        });
      });
      setStores(stores);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MyStoreList.section.title')} description={Translator.t('components.organisms.lists.MyStoreList.section.description')}>
      <StoreList stores={stores} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MyStoreList;

import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Card from '../../atoms/elements/Card';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { showConfirmAlert } from '../../../modules/Alert';
import { prepareLocale } from '../../../modules/Helper';

function CompanyCard({
  avatar, name, companyId, enableView, enableEdit, enableDelete, customActions,
}) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  enableView = typeof enableView !== 'undefined' && enableView;
  enableEdit = typeof enableEdit !== 'undefined' && enableEdit;
  enableDelete = typeof enableDelete !== 'undefined' && enableDelete;

  const actions = [];

  if (enableView) {
    actions.push({
      title: Translator.t('words.view'),
      mode: 'contained',
      onPress() {
        navigation.push('CompanyGetItem', {
          companyId
        });
      },
    });
  }

  if (enableEdit) {
    actions.push({
      title: Translator.t('words.edit'),
      mode: 'outlined',
      onPress() {
        navigation.push('CompanyUpdateItem', {
          companyId
        });
      },
    });
  }

  if (enableDelete) {
    actions.push({
      title: Translator.t('words.delete'),
      mode: 'outlined',
      onPress: function () {
        showConfirmAlert(Translator, function () {
          Firebase.firestore().collection('company').doc(companyId).delete()
            .then(() => {
              alert(Translator.t('components.organisms.items.CompanyCard.enableDelete.success.title'));
            })
            .catch((error) => {
              alert(error.message);
            });
        });
      },
    });
  }

  return (
    <Card avatar={avatar} title={name} subtitle={`ID: #${companyId}`} icon="file-document" actions={actions.concat(typeof customActions === 'undefined' ? [] : customActions)} />
  );
}

export default CompanyCard;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CompanyList from './CompanyList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { prepareLocale } from '../../../modules/Helper';

function MyCompanyList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('company').where(`rights.${user}`, '>', 0).onSnapshot((companiesRef) => {
      const companiesDocs = companiesRef.docs;
      const companies = [];
      companiesDocs.forEach((companiesDoc) => {
        const company = companiesDoc.data();
        companies.push({
          name: company.name,
          companyId: companiesDoc.id,
          enableView: company.rights[user] >= 1,
          enableEdit: company.rights[user] >= 4,
          enableDelete: company.rights[user] >= 7,
        });
      });
      setCompanies(companies);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MyCompanyList.section.title')} description={Translator.t('components.organisms.lists.MyCompanyList.section.description')}>
      <CompanyList companies={companies} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MyCompanyList;

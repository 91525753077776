import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Avatar, useTheme } from 'react-native-paper';

import Firebase from '../../../modules/Firebase';

function ImageView(props) {
  const theme = useTheme();
  const [image, setImage] = useState(null);
  const [meta, setMeta] = useState(null);
  const path = props.hasOwnProperty('path') ? props.path : null;
  const property = props.hasOwnProperty('property') ? props.property : null;
  const showAsAvatar = props.hasOwnProperty('avatar') && props.avatar === true;
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    avatar: {
      width: null,
      height: null,
      backgroundColor: null,
      borderWidth: 1,
      borderColor: theme.colors.primary,
    },
    image: {
      width: 128,
      height: meta && meta.height / (meta.width / 128),
      resizeMode: 'stretch',
      backgroundColor: null,
      borderWidth: 1,
      borderColor: theme.colors.primary,
      borderRadius: 10,
    },
  });

  useEffect(() => {
    const unsubscribe = Firebase.firestore().doc(path).onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data[property] !== null) {
          Image.getSize(data[property], (width, height) => {
            setImage(data[property]);
            setMeta({
              width: width,
              height: height
            });
          });
        }
      }
    });
    return () => unsubscribe();
  }, []);

  if (image === null) {
    return null;
  }

  return (
    <View style={styles.container}>
      { showAsAvatar ? (
        <Avatar.Image size={128} source={{ uri: image }} style={styles.avatar} />
      ) : (
        meta !== null ? (
          <Image source={{ uri: image }} style={styles.image} />
        ) : null
      ) }
    </View>
  );
}

export default ImageView;

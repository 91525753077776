import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import StoreNameInput from '../../molecules/inputs/StoreNameInput';
import { changeIsLoading, createLocation } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import Config from '../../../Config';
import StoreLocationInput from '../../molecules/inputs/StoreLocationInput';
import StoreIsCashPaymentSupportedInput
  from '../../molecules/inputs/StoreIsCashPaymentSupportedInput';
import StoreIsCardTerminalPaymentSupportedInput
  from '../../molecules/inputs/StoreIsCardTerminalPaymentSupportedInput';
import StoreIsCardAppPaymentSupportedInput
  from '../../molecules/inputs/StoreIsCardAppPaymentSupportedInput';
import StoreIsTipSupportedInput from '../../molecules/inputs/StoreIsTipSupportedInput';

function StoreEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const companyId = parsedDocPath.company;
  const brandId = parsedDocPath.brand;
  const storeId = parsedDocPath.store;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const locations = useSelector((state) => state.page.locations);
  const [disabledForm, setDisabledForm] = useState(true);
  const [name, setName] = useState('');
  const [isCashPaymentSupported, setIsCashPaymentSupported] = useState(false);
  const [isCardTerminalPaymentSupported, setIsCardTerminalPaymentSupported] = useState(false);
  const [isCardAppPaymentSupported, setIsCardAppPaymentSupported] = useState(false);
  const [isTipSupported, setIsTipSupported] = useState(false);
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof name !== 'string' || name.length === 0) {
      errors["StoreNameInput"] = {
        "requiredField": {}
      };
    }
    if (!locations.hasOwnProperty('storeEditFormLocation') || !locations.storeEditFormLocation.hasOwnProperty('value') || locations.storeEditFormLocation.value === null) {
      errors["StoreLocationInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('store')
      .doc(storeId)
      .set({
        name,
        location: locations.storeEditFormLocation.value,
        isCashPaymentSupported,
        isCardTerminalPaymentSupported,
        isCardAppPaymentSupported,
        isTipSupported
      }, {
        merge: true,
      })
      .then(() => {
        alert(Translator.t('components.organisms.forms.StoreEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('store')
      .doc(storeId)
      .onSnapshot((storeDoc) => {
        if (storeDoc.exists) {
          const store = storeDoc.data();
          if (store.rights.hasOwnProperty(user) && store.rights[user] >= 4) {
            setName(store.name);
            setIsCashPaymentSupported(store.hasOwnProperty('isCashPaymentSupported') ? store.isCashPaymentSupported : false);
            setIsCardTerminalPaymentSupported(store.hasOwnProperty('isCardTerminalPaymentSupported') ? store.isCardTerminalPaymentSupported : false);
            setIsCardAppPaymentSupported(store.hasOwnProperty('isCardAppPaymentSupported') ? store.isCardAppPaymentSupported : false);
            setIsTipSupported(store.hasOwnProperty('isTipSupported') ? store.isTipSupported : false);
            dispatch(createLocation({
              id: 'storeEditFormLocation',
              location: {
                value: store.location
              },
            }));
            setDisabledForm(false);
          } else {
            setName('');
            dispatch(createLocation({
              id: 'storeEditFormLocation',
              location: {
                value: Config.defaultLocation
              },
            }));
            setDisabledForm(true);
            alert(Translator.t('components.organisms.forms.StoreEditForm.error.noPermissions'));
          }
        }
      });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <StoreNameInput errors={errors.hasOwnProperty('StoreNameInput') ? errors.StoreNameInput : {}} value={name} onChange={(name) => setName(name)} disabled={disabledForm} />
      <StoreLocationInput errors={errors.hasOwnProperty('StoreLocationInput') ? errors.StoreLocationInput : {}} value={locations.hasOwnProperty('storeEditFormLocation') && locations.storeEditFormLocation.hasOwnProperty('value') ? locations.storeEditFormLocation.value : null} id="storeEditFormLocation" disabled={disabledForm}/>
      <StoreIsCashPaymentSupportedInput value={isCashPaymentSupported} onChange={() => setIsCashPaymentSupported(!isCashPaymentSupported)} disabled={disabledForm} />
      <StoreIsCardTerminalPaymentSupportedInput value={isCardTerminalPaymentSupported} onChange={() => setIsCardTerminalPaymentSupported(!isCardTerminalPaymentSupported)} disabled={disabledForm} />
      <StoreIsCardAppPaymentSupportedInput value={isCardAppPaymentSupported} onChange={() => setIsCardAppPaymentSupported(!isCardAppPaymentSupported)} disabled={disabledForm} />
      <StoreIsTipSupportedInput value={isTipSupported} onChange={() => setIsTipSupported(!isTipSupported)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.StoreEditForm.submit.title')}</Button>
    </View>
  );
}

export default StoreEditForm;

import React from 'react';

import View from '../atoms/layouts/View';
import Row from '../atoms/layouts/Row';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import MyOrderList from '../organisms/lists/MyOrderList';
import Col from '../atoms/layouts/Col';
import Footer from '../atoms/layouts/Footer';

function MyOrders() {
  return (
    <DashboardLayout>
      <View>
        <Row>
          <MyOrderList />
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Footer/>
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default MyOrders;

import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Switch, Subheading, useTheme } from 'react-native-paper';

function CheckboxInput({ label, value, onChange, disabled }) {
  const theme = useTheme();
  label = typeof label === 'undefined' || label === null ? null : label;
  disabled = typeof disabled !== 'boolean' ? false : disabled;
  const styles = StyleSheet.create({
    checkboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    checkboxLabel: {
      marginRight: 20,
    }
  });

  return (
    <View style={styles.checkboxContainer}>
      {
        label !== null ? (
          <Subheading style={styles.checkboxLabel}>
            {label}
          </Subheading>
        ) : null
      }
      <Switch value={value} onValueChange={onChange} disabled={disabled} color={theme.colors.primary}/>
    </View>
  );
}

export default CheckboxInput;

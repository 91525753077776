import React from 'react';
import { Platform } from 'react-native';
import { Button } from 'react-native-paper';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function PrintPdfFromHtml(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const html = props.hasOwnProperty('html') ? props.html : null;
  const label = props.hasOwnProperty('label') ? props.label : Translator.t('components.atoms.buttons.PrintPdfFromHtml.label');
  const onPress = function () {
    if (Platform.OS === 'web') {
      const mainHtml = document.getElementsByTagName('html')[0];
      const htmlDoc = document.createElement('div');
      htmlDoc.id = 'printPdfHtml';
      htmlDoc.style.display = 'none';
      htmlDoc.innerHTML = html;
      mainHtml.appendChild(htmlDoc);
      htmlDoc.style.display = 'block';
      document.getElementsByTagName('body')[0].style.display = 'none';
    }
    Print.printToFileAsync({
      html: html
    }).then(function (file) {
      if ((Platform.OS === 'ios' || Platform.OS === 'android') && typeof file !== 'undefined') {
        const fileUri = file.uri;
        Sharing.shareAsync(fileUri, { UTI: '.pdf', mimeType: 'application/pdf' }).then(function () {});
      }
      if (Platform.OS === 'web') {
        document.getElementsByTagName('body')[0].style.display = 'unset';
        const mainHtml = document.getElementsByTagName('html')[0];
        const htmlDoc = document.getElementById('printPdfHtml');
        mainHtml.removeChild(htmlDoc);
      }
    });
  };

  return (
    <Button mode='contained' onPress={onPress}>{label}</Button>
  );
}

export default PrintPdfFromHtml;

import React from 'react';
import { useSelector } from 'react-redux';

import TextInput from '../../atoms/inputs/TextInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function SectionWaiterNameInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <TextInput
      label={Translator.t('components.molecules.inputs.SectionWaiterNameInput.label')}
      placeholder={Translator.t('components.molecules.inputs.SectionWaiterNameInput.placeholder')}
      infos={[
        Translator.t('components.molecules.inputs.SectionWaiterNameInput.info'),
      ]}
      icon="dots-horizontal"
      keyboardType="default"
      {...props}
    />
  );
}

export default SectionWaiterNameInput;


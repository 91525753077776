import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import {
  parseDocPath,
  parsePriceToFloat,
  canPriceBeParseToFloat,
  prepareLocale
} from '../../../modules/Helper';
import Firebase from '../../../modules/Firebase';
import Translator from '../../../modules/Translator';
import {
  changeIsLoading,
  changeSelect,
  createSelect,
  createTranslations
} from '../../../redux/reducers/PageReducer';
import ProductNameInput from '../../molecules/inputs/ProductNameInput';
import ProductDescriptionInput from '../../molecules/inputs/ProductDescriptionInput';
import ProductWaiterNameInput from '../../molecules/inputs/ProductWaiterNameInput';
import ProductPriceInput from '../../molecules/inputs/ProductPriceInput';
import ProductTagsInput from '../../molecules/inputs/ProductTagsInput';
import ProductNameTranslationsInput from '../../molecules/inputs/ProductNameTranslationsInput';
import ProductDescriptionTranslationsInput
  from '../../molecules/inputs/ProductDescriptionTranslationsInput';
import SectionsInput from '../../molecules/inputs/SectionsInput';
import Currencies from '../../../modules/Currencies';
import ProductCurrencyInput from '../../molecules/inputs/ProductCurrencyInput';
import ImageManager from '../../atoms/buttons/ImageManager';
import ProductIsAvailableInput from '../../molecules/inputs/ProductIsAvailableInput';

function ProductEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const productId = parsedDocPath.product;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const translations = useSelector((state) => state.page.translations);
  const selects = useSelector((state) => state.page.selects);
  const currencies = Currencies();
  const [disabledForm, setDisabledForm] = useState(true);
  const [image, setImage] = useState(null);
  const [waiterName, setWaiterName] = useState('');
  const [price, setPrice] = useState('');
  const [tags, setTags] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["ProductWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (typeof price !== 'string' || price.length === 0) {
      errors["ProductPriceInput"] = {
        "requiredField": {}
      };
    }
    else if (!canPriceBeParseToFloat(price)) {
      errors["ProductPriceInput"] = {
        "notValidValue": {}
      };
    }
    if (!selects.hasOwnProperty('productEditFormCurrency') || !selects.productEditFormCurrency.hasOwnProperty('value') || selects.productEditFormCurrency.value === null) {
      errors["ProductCurrencyInput"] = {
        "requiredField": {}
      };
    }
    if (Object.keys(translations.productEditFormProductName.hasOwnProperty('value') ? translations.productEditFormProductName.value : []).length === 0) {
      errors["ProductNameTranslationsInput"] = {
        "requiredTranslation": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const productNameTranslations = translations.productEditFormProductName.hasOwnProperty('value') ? translations.productEditFormProductName.value : [];
    const productDescriptionTranslations = translations.productEditFormProductDescription.hasOwnProperty('value') ? translations.productEditFormProductDescription.value : [];
    const productSections = selects.hasOwnProperty('productEditFormSections') && selects.productEditFormSections.hasOwnProperty('value') ? selects.productEditFormSections.value : [];
    Firebase.firestore().collection('product').doc(productId)
      .update({
        nameTranslations: productNameTranslations,
        descriptionTranslations: productDescriptionTranslations,
      })
      .catch((error) => {
        alert(error.message);
      });
    Firebase.firestore().collection('product').doc(productId)
      .set({
        waiterName,
        isAvailable,
        price: parsePriceToFloat(price),
        tags: tags.trim().split(',').filter((tag) => tag.length > 0),
        nameTranslations: productNameTranslations,
        descriptionTranslations: productDescriptionTranslations,
        sections: productSections,
        currency: selects.productEditFormCurrency.value,
      }, {
        merge: true,
      })
      .then(() => {
        alert(Translator.t('components.organisms.forms.ProductEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const currencySelectOptions = [];
    for (const [index, currency] of Object.entries(currencies.getAll(Translator.locale))) {
      currencySelectOptions.push({
        label: currency.name + ' (' + currency.symbol + ')',
        value: currency.code,
      });
    }
    dispatch(createSelect({
      id: 'productEditFormCurrency',
      select: {
        options: currencySelectOptions,
        enableSearch: true
      },
    }));
    const unsubscribeSections = Firebase.firestore().collectionGroup('section').where(`rights.${user}`, '>=', 7).onSnapshot((sectionsRef) => {
      const sectionsDocs = sectionsRef.docs;
      const sectionSelectOptions = [];
      sectionsDocs.forEach((sectionsDoc) => {
        const sectionDocPath = sectionsDoc.ref.path;
        const section = sectionsDoc.data();
        sectionSelectOptions.push({
          icon: 'file-document',
          label: section.waiterName,
          description: `ID: #${sectionsDoc.id}`,
          value: sectionDocPath,
        });
      });
      dispatch(createSelect({
        id: 'productEditFormSections',
        select: {
          options: sectionSelectOptions,
          enableMultiple: true
        },
      }));
    });
    const unsubscribeProduct = Firebase.firestore().collection('product').doc(productId)
      .onSnapshot((productDoc) => {
        if (productDoc.exists) {
          const product = productDoc.data();
          if (product.rights.hasOwnProperty(user) && product.rights[user] >= 4) {
            setImage(product.image);
            setWaiterName(product.waiterName);
            setIsAvailable(product.isAvailable);
            setPrice(String(product.price));
            setTags(product.tags.join(','));
            dispatch(createTranslations({
              id: 'productEditFormProductName',
              translations: {
                input: ProductNameInput,
                value: product.hasOwnProperty('nameTranslations') && typeof product.nameTranslations === 'object' && Object.keys(product.nameTranslations).length > 0 ? product.nameTranslations : {}
              },
            }));
            dispatch(createTranslations({
              id: 'productEditFormProductDescription',
              translations: {
                input: ProductDescriptionInput,
                value: product.hasOwnProperty('descriptionTranslations') && typeof product.descriptionTranslations === 'object' && Object.keys(product.descriptionTranslations).length > 0 ? product.descriptionTranslations : {}
              },
            }));
            dispatch(changeSelect({
              id: 'productEditFormSections',
              select: {
                value: product.hasOwnProperty('sections') && Array.isArray(product.sections) && product.sections.length > 0 ? product.sections : []
              },
            }));
            dispatch(changeSelect({
              id: 'productEditFormCurrency',
              select: {
                value: product.currency
              },
            }));
            setDisabledForm(false);
          } else {
            setImage(null);
            setWaiterName('');
            setIsAvailable(false);
            setPrice('');
            setTags('');
            dispatch(createTranslations({
              id: 'productEditFormProductName',
              translations: {
                input: ProductNameInput,
                value: null
              },
            }));
            dispatch(createTranslations({
              id: 'productEditFormProductDescription',
              translations: {
                input: ProductDescriptionInput,
                value: null
              },
            }));
            dispatch(changeSelect({
              id: 'productEditFormSections',
              select: {
                value: []
              },
            }));
            dispatch(changeSelect({
              id: 'productEditFormCurrency',
              select: {
                value: null
              },
            }));
            setDisabledForm(true);
            alert(Translator.t('components.organisms.forms.ProductEditForm.error.noPermissions'));
          }
        }
      });
    return () => {
      unsubscribeSections();
      unsubscribeProduct();
    };
  }, []);

  return (
    <View>
      <ImageManager path={props.path} property='image' avatar={true}/>
      <ProductWaiterNameInput errors={errors.hasOwnProperty('ProductWaiterNameInput') ? errors.ProductWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <ProductPriceInput errors={errors.hasOwnProperty('ProductPriceInput') ? errors.ProductPriceInput : {}} value={price} onChange={(price) => setPrice(price)} disabled={disabledForm} />
      <ProductCurrencyInput errors={errors.hasOwnProperty('ProductCurrencyInput') ? errors.ProductCurrencyInput : {}} value={selects.hasOwnProperty('productEditFormCurrency') && selects.productEditFormCurrency.hasOwnProperty('value') ? selects.productEditFormCurrency.value : null} id="productEditFormCurrency" options={selects.hasOwnProperty('productEditFormCurrency') && selects.productEditFormCurrency.hasOwnProperty('options') ? selects.productEditFormCurrency.options : []} disabled={disabledForm}/>
      <ProductIsAvailableInput value={isAvailable} onChange={() => setIsAvailable(!isAvailable)} disabled={disabledForm} />
      <ProductTagsInput value={tags} onChange={(tags) => setTags(tags)} disabled={disabledForm} />
      <ProductNameTranslationsInput errors={errors.hasOwnProperty('ProductNameTranslationsInput') ? errors.ProductNameTranslationsInput : {}} value={translations.hasOwnProperty('productEditFormProductName') && translations.productEditFormProductName.hasOwnProperty('value') ? translations.productEditFormProductName.value : null} id="productEditFormProductName" disabled={disabledForm} />
      <ProductDescriptionTranslationsInput value={translations.hasOwnProperty('productEditFormProductDescription') && translations.productEditFormProductDescription.hasOwnProperty('value') ? translations.productEditFormProductDescription.value : null} id="productEditFormProductDescription" disabled={disabledForm} />
      <SectionsInput value={selects.hasOwnProperty('productEditFormSections') && selects.productEditFormSections.hasOwnProperty('value') ? selects.productEditFormSections.value : null} id="productEditFormSections" options={selects.hasOwnProperty('productEditFormSections') && selects.productEditFormSections.hasOwnProperty('options') ? selects.productEditFormSections.options : []} disabled={disabledForm} enableMultiple={selects.hasOwnProperty('productEditFormSections') && selects.productEditFormSections.hasOwnProperty('enableMultiple') ? selects.productEditFormSections.enableMultiple : false} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.ProductEditForm.submit.title')}</Button>
    </View>
  );
}

export default ProductEditForm;

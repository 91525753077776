import React from 'react';
import { StyleSheet } from 'react-native';
import { Col as DefaultCol } from 'react-native-responsive-grid-system';

function Col(props) {
  return (
    <DefaultCol colStyles={[styles.col, props.style]} {...props}>
      {props.children}
    </DefaultCol>
  );
}

const styles = StyleSheet.create({
  col: {
    paddingTop: 0,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  },
});

export default Col;

import { Platform, Alert } from 'react-native';

const showConfirmAlert = function (translator, confirmedCallback) {
  if (Platform.OS === 'web') {
    if (confirm(translator.t('components.atoms.elements.modal.templates.confirmModal.title') + "\n" + translator.t('components.atoms.elements.modal.templates.confirmModal.description'))) {
      confirmedCallback();
    }
  }
  else {
    Alert.alert(translator.t('components.atoms.elements.modal.templates.confirmModal.title'), translator.t('components.atoms.elements.modal.templates.confirmModal.description'), [
      {
        text: translator.t('components.atoms.elements.modal.templates.confirmModal.actions.no.title'),
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: translator.t('components.atoms.elements.modal.templates.confirmModal.actions.yes.title'),
        onPress: () => {
          confirmedCallback();
        }
      }
    ]);
  }
}

export { showConfirmAlert };

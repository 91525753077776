import React from 'react';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Firebase from '../../../modules/Firebase';
import Translator from '../../../modules/Translator';
import { changeIsLoading } from '../../../redux/reducers/PageReducer';
import { prepareLocale } from '../../../modules/Helper';

function LogoutButton(props) {
  const mode = props.hasOwnProperty('mode') ? props.mode : 'contained';
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const handlePress = function () {
    dispatch(changeIsLoading(true));
    Firebase.auth().signOut().then(() => {
      alert(Translator.t('components.atoms.buttons.LogoutButton.success.title'));
    }).catch((error) => {
      alert(error.message);
    })
      .then(() => {
        dispatch(changeIsLoading(false));
      });
  };

  return (
    <Button mode={mode} onPress={handlePress}>{Translator.t('components.atoms.buttons.LogoutButton.title')}</Button>
  );
}

export default LogoutButton;

import React from 'react';
import { useSelector } from 'react-redux';

import TranslationsInput from '../../atoms/inputs/TranslationsInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function ProductNameTranslationsInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <TranslationsInput
      label={Translator.t('components.molecules.inputs.ProductNameTranslationsInput.label')}
      {...props}
    />
  );
}

export default ProductNameTranslationsInput;

import React, { useState, useEffect } from 'react';
import { Title, Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import Card from '../atoms/elements/Card';
import View from '../atoms/layouts/View';
import Section from '../atoms/layouts/Section';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Firebase from '../../modules/Firebase';
import { buildDocPath, parseDocPath, prepareLocale } from '../../modules/Helper';
import Translator from '../../modules/Translator';
import Countries from '../../modules/Countries';
import BrandList from '../organisms/lists/BrandList';
import { useSelector } from 'react-redux';
import { capitalize } from 'stringulation';
import RightsManager from '../atoms/buttons/RightsManager';
import DashboardLayout from '../atoms/layouts/DashboardLayout';

function CompanyGetItem(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const companyId = props.route.params.companyId;
  const path = buildDocPath({
    company: companyId
  });
  const user = useSelector((state) => state.auth.user);
  const [company, setCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [brands, setBrands] = useState([]);
  const countries = Countries();

  useEffect(() => {
    const unsubscribeCompany = Firebase.firestore().collection('company').doc(companyId).onSnapshot((companyDoc) => {
      if (companyDoc.exists) {
        const company = companyDoc.data();
        setCompany(company);
        setIsFound(true);
        setIsAllowed(company.rights.hasOwnProperty(user) && company.rights[user] >= 1);
        setIsEditAllowed(company.rights.hasOwnProperty(user) && company.rights[user] >= 4);
        props.navigation.setOptions({
          title: company.name
        });
      }
      setIsLoading(false);
    });
    const unsubscribeBrands = Firebase.firestore().collection('company').doc(companyId).collection('brand').where(`rights.${user}`, '>', 0).onSnapshot((brandsRef) => {
      const brandsDocs = brandsRef.docs;
      const brands = [];
      brandsDocs.forEach((brandsDoc) => {
        const brandsDocParsedPath = parseDocPath(brandsDoc.ref.path);
        const brand = brandsDoc.data();
        brands.push({
          cover: brand.logo,
          name: brand.name,
          companyId: brandsDocParsedPath.company,
          brandId: brandsDoc.id,
          enableView: brand.rights[user] >= 1,
          enableEdit: brand.rights[user] >= 4,
          enableDelete: brand.rights[user] >= 7,
        });
      });
      setBrands(brands);
    });
    return () => {
      unsubscribeCompany();
      unsubscribeBrands();
    };
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isFound) {
    return (
      <DashboardLayout>
        <View>
          <Title>{capitalize(Translator.t('words.notFound'), true)}</Title>
        </View>
      </DashboardLayout>
    );
  }

  if (!isAllowed) {
    return (
      <DashboardLayout>
        <View>
          <Title>{Translator.t('messages.notAllowed')}</Title>
        </View>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Section title={Translator.t('pages.CompanyGetItem.content.generalInformation.title')} description={Translator.t('pages.CompanyGetItem.content.generalInformation.description')}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.CompanyGetItem.content.generalInformation.phone.title')} subtitle={Translator.t('pages.CompanyGetItem.content.generalInformation.phone.description')} icon="phone">
                  <Title>{'+ ' + company.phoneCode + ' ' + company.phoneNumber}</Title>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.CompanyGetItem.content.generalInformation.countryState.title')} subtitle={Translator.t('pages.CompanyGetItem.content.generalInformation.countryState.description')} icon="pin">
                  <Title>{countries.getName(company.country, Translator.locale)}, {company.state}</Title>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.CompanyGetItem.content.generalInformation.address.title')} subtitle={Translator.t('pages.CompanyGetItem.content.generalInformation.address.description')} icon="pin">
                  <Title>{company.city} {company.postalCode},</Title>
                  <Title>{company.addressLine1},</Title>
                  <Title>{company.addressLine2}</Title>
                </Card>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.CompanyGetItem.content.management.title')} description={Translator.t('pages.CompanyGetItem.content.management.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Button mode='contained' onPress={() => {
                  navigation.push('CompanyUpdateItem', {
                    companyId
                  });
                }}>{Translator.t('words.edit')}</Button>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.CompanyGetItem.content.rights.title')} description={Translator.t('pages.CompanyGetItem.content.rights.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <RightsManager path={path} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.CompanyGetItem.content.brands.title')} description={Translator.t('pages.CompanyGetItem.content.brands.description')} hideBottomDivider={true}>
            <BrandList brands={brands} hasAddNewButton={true} addNewButtonParams={{
              path: path
            }} {...props} />
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default CompanyGetItem;

import React, { useEffect } from 'react';
import {
  Platform, StyleSheet, View, Image,
} from 'react-native';
import { Avatar, Button, useTheme } from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function ImageInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const theme = useTheme();
  const { value } = props;
  const { setValue } = props;
  const showAsAvatar = props.hasOwnProperty('avatar') && props.avatar === true;
  const label = props.hasOwnProperty('label') ? props.label : Translator.t('components.atoms.inputs.ImageInput.label');
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    imageContainer: {
      marginBottom: 10,
    },
    avatar: {
      width: null,
      height: null,
      backgroundColor: null,
      borderWidth: 1,
      borderColor: theme.colors.primary,
    },
    image: {
      width: 128,
      height: value && value.height / (value.width / 128),
      resizeMode: 'stretch',
      backgroundColor: null,
      borderWidth: 1,
      borderColor: theme.colors.primary,
      borderRadius: 10,
    },
  });
  const pickImage = async function () {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setValue(result);
    }
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert(Translator.t('components.atoms.inputs.ImageInput.error.noAccess'));
        }
      }
    })();
  }, []);

  return (
    <View style={styles.container}>
      { value && (
        <View style={styles.imageContainer}>
          { showAsAvatar ? (
            <Avatar.Image size={128} source={{ uri: value.uri }} style={styles.avatar} />
          ) : (
            <Image source={{ uri: value.uri }} style={styles.image} />
          ) }
        </View>
      ) }
      <Button mode="outlined" onPress={pickImage} {...props}>{ label }</Button>
    </View>
  );
}

export default ImageInput;

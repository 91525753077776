import React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Col from '../atoms/layouts/Col';
import Row from '../atoms/layouts/Row';
import Translator from '../../modules/Translator';
import CenterView from '../atoms/layouts/CenterView';
import ResetForm from '../organisms/forms/ResetForm';
import { prepareLocale } from '../../modules/Helper';

function Reset(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <CenterView>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <ResetForm />
        </Col>
      </Row>
      <Row style={styles.loginButtonRow}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Button mode="outlined" onPress={() => props.navigation.navigate('Login')}>{ Translator.t('pages.Login.title') }</Button>
        </Col>
      </Row>
    </CenterView>
  );
}

const styles = StyleSheet.create({
  loginButtonRow: {
    marginTop: 20,
  },
});

export default Reset;

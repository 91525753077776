import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import MapView from 'react-native-maps';

import GoogleMap from './GoogleMap';

function LocationView(props) {
  const location = props.hasOwnProperty('location') ? props.location : null;

  if (location === null) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.mapContainer}>
        {
          Platform.OS !== 'web' ? (
            <MapView style={styles.map}>
              <MapView.Marker coordinate={location} />
            </MapView>
          ) : (
            <GoogleMap style={styles.map} value={location} />
          )
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  mapContainer: {
    width: '100%',
    height: 400,
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  }
});

export default LocationView;

import * as Localization from 'expo-localization';
import * as Device from 'expo-device';

import Config from '../Config';
import Currencies from './Currencies';
import Timezones from './Timezones';

function parseDocPath(path) {
  const parsedDocPath = {};
  const pathParts = path.split('/');
  pathParts.forEach((pathPart, pathPartKey) => {
    if (pathPartKey % 2 === 0) {
      parsedDocPath[pathPart] = pathParts[pathPartKey + 1];
    }
  });
  return parsedDocPath;
}

function buildDocPath(parameters) {
  let docPath = '';
  for (const [parameterKey, parameter] of Object.entries(parameters)) {
    docPath += `${parameterKey}/${parameter}/`;
  }
  docPath = docPath.substring(0, docPath.length - 1);
  return docPath;
}

function parsePriceToFloat(priceAsString) {
  const price = parseFloat(priceAsString);
  if (isNaN(price)) {
    return 0;
  }

  return price;
}

function canPriceBeParseToFloat(priceAsString) {
  const price = parseFloat(priceAsString);
  return !isNaN(price);
}

function generateAcronym(string) {
  const matches = string.match(/\b(\w)/g);
  return matches.join('');
}

function isStringEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

function prepareTheme(theme, colorScheme) {
  if (theme === 'auto') {
    return Config.supportedThemes.includes(colorScheme) ? colorScheme : 'light';
  }
  else {
    return Config.supportedThemes.includes(theme) ? theme : 'light';
  }
}

function prepareLocale(locale) {
  if (locale === 'auto') {
    return Config.supportedLocales.includes(Localization.locale.split('-')[0]) ? Localization.locale.split('-')[0] : 'bg';
  }
  else {
    return Config.supportedLocales.includes(locale) ? locale : 'bg';
  }
}

function prepareCurrency(currency) {
  const currencies = Currencies();
  if (currency === 'auto') {
    if (Localization.currency !== null) {
      return Object.keys(currencies.getAll('en')).includes(Localization.currency) ? Localization.currency : 'BGN';
    }
    else {
      return 'BGN';
    }
  }
  else {
    return Object.keys(currencies.getAll('en')).includes(currency) ? currency : 'BGN';
  }
}

function prepareTimezone(timezone) {
  const timezones = Timezones();
  if (timezone === 'auto') {
    return timezones.includes(Localization.timezone) ? Localization.timezone : 'Europe/Sofia';
  }
  else {
    return timezones.includes(timezone) ? timezone : 'Europe/Sofia';
  }
}

function getDevice() {
  const device = {};
  Object.keys(Device).forEach(function (infoKey) {
    if (typeof Device[infoKey] !== 'function' && infoKey.charAt(0) === infoKey.charAt(0).toLowerCase() && infoKey.charAt(0) !== infoKey.charAt(0).toUpperCase()) {
      device[infoKey] = Device[infoKey];
    }
  });
  return device;
}

function changeTimeZoneOfDate(date, timeZone) {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
}

function getLastUpdateDateOfOrder(order) {
  if (order.hasOwnProperty('status') && Config.orderStatuses.includes(order.status)) {
    const propertyNameOfStatusChange = getPropertyNameOfStatusChange(order.status);
    return order.hasOwnProperty(propertyNameOfStatusChange) ? order[propertyNameOfStatusChange] : null;
  }
  else {
    return null;
  }
}

function getLastUpdateDateOfOrderProduct(orderProduct) {
  if (orderProduct.hasOwnProperty('status') && Config.orderProductStatuses.includes(orderProduct.status)) {
    const propertyNameOfStatusChange = getPropertyNameOfStatusChange(orderProduct.status);
    return orderProduct.hasOwnProperty(propertyNameOfStatusChange) ? orderProduct[propertyNameOfStatusChange] : null;
  }
  else {
    return null;
  }
}

function translateOrderStatus(status, Translator) {
  return Translator.t('order.statuses.' + status);
}

function translateOrderProductStatus(status, Translator) {
  return Translator.t('orderProduct.statuses.' + status);
}

function getPropertyNameOfStatusChange(status) {
  const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );

  return snakeToCamel(status) + "At";
}

function convertTimezone(date, toTimezone) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: toTimezone}));
}

function outputDate(date, locale, options = {}) {
  return date.toLocaleDateString(locale, {
    localeMatcher: undefined,
    weekday: undefined,
    era: undefined,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: undefined,
    minute: undefined,
    second: undefined,
    timeZoneName: undefined,
    formatMatcher: undefined,
    hour12: undefined,
    timeZone: undefined,
    ... options
  });
}

function outputTime(date, locale, options = {}) {
  return date.toLocaleTimeString(locale, {
    localeMatcher: undefined,
    weekday: undefined,
    era: undefined,
    year: undefined,
    month: undefined,
    day: undefined,
    hour: "2-digit",
    minute: "2-digit",
    second: undefined,
    timeZoneName: undefined,
    formatMatcher: undefined,
    hour12: false,
    timeZone: undefined,
    ... options
  });
}

export {
  parseDocPath, buildDocPath, parsePriceToFloat, canPriceBeParseToFloat, generateAcronym, isStringEmail, prepareTheme, prepareLocale, prepareCurrency, prepareTimezone, getDevice, changeTimeZoneOfDate, getLastUpdateDateOfOrder, getLastUpdateDateOfOrderProduct, translateOrderStatus, translateOrderProductStatus, getPropertyNameOfStatusChange, convertTimezone, outputDate, outputTime
};

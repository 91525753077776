import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import TableWaiterNameInput from '../../molecules/inputs/TableWaiterNameInput';
import TableSeatsInput from '../../molecules/inputs/TableSeatsInput';
import StoreInput from '../../molecules/inputs/StoreInput';
import { changeIsLoading, createSelect, changeSelect } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function TableCreateForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const parentPath = props.hasOwnProperty('path') && props.path !== null ? props.path : null;
  const [disabledForm, setDisabledForm] = useState(true);
  const [waiterName, setWaiterName] = useState('');
  const [seats, setSeats] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["TableWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (!/^\d+$/.test(seats)) {
      errors["TableSeatsInput"] = {
        "requiredField": {}
      };
    }
    else if (parseInt(seats, 10) > 50 || parseInt(seats, 10) <= 0) {
      errors["TableSeatsInput"] = {
        "notValidValue": {}
      };
    }
    if (!selects.hasOwnProperty('tableCreateFormStore') || !selects.tableCreateFormStore.hasOwnProperty('value') || selects.tableCreateFormStore.value === null) {
      errors["StoreInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const storeDocParsedPath = parseDocPath(selects.tableCreateFormStore.value);
    Firebase.firestore().collection('company').doc(storeDocParsedPath.company).collection('brand').doc(storeDocParsedPath.brand).collection('store').doc(storeDocParsedPath.store).collection('table')
      .doc()
      .set({
        waiterName,
        seats: parseInt(seats, 10),
        rights: {
          [user]: 7,
        },
        notificationList: [user]
      })
      .then(() => {
        setWaiterName('');
        setSeats('');
        dispatch(changeSelect({
          id: 'tableCreateFormStore',
          select: {
            value: parentPath,
          },
        }));
        alert(Translator.t('components.organisms.forms.TableCreateForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('store').where(`rights.${user}`, '>=', 7).onSnapshot((storesRef) => {
      const storesDocs = storesRef.docs;
      const storeSelectOptions = [];
      storesDocs.forEach((storesDoc) => {
        const storeDocPath = storesDoc.ref.path;
        const store = storesDoc.data();
        storeSelectOptions.push({
          icon: 'store',
          label: store.name,
          description: `ID: #${storesDoc.id}`,
          value: storeDocPath,
        });
      });
      dispatch(createSelect({
        id: 'tableCreateFormStore',
        select: {
          options: storeSelectOptions,
          value: parentPath
        },
      }));
      setDisabledForm(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <TableWaiterNameInput errors={errors.hasOwnProperty('TableWaiterNameInput') ? errors.TableWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <TableSeatsInput errors={errors.hasOwnProperty('TableSeatsInput') ? errors.TableSeatsInput : {}} value={seats} onChange={(seats) => setSeats(seats)} disabled={disabledForm} />
      <StoreInput errors={errors.hasOwnProperty('StoreInput') ? errors.StoreInput : {}} value={selects.hasOwnProperty('tableCreateFormStore') && selects.tableCreateFormStore.hasOwnProperty('value') ? selects.tableCreateFormStore.value : null} id="tableCreateFormStore" options={selects.hasOwnProperty('tableCreateFormStore') && selects.tableCreateFormStore.hasOwnProperty('options') ? selects.tableCreateFormStore.options : []} disabled={disabledForm || parentPath !== null} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.TableCreateForm.submit.title')}</Button>
    </View>
  );
}

export default TableCreateForm;

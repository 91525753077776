import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import MenuWaiterNameInput from '../../molecules/inputs/MenuWaiterNameInput';
import BrandInput from '../../molecules/inputs/BrandInput';
import { changeIsLoading, createSelect, changeSelect } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MenuCreateForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const parentPath = props.hasOwnProperty('path') && props.path !== null ? props.path : null;
  const [disabledForm, setDisabledForm] = useState(true);
  const [waiterName, setWaiterName] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["MenuWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (!selects.hasOwnProperty('menuCreateFormBrand') || !selects.menuCreateFormBrand.hasOwnProperty('value') || selects.menuCreateFormBrand.value === null) {
      errors["BrandInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const brandDocParsedPath = parseDocPath(selects.menuCreateFormBrand.value);
    Firebase.firestore().collection('company').doc(brandDocParsedPath.company).collection('brand')
      .doc(brandDocParsedPath.brand)
      .collection('menu')
      .doc()
      .set({
        waiterName,
        rights: {
          [user]: 7,
        },
      })
      .then(() => {
        setWaiterName('');
        dispatch(changeSelect({
          id: 'menuCreateFormBrand',
          select: {
            value: parentPath,
          },
        }));
        alert(Translator.t('components.organisms.forms.MenuCreateForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('brand').where(`rights.${user}`, '>=', 7).onSnapshot((brandsRef) => {
      const brandsDocs = brandsRef.docs;
      const brandSelectOptions = [];
      brandsDocs.forEach((brandsDoc) => {
        const brandDocPath = brandsDoc.ref.path;
        const brand = brandsDoc.data();
        brandSelectOptions.push({
          icon: 'star',
          label: brand.name,
          description: `ID: #${brandsDoc.id}`,
          value: brandDocPath,
        });
      });
      dispatch(createSelect({
        id: 'menuCreateFormBrand',
        select: {
          options: brandSelectOptions,
          value: parentPath
        },
      }));
      setDisabledForm(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <MenuWaiterNameInput errors={errors.hasOwnProperty('MenuWaiterNameInput') ? errors.MenuWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <BrandInput errors={errors.hasOwnProperty('BrandInput') ? errors.BrandInput : {}} value={selects.hasOwnProperty('menuCreateFormBrand') && selects.menuCreateFormBrand.hasOwnProperty('value') ? selects.menuCreateFormBrand.value : null} id="menuCreateFormBrand" options={selects.hasOwnProperty('menuCreateFormBrand') && selects.menuCreateFormBrand.hasOwnProperty('options') ? selects.menuCreateFormBrand.options : []} disabled={disabledForm || parentPath !== null} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.MenuCreateForm.submit.title')}</Button>
    </View>
  );
}

export default MenuCreateForm;

import React from 'react';
import { StyleSheet } from 'react-native';
import { Row as DefaultRow } from 'react-native-responsive-grid-system';

function Row(props) {
  return (
    <DefaultRow rowStyles={[styles.row, props.style]} {...props}>
      {props.children}
    </DefaultRow>
  );
}

const styles = StyleSheet.create({
  row: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  },
});

export default Row;

import { createSlice } from '@reduxjs/toolkit';

import Config from '../../Config';

export const DesignSlice = createSlice({
  name: 'design',
  initialState: {
    theme: Config.defaultTheme,
  },
  reducers: {
    changeTheme: (state, action) => {
      state.theme = Config.supportedThemes.includes(action.payload) ? action.payload : Config.defaultTheme;
    }
  },
});

export const {
  changeTheme,
} = DesignSlice.actions;

export default DesignSlice.reducer;

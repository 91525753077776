import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import StoreNameInput from '../../molecules/inputs/StoreNameInput';
import StoreLocationInput from '../../molecules/inputs/StoreLocationInput';
import BrandInput from '../../molecules/inputs/BrandInput';
import {
  changeIsLoading,
  createSelect,
  changeSelect,
  changeLocation, createLocation
} from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import StoreIsCashPaymentSupportedInput
  from '../../molecules/inputs/StoreIsCashPaymentSupportedInput';
import StoreIsCardTerminalPaymentSupportedInput
  from '../../molecules/inputs/StoreIsCardTerminalPaymentSupportedInput';
import StoreIsCardAppPaymentSupportedInput
  from '../../molecules/inputs/StoreIsCardAppPaymentSupportedInput';
import StoreIsTipSupportedInput from '../../molecules/inputs/StoreIsTipSupportedInput';

function StoreCreateForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const locations = useSelector((state) => state.page.locations);
  const parentPath = props.hasOwnProperty('path') && props.path !== null ? props.path : null;
  const [disabledForm, setDisabledForm] = useState(true);
  const [name, setName] = useState('');
  const [isCashPaymentSupported, setIsCashPaymentSupported] = useState(false);
  const [isCardTerminalPaymentSupported, setIsCardTerminalPaymentSupported] = useState(false);
  const [isCardAppPaymentSupported, setIsCardAppPaymentSupported] = useState(false);
  const [isTipSupported, setIsTipSupported] = useState(false);
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof name !== 'string' || name.length === 0) {
      errors["StoreNameInput"] = {
        "requiredField": {}
      };
    }
    if (!locations.hasOwnProperty('storeCreateFormLocation') || !locations.storeCreateFormLocation.hasOwnProperty('value') || locations.storeCreateFormLocation.value === null) {
      errors["StoreLocationInput"] = {
        "requiredField": {}
      };
    }
    if (!selects.hasOwnProperty('storeCreateFormBrand') || !selects.storeCreateFormBrand.hasOwnProperty('value') || selects.storeCreateFormBrand.value === null) {
      errors["BrandInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const brandDocParsedPath = parseDocPath(selects.storeCreateFormBrand.value);
    Firebase.firestore().collection('company').doc(brandDocParsedPath.company).collection('brand')
      .doc(brandDocParsedPath.brand)
      .collection('store')
      .doc()
      .set({
        name,
        location: locations.storeCreateFormLocation.value,
        isCashPaymentSupported,
        isCardTerminalPaymentSupported,
        isCardAppPaymentSupported,
        isTipSupported,
        rights: {
          [user]: 7,
        },
      })
      .then(() => {
        setName('');
        dispatch(changeSelect({
          id: 'storeCreateFormBrand',
          select: {
            value: parentPath,
          },
        }));
        dispatch(changeLocation({
          id: 'storeCreateFormLocation',
          location: {
            value: null
          },
        }));
        alert(Translator.t('components.organisms.forms.StoreCreateForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    dispatch(createLocation({
      id: 'storeCreateFormLocation',
      location: {
        value: null
      },
    }));
    const unsubscribe = Firebase.firestore().collectionGroup('brand').where(`rights.${user}`, '>=', 7).onSnapshot((brandsRef) => {
      const brandsDocs = brandsRef.docs;
      const brandSelectOptions = [];
      brandsDocs.forEach((brandsDoc) => {
        const brandDocPath = brandsDoc.ref.path;
        const brand = brandsDoc.data();
        brandSelectOptions.push({
          icon: 'star',
          label: brand.name,
          description: `ID: #${brandsDoc.id}`,
          value: brandDocPath,
        });
      });
      dispatch(createSelect({
        id: 'storeCreateFormBrand',
        select: {
          options: brandSelectOptions,
          value: parentPath
        },
      }));
      setDisabledForm(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <StoreNameInput errors={errors.hasOwnProperty('StoreNameInput') ? errors.StoreNameInput : {}} value={name} onChange={(name) => setName(name)} disabled={disabledForm} />
      <StoreLocationInput errors={errors.hasOwnProperty('StoreLocationInput') ? errors.StoreLocationInput : {}} value={locations.hasOwnProperty('storeCreateFormLocation') && locations.storeCreateFormLocation.hasOwnProperty('value') ? locations.storeCreateFormLocation.value : null} id="storeCreateFormLocation" disabled={disabledForm}/>
      <StoreIsCashPaymentSupportedInput value={isCashPaymentSupported} onChange={() => setIsCashPaymentSupported(!isCashPaymentSupported)} disabled={disabledForm} />
      <StoreIsCardTerminalPaymentSupportedInput value={isCardTerminalPaymentSupported} onChange={() => setIsCardTerminalPaymentSupported(!isCardTerminalPaymentSupported)} disabled={disabledForm} />
      <StoreIsCardAppPaymentSupportedInput value={isCardAppPaymentSupported} onChange={() => setIsCardAppPaymentSupported(!isCardAppPaymentSupported)} disabled={disabledForm} />
      <StoreIsTipSupportedInput value={isTipSupported} onChange={() => setIsTipSupported(!isTipSupported)} disabled={disabledForm} />
      <BrandInput errors={errors.hasOwnProperty('BrandInput') ? errors.BrandInput : {}} value={selects.hasOwnProperty('storeCreateFormBrand') && selects.storeCreateFormBrand.hasOwnProperty('value') ? selects.storeCreateFormBrand.value : null} id="storeCreateFormBrand" options={selects.hasOwnProperty('storeCreateFormBrand') && selects.storeCreateFormBrand.hasOwnProperty('options') ? selects.storeCreateFormBrand.options : []} disabled={disabledForm || parentPath !== null} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.StoreCreateForm.submit.title')}</Button>
    </View>
  );
}

export default StoreCreateForm;

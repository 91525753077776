import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { capitalize } from 'stringulation';
import { Avatar, Button } from 'react-native-paper';

import Firebase from '../../../modules/Firebase';
import { Text } from 'react-native-paper';
import Translator from '../../../modules/Translator';
import { generateAcronym, prepareLocale } from '../../../modules/Helper';
import Config from '../../../Config';
import EmailInput from '../../molecules/inputs/EmailInput';
import DropdownInput from '../inputs/DropdownInput';
import Chip from '../elements/Chip';

function RightsManager(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const path = props.hasOwnProperty('path') ? props.path : null;
  const user = useSelector((state) => state.auth.user);
  const [rights, setRights] = useState({});
  const [users, setUsers] = useState({});
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRights, setNewUserRights] = useState(Config.defaultRights);
  const [disabledForm, setDisabledForm] = useState(false);
  const options = [];
  const handleSubmit = function () {
    if (!Object.keys(Config.supportedRights).includes(newUserRights.toString())) {
      alert(Translator.t('components.atoms.buttons.RightsManager.error.invalidField'));
      return;
    }
    setDisabledForm(true);
    Firebase.firestore().collection('user').where('email', '==', newUserEmail).get().then((usersRef) => {
      const usersDocs = usersRef.docs;
      if (usersDocs.length > 0) {
        const usersDoc = usersDocs[0];
        const newUserId = usersDoc.id;
        if (Object.keys(rights).includes(newUserId)) {
          alert(Translator.t('components.atoms.buttons.RightsManager.error.alreadyExists'));
          setNewUserEmail('');
          setDisabledForm(false);
          return;
        }
        Firebase.firestore().doc(path).set({
          rights: {
            ...rights,
            [newUserId]: parseInt(newUserRights, 10)
          }
        }, {
          merge: true,
        }).then(function () {
          setRights({
            ...rights,
            [newUserId]: parseInt(newUserRights, 10)
          });
          setNewUserEmail('');
          setNewUserRights(Config.defaultRights);
          setDisabledForm(false);
        }).catch((error) => {
          alert(error.message);
          setDisabledForm(false);
        });
      }
      else {
        alert(Translator.t('components.atoms.buttons.RightsManager.error.notExistingUser'));
        setDisabledForm(false);
      }
    }).catch((error) => {
      alert(error.message);
      setDisabledForm(false);
    });
  };
  const onClose = function (removeUserId) {
    const newRights = rights;
    delete newRights[removeUserId];
    Firebase.firestore().doc(path).update({
      rights: newRights
    }).catch((error) => {
      alert(error.message);
    });
  };

  Object.keys(Config.supportedRights).forEach(function (supportedRights, supportedRightsKey) {
    options[supportedRights] = Translator.t('rights.' + Config.supportedRights[supportedRights]);
  });

  useEffect(() => {
    const unsubscribe = Firebase.firestore().doc(path).onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const rights = data.hasOwnProperty('rights') ? data.rights : {};
        setRights(rights);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let users = {};
    const unsubscribeUsers = [];
    for (const userId in rights) {
      const unsubscribe = Firebase.firestore().collection('user').doc(userId).onSnapshot((userDoc) => {
        if (userDoc.exists) {
          const user = userDoc.data()
          users = {
            ...users,
            [userId]: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              rights: rights[userId]
            }
          };
          setUsers(users);
        }
      });
      unsubscribeUsers.push(unsubscribe);
    }
    return () => {
      if (unsubscribeUsers.length !== 0) {
        unsubscribeUsers.forEach((unsubscribeUser, unsubscribeUsersKey) => {
          unsubscribeUser();
        });
      }
    };
  }, [rights]);

  return (
    <View>
      <View>
        <EmailInput value={newUserEmail} onChange={(newUserEmail) => setNewUserEmail(newUserEmail)} disabled={disabledForm} />
        <DropdownInput style={styles.newUserRightsSelector} mode={'outlined'} value={newUserRights} options={options} onChange={(newUserRights) => setNewUserRights(newUserRights)} disabled={disabledForm}/>
        <Button style={styles.newUserSubmitButton} mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.atoms.buttons.RightsManager.submit.title')}</Button>
      </View>
      <View style={styles.chipContainer}>
        {
          Object.keys(users).map((userId) => (
            <Chip onClose={userId !== user ? (() => {
              onClose(userId);
            }) : null} avatar={<Avatar.Text size={20} label={generateAcronym(capitalize(users[userId].firstName + ' ' + users[userId].lastName))} />} key={userId} size={30}>
              <Text>
                {users[userId].email}{user === userId ? ' (' + capitalize(Translator.t('words.you')) + ')' : ''} ({Translator.t('rights.' + Config.supportedRights[users[userId].rights])})
              </Text>
            </Chip>
          ))
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  newUserRightsSelector: {
    marginBottom: 10
  },
  newUserSubmitButton: {
    marginBottom: 10
  }
});

export default RightsManager;

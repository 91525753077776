import React, { useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import EmailInput from '../../molecules/inputs/EmailInput';
import PasswordInput from '../../molecules/inputs/PasswordInput';
import Firebase from '../../../modules/Firebase';
import { changeIsLoading } from '../../../redux/reducers/PageReducer';
import { isStringEmail, prepareLocale } from '../../../modules/Helper';
import { translateFirebaseError } from '../../../modules/FirebaseErrors';

function LoginForm() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const [disabledForm, setDisabledForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof email !== 'string' || email.length === 0) {
      errors["EmailInput"] = {
        "requiredField": {}
      };
    }
    else if (!isStringEmail(email)) {
      errors["EmailInput"] = {
        "notValidValue": {}
      };
    }
    if (typeof password !== 'string' || password.length === 0) {
      errors["PasswordInput"] = {
        "requiredField": {}
      };
    }
    else if (password.length < 6 || password.length > 30) {
      errors["PasswordInput"] = {
        "notValidValue": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
      alert(Translator.t('components.organisms.forms.LoginForm.success.title'));
    }).catch((error) => {
      alert(translateFirebaseError(error.code, Translator.locale, error.message));
    })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  return (
    <View>
      <EmailInput errors={errors.hasOwnProperty('EmailInput') ? errors.EmailInput : {}} value={email} onChange={(email) => setEmail(email)} disabled={disabledForm} />
      <PasswordInput errors={errors.hasOwnProperty('PasswordInput') ? errors.PasswordInput : {}} value={password} onChange={(password) => setPassword(password)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.LoginForm.submit.title')}</Button>
    </View>
  );
}

export default LoginForm;

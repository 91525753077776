function generateTableQrCodeHtml(table) {
  let html = '';
  for (let i = 1; i <= table.seats; i++) {
    html += '<div style="display: inline-block; padding: 5px; margin: 3px; border-style: dashed; border-width: 2px; border-color: #FF0000FF">';
    html += table.qrCode;
    html += '<span style="display: block">' + table.waiterName + '/' + i + '</span>';
    html += '</div>';
  }
  return html;
}

export { generateTableQrCodeHtml };

import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Card from '../../atoms/elements/Card';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { showConfirmAlert } from '../../../modules/Alert';
import { prepareLocale } from '../../../modules/Helper';

function BrandCard({
  cover, avatar, name, companyId, brandId, enableView, enableEdit, enableDelete, customActions,
}) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  enableView = typeof enableView !== 'undefined' && enableView;
  enableEdit = typeof enableEdit !== 'undefined' && enableEdit;
  enableDelete = typeof enableDelete !== 'undefined' && enableDelete;

  const actions = [];

  if (enableView) {
    actions.push({
      title: Translator.t('words.view'),
      mode: 'contained',
      onPress() {
        navigation.push('BrandGetItem', {
          companyId,
          brandId,
        });
      },
    });
  }

  if (enableEdit) {
    actions.push({
      title: Translator.t('words.edit'),
      mode: 'outlined',
      onPress() {
        navigation.push('BrandUpdateItem', {
          companyId,
          brandId,
        });
      },
    });
  }

  if (enableDelete) {
    actions.push({
      title: Translator.t('words.delete'),
      mode: 'outlined',
      onPress: function () {
        showConfirmAlert(Translator, function () {
          Firebase.firestore().collection('company').doc(companyId).collection('brand')
            .doc(brandId)
            .delete()
            .then(() => {
              alert(Translator.t('components.organisms.items.BrandCard.enableDelete.success.title'));
            })
            .catch((error) => {
              alert(error.message);
            });
          Firebase.storage().ref().child(`images/brand/${brandId}`).delete()
            .catch((error) => {
              // alert(error.message);
            });
        });
      },
    });
  }

  return (
    <Card avatar={avatar} cover={typeof cover !== 'undefined' && cover !== null ? cover : 'https://firebasestorage.googleapis.com/v0/b/smart-take.appspot.com/o/images%2Fbrand%2Fdefault.jpg?alt=media&token=d5097ace-988e-4845-901c-964b988948b3'} title={name} subtitle={`ID: #${brandId}`} icon="star" actions={actions.concat(typeof customActions === 'undefined' ? [] : customActions)} />
  );
}

export default BrandCard;

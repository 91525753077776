import React from 'react';
import { StyleSheet, View } from 'react-native';
import { GoogleMap as DefaultGoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import * as FirebaseCore from 'expo-firebase-core';

const styles = StyleSheet.create({
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  webMap: {
    height: '100%'
  }
});

const MapEnvironment = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${FirebaseCore.DEFAULT_APP_OPTIONS.apiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <View style={styles.webMap} />,
    containerElement: <View style={styles.map}/>,
    mapElement: <View style={styles.webMap} />,
  }),
  withScriptjs,
  withGoogleMap
);

const MapLayout = (props) => (
  <DefaultGoogleMap defaultZoom={8} defaultCenter={{ lat: props.value.latitude, lng: props.value.longitude }}>
    <Marker position={{ lat: props.value.latitude, lng: props.value.longitude }} />
  </DefaultGoogleMap>
);

const GoogleMap = MapEnvironment(MapLayout);

export default GoogleMap;

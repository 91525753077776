import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import MenuList from './MenuList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MyMenuList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('menu').where(`rights.${user}`, '>', 0).onSnapshot((menusRef) => {
      const menusDocs = menusRef.docs;
      const menus = [];
      menusDocs.forEach((menusDoc) => {
        const menusDocParsedPath = parseDocPath(menusDoc.ref.path);
        const menu = menusDoc.data();
        menus.push({
          name: menu.waiterName,
          companyId: menusDocParsedPath.company,
          brandId: menusDocParsedPath.brand,
          menuId: menusDoc.id,
          enableView: menu.rights[user] >= 1,
          enableEdit: menu.rights[user] >= 4,
          enableDelete: menu.rights[user] >= 7,
        });
      });
      setMenus(menus);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MyMenuList.section.title')} description={Translator.t('components.organisms.lists.MyMenuList.section.description')}>
      <MenuList menus={menus} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MyMenuList;

import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Card from '../../atoms/elements/Card';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { showConfirmAlert } from '../../../modules/Alert';
import { prepareLocale } from '../../../modules/Helper';

function ProductCard({
                     cover, avatar, name, productId, enableView, enableEdit, enableDelete, customActions,
                   }) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  enableView = typeof enableView !== 'undefined' && enableView;
  enableEdit = typeof enableEdit !== 'undefined' && enableEdit;
  enableDelete = typeof enableDelete !== 'undefined' && enableDelete;

  const actions = [];

  if (enableView) {
    actions.push({
      title: Translator.t('words.view'),
      mode: 'contained',
      onPress() {
        navigation.push('ProductGetItem', {
          productId
        });
      },
    });
  }

  if (enableEdit) {
    actions.push({
      title: Translator.t('words.edit'),
      mode: 'outlined',
      onPress() {
        navigation.push('ProductUpdateItem', {
          productId
        });
      },
    });
  }

  if (enableDelete) {
    actions.push({
      title: Translator.t('words.delete'),
      mode: 'outlined',
      onPress: function () {
        showConfirmAlert(Translator, function () {
          Firebase.firestore().collection('product').doc(productId)
            .delete()
            .then(() => {
              alert(Translator.t('components.organisms.items.ProductCard.enableDelete.success.title'));
            })
            .catch((error) => {
              alert(error.message);
            });
          Firebase.storage().ref().child(`images/product/${productId}`).delete()
            .catch((error) => {
              // alert(error.message);
            });
        });
      },
    });
  }

  return (
    <Card avatar={avatar} cover={typeof cover !== 'undefined' && cover !== null ? cover : 'https://firebasestorage.googleapis.com/v0/b/smart-take.appspot.com/o/images%2Fproduct%2Fdefault.jpg?alt=media&token=cc3fd42e-3373-45b6-83a6-6cfb92257ca1'} title={name} subtitle={`ID: #${productId}`} icon="shopping" actions={actions.concat(typeof customActions === 'undefined' ? [] : customActions)} />
  );
}

export default ProductCard;

import React from 'react';
import { StyleSheet } from 'react-native';
import { Chip as DefaultChip, useTheme } from 'react-native-paper';

function Chip(props) {
  const theme = useTheme();
  const styles = StyleSheet.create({
    chip: {
      marginLeft: 2,
      marginRight: 2,
      marginTop: 1,
      marginBottom: 1,
      backgroundColor: theme.colors.border
    }
  });

  return (
    <DefaultChip style={styles.chip} {...props}>{props.children}</DefaultChip>
  );
}

export default Chip;

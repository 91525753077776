import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import SectionWaiterNameInput from '../../molecules/inputs/SectionWaiterNameInput';
import { changeIsLoading, createTranslations } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import SectionNameInput from '../../molecules/inputs/SectionNameInput';
import SectionDescriptionInput from '../../molecules/inputs/SectionDescriptionInput';
import SectionNameTranslationsInput from '../../molecules/inputs/SectionNameTranslationsInput';
import SectionDescriptionTranslationsInput
  from '../../molecules/inputs/SectionDescriptionTranslationsInput';

function SectionEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const companyId = parsedDocPath.company;
  const brandId = parsedDocPath.brand;
  const menuId = parsedDocPath.menu;
  const sectionId = parsedDocPath.section;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const translations = useSelector((state) => state.page.translations);
  const [disabledForm, setDisabledForm] = useState(true);
  const [waiterName, setWaiterName] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["SectionWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (Object.keys(translations.sectionEditFormSectionName.hasOwnProperty('value') ? translations.sectionEditFormSectionName.value : []).length === 0) {
      errors["SectionNameTranslationsInput"] = {
        "requiredTranslation": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const sectionNameTranslations = translations.sectionEditFormSectionName.hasOwnProperty('value') ? translations.sectionEditFormSectionName.value : [];
    const sectionDescriptionTranslations = translations.sectionEditFormSectionDescription.hasOwnProperty('value') ? translations.sectionEditFormSectionDescription.value : [];
    Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('menu')
      .doc(menuId)
      .collection('section')
      .doc(sectionId)
      .update({
        nameTranslations: sectionNameTranslations,
        descriptionTranslations: sectionDescriptionTranslations,
      })
      .catch((error) => {
        alert(error.message);
      });
    Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('menu')
      .doc(menuId)
      .collection('section')
      .doc(sectionId)
      .set({
        waiterName,
        nameTranslations: sectionNameTranslations,
        descriptionTranslations: sectionDescriptionTranslations,
      }, {
        merge: true,
      })
      .then(() => {
        alert(Translator.t('components.organisms.forms.SectionEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('menu')
      .doc(menuId)
      .collection('section')
      .doc(sectionId)
      .onSnapshot((sectionDoc) => {
        if (sectionDoc.exists) {
          const section = sectionDoc.data();
          if (section.rights.hasOwnProperty(user) && section.rights[user] >= 4) {
            setWaiterName(section.waiterName);
            dispatch(createTranslations({
              id: 'sectionEditFormSectionName',
              translations: {
                input: SectionNameInput,
                value: section.hasOwnProperty('nameTranslations') && typeof section.nameTranslations === 'object' && Object.keys(section.nameTranslations).length > 0 ? section.nameTranslations : {}
              },
            }));
            dispatch(createTranslations({
              id: 'sectionEditFormSectionDescription',
              translations: {
                input: SectionDescriptionInput,
                value: section.hasOwnProperty('descriptionTranslations') && typeof section.descriptionTranslations === 'object' && Object.keys(section.descriptionTranslations).length > 0 ? section.descriptionTranslations : {}
              },
            }));
            setDisabledForm(false);
          } else {
            setWaiterName('');
            dispatch(createTranslations({
              id: 'sectionEditFormSectionName',
              translations: {
                input: SectionNameInput,
                value: null
              },
            }));
            dispatch(createTranslations({
              id: 'sectionEditFormSectionDescription',
              translations: {
                input: SectionDescriptionInput,
                value: null
              },
            }));
            setDisabledForm(true);
            alert(Translator.t('components.organisms.forms.SectionEditForm.error.noPermissions'));
          }
        }
      });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <SectionWaiterNameInput errors={errors.hasOwnProperty('SectionWaiterNameInput') ? errors.SectionWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <SectionNameTranslationsInput errors={errors.hasOwnProperty('SectionNameTranslationsInput') ? errors.SectionNameTranslationsInput : {}} value={translations.hasOwnProperty('sectionEditFormSectionName') && translations.sectionEditFormSectionName.hasOwnProperty('value') ? translations.sectionEditFormSectionName.value : null} id="sectionEditFormSectionName" disabled={disabledForm} />
      <SectionDescriptionTranslationsInput value={translations.hasOwnProperty('sectionEditFormSectionDescription') && translations.sectionEditFormSectionDescription.hasOwnProperty('value') ? translations.sectionEditFormSectionDescription.value : null} id="sectionEditFormSectionDescription" disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.SectionEditForm.submit.title')}</Button>
    </View>
  );
}

export default SectionEditForm;

import React from 'react';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';
import { useSelector } from 'react-redux';
import CheckboxInput from '../../atoms/inputs/CheckboxInput';

function StoreIsCardAppPaymentSupportedInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <CheckboxInput
      label={Translator.t('components.molecules.inputs.StoreIsCardAppPaymentSupportedInput.label')}
      {...props}
    />
  );
}

export default StoreIsCardAppPaymentSupportedInput;

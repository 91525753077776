import React from 'react';
import { View, Dimensions, StyleSheet } from 'react-native';
import { Drawer, useTheme } from 'react-native-paper';
import { useRoute, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Row from '../layouts/Row';
import Col from '../layouts/Col';
import Config from '../../../Config';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function DashboardLayout(props) {
  const theme = useTheme();
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const activeRoute = useRoute();
  const navigation = useNavigation();
  const sections = {
    [Translator.t('components.atoms.layouts.DashboardLayout.sections.basic')]: {
      "Dashboard": {
        "label": Translator.t('pages.Dashboard.title'),
        "icon": "apps"
      },
      "MyOrders": {
        "label": Translator.t('pages.MyOrders.title'),
        "icon": "food"
      },
      "MyAccount": {
        "label": Translator.t('pages.MyAccount.title'),
        "icon": "account-circle"
      }
    }
  };
  const styles = StyleSheet.create({
    drawerCol: {
      borderRightWidth: 1,
      borderRightColor: theme.colors.border
    }
  });

  if (Dimensions.get('window').width >= Config.dimensions.xl) {
    return (
      <View>
        <Row>
          <Col xs={12} sm={12} md={12} lg={2} style={styles.drawerCol}>
            {
              Object.keys(sections).map(function (sectionName, sectionKey) {
                return (
                  <Drawer.Section key={sectionKey} title={sectionName}>
                    {
                      Object.keys(sections[sectionName]).map(function (routeName, routeKey) {
                        return (
                          <Drawer.Item
                            label={sections[sectionName][routeName]["label"]}
                            icon={sections[sectionName][routeName].hasOwnProperty('icon') ? sections[sectionName][routeName]["icon"] : null}
                            active={routeName === activeRoute.name}
                            onPress={() => navigation.navigate(routeName)}
                            key={routeKey}
                          />
                        );
                      })
                    }
                  </Drawer.Section>
                );
              })
            }
          </Col>
          <Col xs={12} sm={12} md={12} lg={10}>
            {props.children}
          </Col>
        </Row>
      </View>
    );
  }

  return (
    <View>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {props.children}
        </Col>
      </Row>
    </View>
  );
}

export default DashboardLayout;

import React from 'react';
import { Button } from 'react-native-paper';
import { useSelector } from 'react-redux';

import View from '../atoms/layouts/View';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Section from '../atoms/layouts/Section';
import MyCompanyList from '../organisms/lists/MyCompanyList';
import MyBrandList from '../organisms/lists/MyBrandList';
import MyStoreList from '../organisms/lists/MyStoreList';
import MyTableList from '../organisms/lists/MyTableList';
import MyMenuList from '../organisms/lists/MyMenuList';
import MySectionList from '../organisms/lists/MySectionList';
import MyProductList from '../organisms/lists/MyProductList';
import Translator from '../../modules/Translator';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import { prepareLocale } from '../../modules/Helper';

function Dashboard(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <DashboardLayout>
      <View>
        <Row>
          <MyCompanyList />
        </Row>
        <Row>
          <MyBrandList />
        </Row>
        <Row>
          <MyStoreList />
        </Row>
        <Row>
          <MyTableList />
        </Row>
        <Row>
          <MyMenuList />
        </Row>
        <Row>
          <MySectionList />
        </Row>
        <Row>
          <MyProductList />
        </Row>
        <Section title={Translator.t('pages.Dashboard.content.myAccount.title')} description={Translator.t('pages.Dashboard.content.myAccount.description')} hideBottomDivider={true}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Button mode='contained' onPress={() => props.navigation.push('MyAccount')}>{Translator.t('words.view')}</Button>
          </Col>
        </Section>
      </View>
    </DashboardLayout>
  );
}

export default Dashboard;

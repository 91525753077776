import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Config from '../../../Config';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import DropdownInput from '../../atoms/inputs/DropdownInput';
import { prepareLocale } from '../../../modules/Helper';

function LocaleSelector(props) {
  const mode = props.hasOwnProperty('mode') ? props.mode : 'contained';
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [locale, setLocale] = useState(Config.defaultLocale);
  const currentLocale = Translator.locale;
  const options = {};
  const onChange = function (locale) {
    Firebase.firestore().collection('user').doc(user).set({
      locale: locale
    }, {
      merge: true
    });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('user').doc(user).onSnapshot((userDoc) => {
      const user = userDoc.data();
      setLocale(user.hasOwnProperty('locale') ? user.locale : Config.defaultLocale);
    });
    return () => unsubscribe();
  }, []);

  Config.supportedLocales.forEach(function (supportedLocale, supportedLocaleKey) {
    if (supportedLocale !== 'auto') {
      Translator.locale = supportedLocale;
    }
    options[supportedLocale] = Translator.t('locales.' + supportedLocale);
    Translator.locale = currentLocale;
  });

  return (
    <DropdownInput mode={mode} value={locale} options={options} onChange={onChange}/>
  );
}

export default LocaleSelector;

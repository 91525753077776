import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TextInput as DefaultTextInput, HelperText } from 'react-native-paper';
import { MaskedTextInput } from 'react-native-mask-text';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function TextInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const icon = props.hasOwnProperty('icon') ? props.icon : null;
  const mask = props.hasOwnProperty('mask') ? props.mask : null;
  const infos = props.hasOwnProperty('infos') && typeof props.infos === 'object' ? props.infos : [];
  const errors = props.hasOwnProperty('errors') && typeof props.errors === 'object' ? props.errors : {};
  const infosNodes = [];
  const errorMessages = [];

  infos.forEach((info, infoNodeKey) => infosNodes.push(<HelperText key={infoNodeKey} type="success">{info}</HelperText>));

  for (const [errorKey, error] of Object.entries(errors)) {
    errorMessages.push(Translator.t('errors.' + errorKey));
  }

  return (
    <View style={styles.container}>
      { mask !== null ? (
        <DefaultTextInput
          right={icon ? <DefaultTextInput.Icon name={icon} /> : null}
          error={errorMessages.length > 0}
          autoCapitalize="none"
          returnKeyType="done"
          onChangeText={props.onChange}
          render={function (props) {
            return <MaskedTextInput {...props} mask={mask} />;
          }}
          {...props}
        />
      ) : (
        <DefaultTextInput
          right={icon ? <DefaultTextInput.Icon name={icon} /> : null}
          error={errorMessages.length > 0}
          autoCapitalize="none"
          returnKeyType="done"
          onChangeText={props.onChange}
          {...props}
        />
      ) }
      {infosNodes}
      {
        errorMessages.length > 0 ? (
          errorMessages.map(function (errorMessage, errorMessageIndex) {
            return (
              <HelperText key={errorMessageIndex} type="error">{errorMessage}</HelperText>
            );
          })
        ) : null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
});

export default TextInput;

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import ProductWaiterNameInput from '../../molecules/inputs/ProductWaiterNameInput';
import ProductIsAvailableInput from '../../molecules/inputs/ProductIsAvailableInput';
import ProductPriceInput from '../../molecules/inputs/ProductPriceInput';
import ProductNameInput from '../../molecules/inputs/ProductNameInput';
import ProductImageInput from '../../molecules/inputs/ProductImageInput';
import ProductNameTranslationsInput from '../../molecules/inputs/ProductNameTranslationsInput';
import SectionsInput from '../../molecules/inputs/SectionsInput';
import {
  changeIsLoading, changeSelect,
  changeTranslations, createSelect,
  createTranslations,
} from '../../../redux/reducers/PageReducer';
import { parsePriceToFloat, canPriceBeParseToFloat, prepareLocale } from '../../../modules/Helper';
import ProductDescriptionInput from '../../molecules/inputs/ProductDescriptionInput';
import ProductDescriptionTranslationsInput
  from '../../molecules/inputs/ProductDescriptionTranslationsInput';
import ProductTagsInput from '../../molecules/inputs/ProductTagsInput';
import Currencies from '../../../modules/Currencies';
import ProductCurrencyInput from '../../molecules/inputs/ProductCurrencyInput';

function ProductCreateForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const translations = useSelector((state) => state.page.translations);
  const selects = useSelector((state) => state.page.selects);
  const parentPath = props.hasOwnProperty('path') && props.path !== null ? [props.path] : null;
  const [disabledForm, setDisabledForm] = useState(true);
  const [image, setImage] = useState(null);
  const [waiterName, setWaiterName] = useState('');
  const [price, setPrice] = useState('');
  const [tags, setTags] = useState('');
  const [isAvailable, setIsAvailable] = useState(true);
  const [errors, setErrors] = useState({});
  const currencies = Currencies();
  const uploadImage = async function (productId) {
    const response = await fetch(image.uri);
    const blob = await response.blob();

    const ref = Firebase.storage().ref().child(`images/product/${productId}`);
    return ref.put(blob);
  };
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["ProductWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (typeof price !== 'string' || price.length === 0) {
      errors["ProductPriceInput"] = {
        "requiredField": {}
      };
    }
    else if (!canPriceBeParseToFloat(price)) {
      errors["ProductPriceInput"] = {
        "notValidValue": {}
      };
    }
    if (!selects.hasOwnProperty('productCreateFormCurrency') || !selects.productCreateFormCurrency.hasOwnProperty('value') || selects.productCreateFormCurrency.value === null) {
      errors["ProductCurrencyInput"] = {
        "requiredField": {}
      };
    }
    if (Object.keys(translations.productCreateFormProductName.hasOwnProperty('value') ? translations.productCreateFormProductName.value : []).length === 0) {
      errors["ProductNameTranslationsInput"] = {
        "requiredTranslation": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const productNameTranslations = translations.productCreateFormProductName.hasOwnProperty('value') ? translations.productCreateFormProductName.value : [];
    const productDescriptionTranslations = translations.productCreateFormProductDescription.hasOwnProperty('value') ? translations.productCreateFormProductDescription.value : [];
    const productSections = selects.hasOwnProperty('productCreateFormSections') && selects.productCreateFormSections.hasOwnProperty('value') ? selects.productCreateFormSections.value : [];
    const newDoc = Firebase.firestore().collection('product').doc();
    newDoc.set({
      image: null,
      waiterName,
      isAvailable,
      price: parsePriceToFloat(price),
      tags: tags.trim().split(',').filter((tag) => tag.length > 0),
      nameTranslations: productNameTranslations,
      descriptionTranslations: productDescriptionTranslations,
      sections: productSections,
      currency: selects.productCreateFormCurrency.value,
      rights: {
        [user]: 7,
      },
    }).then(() => {
      if (image !== null) {
        uploadImage(newDoc.id).then((task) => {
          task.ref.getDownloadURL().then((url) => {
            newDoc.set({
              image: url,
            }, {
              merge: true,
            }).then(() => {
              setImage(null);
              setWaiterName('');
              setIsAvailable(true);
              setPrice('');
              setTags('');
              dispatch(changeTranslations({
                id: 'productCreateFormProductName',
                translations: {
                  value: null,
                },
              }));
              dispatch(changeTranslations({
                id: 'productCreateFormProductDescription',
                translations: {
                  value: null,
                },
              }));
              dispatch(changeSelect({
                id: 'productCreateFormSections',
                select: {
                  value: parentPath,
                },
              }));
              dispatch(changeSelect({
                id: 'productCreateFormCurrency',
                select: {
                  value: null,
                },
              }));
              alert(Translator.t('components.organisms.forms.ProductCreateForm.success.title'));
              setDisabledForm(false);
              dispatch(changeIsLoading(false));
            }).catch((error) => {
              alert(error.message);
            });
          }).catch((error) => {
            alert(error.message);
          });
        }).catch((error) => {
          alert(error.message);
        });
      } else {
        setWaiterName('');
        setIsAvailable(true);
        setPrice('');
        setTags('');
        dispatch(changeTranslations({
          id: 'productCreateFormProductName',
          translations: {
            value: null,
          },
        }));
        dispatch(changeTranslations({
          id: 'productCreateFormProductDescription',
          translations: {
            value: null,
          },
        }));
        dispatch(changeSelect({
          id: 'productCreateFormSections',
          select: {
            value: parentPath,
          },
        }));
        dispatch(changeSelect({
          id: 'productCreateFormCurrency',
          select: {
            value: null,
          },
        }));
        alert(Translator.t('components.organisms.forms.ProductCreateForm.success.title'));
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      }
    }).catch((error) => {
      alert(error.message);
    });
  };

  useEffect(() => {
    dispatch(createTranslations({
      id: 'productCreateFormProductName',
      translations: {
        input: ProductNameInput,
      },
    }));
    dispatch(createTranslations({
      id: 'productCreateFormProductDescription',
      translations: {
        input: ProductDescriptionInput,
      },
    }));
    const currencySelectOptions = [];
    for (const [index, currency] of Object.entries(currencies.getAll(Translator.locale))) {
      currencySelectOptions.push({
        label: currency.name + ' (' + currency.symbol + ')',
        value: currency.code,
      });
    }
    dispatch(createSelect({
      id: 'productCreateFormCurrency',
      select: {
        options: currencySelectOptions,
        enableSearch: true
      },
    }));
    const unsubscribe = Firebase.firestore().collectionGroup('section').where(`rights.${user}`, '>=', 7).onSnapshot((sectionsRef) => {
      const sectionsDocs = sectionsRef.docs;
      const sectionSelectOptions = [];
      sectionsDocs.forEach((sectionsDoc) => {
        const sectionDocPath = sectionsDoc.ref.path;
        const section = sectionsDoc.data();
        sectionSelectOptions.push({
          icon: 'file-document',
          label: section.waiterName,
          description: `ID: #${sectionsDoc.id}`,
          value: sectionDocPath,
        });
      });
      dispatch(createSelect({
        id: 'productCreateFormSections',
        select: {
          options: sectionSelectOptions,
          enableMultiple: true,
          value: parentPath
        },
      }));
      setDisabledForm(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <ProductImageInput value={image} setValue={setImage} disabled={disabledForm} />
      <ProductWaiterNameInput errors={errors.hasOwnProperty('ProductWaiterNameInput') ? errors.ProductWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <ProductPriceInput errors={errors.hasOwnProperty('ProductPriceInput') ? errors.ProductPriceInput : {}} value={price} onChange={(price) => setPrice(price)} disabled={disabledForm} />
      <ProductCurrencyInput errors={errors.hasOwnProperty('ProductCurrencyInput') ? errors.ProductCurrencyInput : {}} value={selects.hasOwnProperty('productCreateFormCurrency') && selects.productCreateFormCurrency.hasOwnProperty('value') ? selects.productCreateFormCurrency.value : null} id="productCreateFormCurrency" options={selects.hasOwnProperty('productCreateFormCurrency') && selects.productCreateFormCurrency.hasOwnProperty('options') ? selects.productCreateFormCurrency.options : []} disabled={disabledForm}/>
      <ProductIsAvailableInput value={isAvailable} onChange={() => setIsAvailable(!isAvailable)} disabled={disabledForm} />
      <ProductTagsInput value={tags} onChange={(tags) => setTags(tags)} disabled={disabledForm} />
      <ProductNameTranslationsInput errors={errors.hasOwnProperty('ProductNameTranslationsInput') ? errors.ProductNameTranslationsInput : {}} value={translations.hasOwnProperty('productCreateFormProductName') && translations.productCreateFormProductName.hasOwnProperty('value') ? translations.productCreateFormProductName.value : null} id="productCreateFormProductName" disabled={disabledForm} />
      <ProductDescriptionTranslationsInput value={translations.hasOwnProperty('productCreateFormProductDescription') && translations.productCreateFormProductDescription.hasOwnProperty('value') ? translations.productCreateFormProductDescription.value : null} id="productCreateFormProductDescription" disabled={disabledForm} />
      <SectionsInput value={selects.hasOwnProperty('productCreateFormSections') && selects.productCreateFormSections.hasOwnProperty('value') ? selects.productCreateFormSections.value : null} id="productCreateFormSections" options={selects.hasOwnProperty('productCreateFormSections') && selects.productCreateFormSections.hasOwnProperty('options') ? selects.productCreateFormSections.options : []} disabled={disabledForm} enableMultiple={selects.hasOwnProperty('productCreateFormSections') && selects.productCreateFormSections.hasOwnProperty('enableMultiple') ? selects.productCreateFormSections.enableMultiple : false} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.ProductCreateForm.submit.title')}</Button>
    </View>
  );
}

export default ProductCreateForm;

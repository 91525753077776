import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Card from '../../atoms/elements/Card';
import Translator from '../../../modules/Translator';
import {
  buildDocPath, changeTimeZoneOfDate, convertTimezone,
  getPropertyNameOfStatusChange, outputTime,
  prepareLocale,
  translateOrderProductStatus
} from '../../../modules/Helper';
import Config from '../../../Config';
import Firebase from '../../../modules/Firebase';
import { Text, Button, Divider } from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import Row from '../../atoms/layouts/Row';
import Col from '../../atoms/layouts/Col';

function OrderProductCard({
                     items, name, orderId, productId, enableView, enableEdit, customActions,
                   }) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const timezone = useSelector((state) => state.intl.timezone);
  const navigation = useNavigation();
  enableView = typeof enableView !== 'undefined' && enableView;
  enableEdit = typeof enableEdit !== 'undefined' && enableEdit;
  const styles = StyleSheet.create({
    mainContainer: {
      paddingTop: 25,
      paddingBottom: 0,
      paddingLeft: '3%',
      paddingRight: '3%',
    },
    divider: {
      marginBottom: 10
    },
    cardActionsRow: {
      justifyContent: "flex-end",
      marginTop: 10
    }
  });

  const actions = [];

  if (enableView) {
    actions.push({
      title: Translator.t('words.view'),
      mode: 'contained',
      onPress() {
        navigation.push('OrderGetItem', {
          path: 'order/' + orderId
        });
      },
    });
  }

  if (enableEdit) {
    actions.push({
      title: Translator.t('words.edit'),
      mode: 'outlined',
      onPress() {
        navigation.push('OrderUpdateItem', {
          path: 'order/' + orderId
        });
      },
    });
  }

  return (
    <Card title={Object.keys(items).length + " x " + name} contentLeftPaddingDisabled={true} actions={actions.concat(typeof customActions === 'undefined' ? [] : customActions)}>
      <View style={styles.mainContainer}>
        <View>
          <Row>
            <Col xs={1} sm={1} md={1} lg={1}><Text>-</Text></Col>
            <Col xs={3} sm={3} md={3} lg={3}><Text>When</Text></Col>
            <Col xs={3} sm={3} md={3} lg={3}><Text>Time</Text></Col>
            <Col xs={5} sm={5} md={5} lg={5}><Text>Status</Text></Col>
          </Row>
          <Divider style={styles.divider}/>
        </View>
        {
          Object.keys(items).map((itemIndex, itemCount) => {
            const item = items[itemIndex];
            const itemKey = buildDocPath({
              order: orderId
            }) + itemIndex;
            const actions = [];
            const stepOfCurrentStatus = Config.orderProductStatuses.indexOf(item.status);
            if (stepOfCurrentStatus !== Config.orderProductStatuses.length - 1) {
              const nextStatus = Config.orderProductStatuses[stepOfCurrentStatus + 1];
              const nextStatusChangePropertyName = getPropertyNameOfStatusChange(nextStatus);
              actions.push({
                title: Translator.t('components.organisms.items.OrderProductCard.statusButtons.' + nextStatus),
                mode: 'contained',
                onPress() {
                  Firebase.firestore().collection('order').doc(orderId).get().then((orderRef) => {
                    if (orderRef.exists) {
                      const order = orderRef.data();
                      const indexOfProduct = order['products'].findIndex((product) => product.path === buildDocPath({
                        product: productId
                      }));
                      const newOrder = Object.assign({}, order);
                      const newItem = Object.assign({}, order['products'][indexOfProduct]['items'][itemIndex]);
                      newItem['status'] = nextStatus;
                      newItem[nextStatusChangePropertyName] = changeTimeZoneOfDate(new Date(), Config.systemTimezone);
                      newOrder['products'][indexOfProduct]['items'][itemIndex] = newItem;

                      let countOfProducts = 0;
                      newOrder.products.forEach((product) => {
                        countOfProducts += Object.keys(product.items).length;
                      });
                      let countOfAcceptedProducts = 0;
                      newOrder.products.forEach((product, productKey) => {
                        Object.keys(product.items).forEach((productItemKey) => {
                          const productItem = newOrder['products'][productKey]['items'][productItemKey];
                          if (productItem.status === Config.orderProductStatuses[1]) {
                            countOfAcceptedProducts++;
                          }
                        });
                      });
                      if (countOfAcceptedProducts === countOfProducts) {
                        newOrder.status = Config.orderStatuses[1];
                        newOrder.acceptedAt = changeTimeZoneOfDate(new Date(), Config.systemTimezone);
                      }

                      Firebase.firestore().collection('order').doc(orderId).set(newOrder).catch((error) => {
                        alert(error.message);
                      });
                    }
                  }).catch((error) => {
                    alert(error.message);
                  });
                }
              });
            }
            return (
              <View key={itemKey}>
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1}><Text>{ parseInt(itemIndex) + 1 }</Text></Col>
                  <Col xs={3} sm={3} md={3} lg={3}><Text>{ outputTime(convertTimezone(item.waitingAcceptanceAt.toDate(), timezone), Translator.locale) }</Text></Col>
                  <Col xs={3} sm={3} md={3} lg={3}><Text>{ item.timeToDeliver } min</Text></Col>
                  <Col xs={5} sm={5} md={5} lg={5}><Text>{ translateOrderProductStatus(item.status, Translator) }</Text></Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Row style={styles.cardActionsRow}>
                      {
                        actions.map((action, actionIndex) => {
                          return (
                            <Col xs={6} sm={6} md={6} lg={6} key={itemKey + actionIndex}>
                              <Button icon={action.icon} mode={action.mode} onPress={action.onPress}>{action.title}</Button>
                            </Col>
                          );
                        })
                      }
                    </Row>
                  </Col>
                </Row>
                {
                  Object.keys(items).length === itemCount + 1 ? null : (
                    <Divider style={styles.divider}/>
                  )
                }
              </View>
            );
          })
        }
      </View>
    </Card>
  );
}

export default OrderProductCard;

import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';

import Translator from '../modules/Translator';
import Header from './atoms/elements/Header';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Select from './pages/Select';
import Location from './pages/Location';
import Translations from './pages/Translations';
import BrandCreateItem from './pages/BrandCreateItem';
import BrandUpdateItem from './pages/BrandUpdateItem';
import CompanyCreateItem from './pages/CompanyCreateItem';
import CompanyUpdateItem from './pages/CompanyUpdateItem';
import MenuCreateItem from './pages/MenuCreateItem';
import MenuUpdateItem from './pages/MenuUpdateItem';
import TableCreateItem from './pages/TableCreateItem';
import TableUpdateItem from './pages/TableUpdateItem';
import ProductCreateItem from './pages/ProductCreateItem';
import ProductUpdateItem from './pages/ProductUpdateItem';
import SectionCreateItem from './pages/SectionCreateItem';
import SectionUpdateItem from './pages/SectionUpdateItem';
import StoreCreateItem from './pages/StoreCreateItem';
import StoreUpdateItem from './pages/StoreUpdateItem';
import CompanyGetItem from './pages/CompanyGetItem';
import BrandGetItem from './pages/BrandGetItem';
import StoreGetItem from './pages/StoreGetItem';
import MenuGetItem from './pages/MenuGetItem';
import TableGetItem from './pages/TableGetItem';
import SectionGetItem from './pages/SectionGetItem';
import ProductGetItem from './pages/ProductGetItem';
import MyAccount from './pages/MyAccount';
import MyOrders from './pages/MyOrders';
import MyAccountUpdate from './pages/MyAccountUpdate';
import { prepareLocale } from '../modules/Helper';

const StackNavigator = createStackNavigator();

function MainStackNavigation() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const isLoggedIn = useSelector((state) => state.auth.user) !== null;

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: true,
        header: (props) => <Header {...props} />,
        headerMode: Platform.OS === 'web' ? 'screen' : 'float'
      }}
    >
      { isLoggedIn ? (
        <>
          <StackNavigator.Screen
            name="Dashboard"
            component={Dashboard}
            options={() => ({
              title: Translator.t('pages.Dashboard.title'),
            })}
          />
          <StackNavigator.Screen
            name="MyAccount"
            component={MyAccount}
            options={() => ({
              title: Translator.t('pages.MyAccount.title'),
            })}
          />
          <StackNavigator.Screen
            name="MyAccountUpdate"
            component={MyAccountUpdate}
            options={() => ({
              title: Translator.t('pages.MyAccountUpdate.title'),
            })}
          />
          <StackNavigator.Screen
            name="MyOrders"
            component={MyOrders}
            options={() => ({
              title: Translator.t('pages.MyOrders.title'),
            })}
          />
          <StackNavigator.Screen
            name="BrandCreateItem"
            component={BrandCreateItem}
            options={() => ({
              title: Translator.t('pages.BrandCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="BrandGetItem"
            component={BrandGetItem}
            options={() => ({
              title: Translator.t('pages.BrandGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="BrandUpdateItem"
            component={BrandUpdateItem}
            options={() => ({
              title: Translator.t('pages.BrandUpdateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="CompanyCreateItem"
            component={CompanyCreateItem}
            options={() => ({
              title: Translator.t('pages.CompanyCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="CompanyGetItem"
            component={CompanyGetItem}
            options={() => ({
              title: Translator.t('pages.CompanyGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="CompanyUpdateItem"
            component={CompanyUpdateItem}
            options={() => ({
              title: Translator.t('pages.CompanyUpdateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="MenuCreateItem"
            component={MenuCreateItem}
            options={() => ({
              title: Translator.t('pages.MenuCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="MenuGetItem"
            component={MenuGetItem}
            options={() => ({
              title: Translator.t('pages.MenuGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="MenuUpdateItem"
            component={MenuUpdateItem}
            options={() => ({
              title: Translator.t('pages.MenuUpdateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="ProductCreateItem"
            component={ProductCreateItem}
            options={() => ({
              title: Translator.t('pages.ProductCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="ProductGetItem"
            component={ProductGetItem}
            options={() => ({
              title: Translator.t('pages.ProductGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="ProductUpdateItem"
            component={ProductUpdateItem}
            options={() => ({
              title: Translator.t('pages.ProductUpdateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="SectionCreateItem"
            component={SectionCreateItem}
            options={() => ({
              title: Translator.t('pages.SectionCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="SectionGetItem"
            component={SectionGetItem}
            options={() => ({
              title: Translator.t('pages.SectionGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="SectionUpdateItem"
            component={SectionUpdateItem}
            options={() => ({
              title: Translator.t('pages.SectionUpdateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="StoreCreateItem"
            component={StoreCreateItem}
            options={() => ({
              title: Translator.t('pages.StoreCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="StoreGetItem"
            component={StoreGetItem}
            options={() => ({
              title: Translator.t('pages.StoreGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="StoreUpdateItem"
            component={StoreUpdateItem}
            options={() => ({
              title: Translator.t('pages.StoreUpdateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="TableCreateItem"
            component={TableCreateItem}
            options={() => ({
              title: Translator.t('pages.TableCreateItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="TableGetItem"
            component={TableGetItem}
            options={() => ({
              title: Translator.t('pages.TableGetItem.title'),
            })}
          />
          <StackNavigator.Screen
            name="TableUpdateItem"
            component={TableUpdateItem}
            options={() => ({
              title: Translator.t('pages.TableUpdateItem.title'),
            })}
          />
        </>
      ) : (
        <>
          <StackNavigator.Screen
            name="Login"
            component={Login}
            options={() => ({
              title: Translator.t('pages.Login.title'),
            })}
          />
          <StackNavigator.Screen
            name="Register"
            component={Register}
            options={() => ({
              title: Translator.t('pages.Register.title'),
            })}
          />
          <StackNavigator.Screen
            name="Reset"
            component={Reset}
            options={() => ({
              title: Translator.t('pages.Reset.title'),
            })}
          />
        </>
      ) }
      <StackNavigator.Screen
        name="Select"
        component={Select}
        options={() => ({
          title: Translator.t('pages.Select.title')
        })}
      />
      <StackNavigator.Screen
        name="Translations"
        component={Translations}
        options={() => ({
          title: Translator.t('pages.Translations.title')
        })}
      />
      <StackNavigator.Screen
        name="Location"
        component={Location}
        options={() => ({
          title: Translator.t('pages.Location.title')
        })}
      />
    </StackNavigator.Navigator>
  );
}

export default MainStackNavigation;

import React from 'react';

import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import View from '../atoms/layouts/View';
import ProductEditForm from '../organisms/forms/ProductEditForm';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import { buildDocPath } from '../../modules/Helper';

function ProductUpdateItem(props) {
  const productId = props.route.params.productId;
  const path = buildDocPath({
    product: productId,
  });

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <ProductEditForm path={path} />
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default ProductUpdateItem;

import React, { useState, useEffect } from 'react';
import { Button, Title } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import View from '../atoms/layouts/View';
import Section from '../atoms/layouts/Section';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Firebase from '../../modules/Firebase';
import { buildDocPath, parseDocPath, prepareLocale } from '../../modules/Helper';
import Translator from '../../modules/Translator';
import { useSelector } from 'react-redux';
import { capitalize } from 'stringulation';
import ImageView from '../atoms/elements/ImageView';
import Card from '../atoms/elements/Card';
import Currencies from '../../modules/Currencies';
import TagsView from '../atoms/elements/TagsView';
import TranslationsView from '../atoms/elements/TranslationsView';
import RightsManager from '../atoms/buttons/RightsManager';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import SectionList from '../organisms/lists/SectionList';

function ProductGetItem(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const productId = props.route.params.productId;
  const path = buildDocPath({
    product: productId
  });
  const currencies = Currencies();
  const user = useSelector((state) => state.auth.user);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [sectionPaths, setSectionPaths] = useState([]);
  const [sections, setSections] = useState({});

  useEffect(() => {
    const unsubscribeProduct = Firebase.firestore().collection('product').doc(productId).onSnapshot((productDoc) => {
      if (productDoc.exists) {
        const product = productDoc.data();
        setProduct(product);
        setSectionPaths(product.hasOwnProperty('sections') ? product.sections : []);
        setIsFound(true);
        setIsAllowed(product.rights.hasOwnProperty(user) && product.rights[user] >= 1);
        setIsEditAllowed(product.rights.hasOwnProperty(user) && product.rights[user] >= 4);
        props.navigation.setOptions({
          title: product.waiterName
        });
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribeProduct();
    };
  }, []);

  useEffect(() => {
    let sections = {};
    const unsubscribeSections = [];
    setSections(sections);
    for (const sectionPathsIndex in sectionPaths) {
      const unsubscribe = Firebase.firestore().doc(sectionPaths[sectionPathsIndex]).onSnapshot((sectionDoc) => {
        if (sectionDoc.exists) {
          const sectionDocParsedPath = parseDocPath(sectionDoc.ref.path);
          const section = sectionDoc.data()
          sections = {
            ...sections,
            [sectionPaths[sectionPathsIndex]]: {
              name: section.waiterName,
              companyId: sectionDocParsedPath.company,
              brandId: sectionDocParsedPath.brand,
              menuId: sectionDocParsedPath.menu,
              sectionId: sectionDoc.id,
              enableView: section.rights[user] >= 1,
              enableEdit: section.rights[user] >= 4,
              enableDelete: section.rights[user] >= 7,
            }
          };
          setSections(sections);
        }
      });
      unsubscribeSections.push(unsubscribe);
    }
    return () => {
      if (unsubscribeSections.length !== 0) {
        unsubscribeSections.forEach((unsubscribeSection, unsubscribeSectionsKey) => {
          unsubscribeSection();
        });
      }
    };
  }, [sectionPaths]);

  if (isLoading) {
    return null;
  }

  if (!isFound) {
    return (
      <DashboardLayout>
        <View>
          <Title>{capitalize(Translator.t('words.notFound'), true)}</Title>
        </View>
      </DashboardLayout>
    );
  }

  if (!isAllowed) {
    return (
      <DashboardLayout>
        <View>
          <Title>{Translator.t('messages.notAllowed')}</Title>
        </View>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Section title={Translator.t('pages.ProductGetItem.content.generalInformation.title')} description={Translator.t('pages.ProductGetItem.content.generalInformation.description')}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <ImageView path={path} property='image' avatar={true} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.ProductGetItem.content.generalInformation.price.title')} subtitle={Translator.t('pages.ProductGetItem.content.generalInformation.price.description')} icon="cash">
                  <Title>{product.price} {currencies.get(product.currency, Translator.locale).symbol_native} ({currencies.get(product.currency, Translator.locale).name})</Title>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.ProductGetItem.content.generalInformation.tags.title')} subtitle={Translator.t('pages.ProductGetItem.content.generalInformation.tags.description')} icon="tag">
                  <TagsView tags={product.tags}/>
                </Card>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.ProductGetItem.content.translations.title')} description={Translator.t('pages.ProductGetItem.content.translations.description')}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <TranslationsView title={Translator.t('pages.ProductGetItem.content.translations.nameTranslations.title')} description={Translator.t('pages.ProductGetItem.content.translations.nameTranslations.description')} translations={product.nameTranslations}/>
              </Col>
              <Col xs={12} sm={12} md={6} lg={8}>
                <TranslationsView title={Translator.t('pages.ProductGetItem.content.translations.descriptionTranslations.title')} description={Translator.t('pages.ProductGetItem.content.translations.descriptionTranslations.description')} translations={product.descriptionTranslations}/>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.ProductGetItem.content.management.title')} description={Translator.t('pages.ProductGetItem.content.management.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Button mode='contained' onPress={() => {
                  navigation.push('ProductUpdateItem', {
                    productId
                  });
                }}>{Translator.t('words.edit')}</Button>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.ProductGetItem.content.rights.title')} description={Translator.t('pages.ProductGetItem.content.rights.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <RightsManager path={path} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.ProductGetItem.content.sections.title')} description={Translator.t('pages.ProductGetItem.content.sections.description')} hideBottomDivider={true}>
            <SectionList sections={Object.values(sections)} />
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default ProductGetItem;

import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Caption } from 'react-native-paper';
import {useSelector} from "react-redux";

import Translator from "../../../modules/Translator";
import {prepareLocale} from "../../../modules/Helper";

function Footer() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <View style={styles.container}>
      <Caption>Smart Take. { new Date().getFullYear() }. {Translator.t('components.atoms.layouts.Footer.location')}.</Caption>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default Footer;

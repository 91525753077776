import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

function Loading(props) {
  const styles = StyleSheet.create({
    view: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: props.theme.colors.background,
    },
  });

  return (
    <View style={styles.view}>
      <ActivityIndicator size="large" color={props.theme.colors.primary} />
    </View>
  );
}

export default Loading;

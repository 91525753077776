import React, { useState, useEffect } from 'react';
import { Title, Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import View from '../atoms/layouts/View';
import Section from '../atoms/layouts/Section';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Firebase from '../../modules/Firebase';
import { buildDocPath, parseDocPath, prepareLocale } from '../../modules/Helper';
import Translator from '../../modules/Translator';
import { useSelector } from 'react-redux';
import { capitalize } from 'stringulation';
import SectionList from '../organisms/lists/SectionList';
import RightsManager from '../atoms/buttons/RightsManager';
import DashboardLayout from '../atoms/layouts/DashboardLayout';

function MenuGetItem(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const companyId = props.route.params.companyId;
  const brandId = props.route.params.brandId;
  const menuId = props.route.params.menuId;
  const path = buildDocPath({
    company: companyId,
    brand: brandId,
    menu: menuId,
  });
  const user = useSelector((state) => state.auth.user);
  const [menu, setMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [sections, setSections] = useState([]);
  const [sectionsCount, setSectionsCount] = useState(null);

  useEffect(() => {
    const unsubscribeMenu = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('menu').doc(menuId).onSnapshot((menuDoc) => {
      if (menuDoc.exists) {
        const menu = menuDoc.data();
        setMenu(menu);
        setIsFound(true);
        setIsAllowed(menu.rights.hasOwnProperty(user) && menu.rights[user] >= 1);
        setIsEditAllowed(menu.rights.hasOwnProperty(user) && menu.rights[user] >= 4);
        props.navigation.setOptions({
          title: menu.waiterName
        });
      }
      setIsLoading(false);
    });
    const unsubscribeSections = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('menu').doc(menuId).collection('section').where(`rights.${user}`, '>', 0).onSnapshot((sectionsRef) => {
      const sectionsDocs = sectionsRef.docs;
      setSectionsCount(sectionsRef.size);
      const sections = [];
      sectionsDocs.forEach((sectionsDoc) => {
        const sectionsDocParsedPath = parseDocPath(sectionsDoc.ref.path);
        const section = sectionsDoc.data();
        sections.push({
          name: section.waiterName,
          companyId: sectionsDocParsedPath.company,
          brandId: sectionsDocParsedPath.brand,
          menuId: sectionsDocParsedPath.menu,
          sectionId: sectionsDoc.id,
          enableView: section.rights[user] >= 1,
          enableEdit: section.rights[user] >= 4,
          enableDelete: section.rights[user] >= 7,
        });
      });
      setSections(sections);
    });
    return () => {
      unsubscribeMenu();
      unsubscribeSections();
    };
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isFound) {
    return (
      <DashboardLayout>
        <View>
          <Title>{capitalize(Translator.t('words.notFound'), true)}</Title>
        </View>
      </DashboardLayout>
    );
  }

  if (!isAllowed) {
    return (
      <DashboardLayout>
        <View>
          <Title>{Translator.t('messages.notAllowed')}</Title>
        </View>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Section title={Translator.t('pages.MenuGetItem.content.management.title')} description={Translator.t('pages.MenuGetItem.content.management.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Button mode='contained' onPress={() => {
                  navigation.push('MenuUpdateItem', {
                    companyId,
                    brandId,
                    menuId,
                  });
                }}>{Translator.t('words.edit')}</Button>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.MenuGetItem.content.rights.title')} description={Translator.t('pages.MenuGetItem.content.rights.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <RightsManager path={path} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.MenuGetItem.content.sections.title')} description={Translator.t('pages.MenuGetItem.content.sections.description')} hideBottomDivider={true}>
            <SectionList sections={sections} hasAddNewButton={sectionsCount !== null && typeof sectionsCount === 'number' && sectionsCount < 10} addNewButtonParams={{
              path: path
            }} {...props} />
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default MenuGetItem;

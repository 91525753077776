import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HelperText, useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function SelectInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const theme = useTheme();
  const selectId = props.id;
  const { value } = props;
  const { options } = props;
  let label = props.hasOwnProperty('label') ? props.label : Translator.t('components.atoms.inputs.SelectInput.label');
  let enableMultiple = props.hasOwnProperty('enableMultiple') ? props.enableMultiple : false;
  const errors = props.hasOwnProperty('errors') ? props.errors : {};
  const errorMessages = [];
  const styles = StyleSheet.create({
    container: {
      marginBottom: 10,
    },
    buttonError: {
      borderColor: theme.colors.error
    },
    buttonLabelError: {
      color: theme.colors.error
    }
  });

  for (const [errorKey, error] of Object.entries(errors)) {
    errorMessages.push(Translator.t('errors.' + errorKey));
  }

  if (enableMultiple) {
    if (Array.isArray(value) && value.length > 0) {
      const valuesLabels = [];
      value.forEach((item) => {
        options.forEach((option) => {
          if (item === option.value) {
            valuesLabels.push(option.label);
          }
        });
      });
      label = valuesLabels.join(", ");
    }
  }
  else {
    options.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
  }

  return (
    <View style={styles.container}>
      <Button
        mode="outlined"
        onPress={() => navigation.push('Select', {
          id: selectId,
        })}
        style={errorMessages.length > 0 ? [styles.buttonError] : []}
        labelStyle={errorMessages.length > 0 ? [styles.buttonLabelError] : []}
        {...props}
        disabled={options.length === 0 ? true : (props.hasOwnProperty('disabled') && props.disabled === true)}
      >
        { label }
      </Button>
      {
        errorMessages.length > 0 ? (
          errorMessages.map(function (errorMessage, errorMessageIndex) {
            return (
              <HelperText key={errorMessageIndex} type="error">{errorMessage}</HelperText>
            );
          })
        ) : null
      }
    </View>
  );
}

export default SelectInput;

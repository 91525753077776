import React from 'react';
import { useSelector } from 'react-redux';

import TextInput from '../../atoms/inputs/TextInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function EmailInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <TextInput
      label={Translator.t('components.molecules.inputs.EmailInput.label')}
      placeholder={Translator.t('components.molecules.inputs.EmailInput.placeholder')}
      icon="email"
      autoCompleteType="email"
      keyboardType="email-address"
      textContentType="emailAddress"
      {...props}
    />
  );
}

export default EmailInput;

import React from 'react';
import { Button } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Col from '../../atoms/layouts/Col';

function AddNewButton(props) {
  const navigation = useNavigation();
  const cols = !props.hasOwnProperty('cols') || typeof props.cols !== 'number' ? 2 : props.cols;
  const entity = props.entity;
  const parameters = !props.hasOwnProperty('parameters') || typeof props.parameters !== 'object' ? {} : props.parameters;
  const onPress = function () {
    navigation.push(entity + 'CreateItem', parameters);
  };

  return (
    <Col xs={12} sm={12} md={12 / cols} lg={12 / (cols + 1)}>
      <Button mode='outlined' onPress={onPress}>
        <MaterialCommunityIcons name="plus-circle" size={64}/>
      </Button>
    </Col>
  );
}

export default AddNewButton;

import React, { useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { useColorScheme } from 'react-native-appearance';

import Translator from '../../../modules/Translator';
import FirstNameInput from '../../molecules/inputs/FirstNameInput';
import LastNameInput from '../../molecules/inputs/LastNameInput';
import EmailInput from '../../molecules/inputs/EmailInput';
import PasswordInput from '../../molecules/inputs/PasswordInput';
import Firebase from '../../../modules/Firebase';
import { changeIsLoading } from '../../../redux/reducers/PageReducer';
import Config from '../../../Config';
import {
  isStringEmail,
  prepareCurrency,
  prepareLocale, prepareTheme,
  prepareTimezone
} from '../../../modules/Helper';
import { translateFirebaseError } from '../../../modules/FirebaseErrors';

function RegisterForm() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const colorScheme = useColorScheme();
  const [disabledForm, setDisabledForm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof firstName !== 'string' || firstName.length === 0) {
      errors["FirstNameInput"] = {
        "requiredField": {}
      };
    }
    if (typeof lastName !== 'string' || lastName.length === 0) {
      errors["LastNameInput"] = {
        "requiredField": {}
      };
    }
    if (typeof email !== 'string' || email.length === 0) {
      errors["EmailInput"] = {
        "requiredField": {}
      };
    }
    else if (!isStringEmail(email)) {
      errors["EmailInput"] = {
        "notValidValue": {}
      };
    }
    if (typeof password !== 'string' || password.length === 0) {
      errors["PasswordInput"] = {
        "requiredField": {}
      };
    }
    else if (password.length < 6 || password.length > 30) {
      errors["PasswordInput"] = {
        "notValidValue": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.auth().createUserWithEmailAndPassword(email, password).then((credentials) => {
      Firebase.firestore().collection('user').doc(credentials.user.uid).set({
        firstName,
        lastName,
        email,
        theme: prepareTheme(Config.defaultTheme, colorScheme),
        locale: prepareLocale(Config.defaultLocale),
        currency: prepareCurrency(Config.defaultCurrency),
        timezone: prepareTimezone(Config.defaultTimezone)
      })
        .then(() => {
          alert(Translator.t('components.organisms.forms.RegisterForm.success.title'));
        })
        .catch((error) => {
          alert(error.message);
        });
    }).catch((error) => {
      alert(translateFirebaseError(error.code, Translator.locale, error.message));
    })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  return (
    <View>
      <FirstNameInput errors={errors.hasOwnProperty('FirstNameInput') ? errors.FirstNameInput : {}} value={firstName} onChange={(firstName) => setFirstName(firstName)} disabled={disabledForm} />
      <LastNameInput errors={errors.hasOwnProperty('LastNameInput') ? errors.LastNameInput : {}} value={lastName} onChange={(lastName) => setLastName(lastName)} disabled={disabledForm} />
      <EmailInput errors={errors.hasOwnProperty('EmailInput') ? errors.EmailInput : {}} value={email} onChange={(email) => setEmail(email)} disabled={disabledForm} />
      <PasswordInput errors={errors.hasOwnProperty('PasswordInput') ? errors.PasswordInput : {}} value={password} onChange={(password) => setPassword(password)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.RegisterForm.submit.title')}</Button>
    </View>
  );
}

export default RegisterForm;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import OrderProductList from './OrderProductList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import {
  getLastUpdateDateOfOrder,
  parseDocPath,
  prepareLocale, translateOrderStatus
} from '../../../modules/Helper';

function MyOrderProductList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [tables, setTables] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const unsubscriber = Firebase.firestore().collectionGroup('table').where('notificationList', 'array-contains', user).onSnapshot((tablesRef) => {
      setTables([]);
      const newTables = [];
      const tableDocs = tablesRef.docs;
      tableDocs.forEach((tableDoc) => {
        const tablePath = tableDoc.ref.path;
        const table = tableDoc.data();
        newTables.push({
          ...table,
          path: tablePath,
        });
      });
      setTables(newTables);
    });
    return () => {
      unsubscriber();
    };
  }, []);

  // WHEN ORDER IS DELETED THE LIST STILL CONTAINS THE DELETED ORDER UNTIL REFRESH OF PAGE
  // DELETE OPERATION OF ORDER IS NOT AVAILABLE FROM ANYWHERE !!!
  useEffect(() => {
    setOrders([]);
    const unsubscribers = [];
    tables.forEach((table) => {
      const tablePath = table.path;
      unsubscribers.push(Firebase.firestore().collection('order').where('table.path', '==', tablePath).where('status', '!=', 'CLOSED').onSnapshot((ordersRef) => {
        const ordersDocs = ordersRef.docs;
        ordersDocs.forEach((ordersDoc) => {
          const orderPath = ordersDoc.ref.path;
          const order = ordersDoc.data();
          setOrders((orders) => {
            const existingOrdersWithoutNewOrder = orders.filter((order) => {
              return order.path !== orderPath;
            });
            const newOrders = [
              {
                ...order,
                path: orderPath,
              },
              ...existingOrdersWithoutNewOrder,
            ];
            newOrders.sort((a, b) => {
              return getLastUpdateDateOfOrder(b) - getLastUpdateDateOfOrder(a);
            });
            return newOrders;
          });
        });
      }));
    });
    return () => {
      unsubscribers.forEach(unsubscriber => unsubscriber());
    };
  }, [tables]);

  return (
    <>
      {
        orders.map((order) => {
          const orderProducts = order.products.map((orderProduct) => {
            return {
              name: orderProduct.waiterName,
              orderId: parseDocPath(order.path).order,
              productId: parseDocPath(orderProduct.path).product,
              items: orderProduct.items,
            };
          });
          return (
            <Section key={order.path} title={order.table.waiterName} description={translateOrderStatus(order.status, Translator)}>
              <OrderProductList orderProducts={orderProducts} cols={1} {...props} />
            </Section>
          );
        })
      }
    </>
  );
}

export default MyOrderProductList;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import BrandList from './BrandList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function MyBrandList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collectionGroup('brand').where(`rights.${user}`, '>', 0).onSnapshot((brandsRef) => {
      const brandsDocs = brandsRef.docs;
      const brands = [];
      brandsDocs.forEach((brandsDoc) => {
        const brandsDocParsedPath = parseDocPath(brandsDoc.ref.path);
        const brand = brandsDoc.data();
        brands.push({
          cover: brand.logo,
          name: brand.name,
          companyId: brandsDocParsedPath.company,
          brandId: brandsDoc.id,
          enableView: brand.rights[user] >= 1,
          enableEdit: brand.rights[user] >= 4,
          enableDelete: brand.rights[user] >= 7,
        });
      });
      setBrands(brands);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MyBrandList.section.title')} description={Translator.t('components.organisms.lists.MyBrandList.section.description')}>
      <BrandList brands={brands} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MyBrandList;

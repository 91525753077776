import React from 'react';
import { useSelector } from 'react-redux';

import TextAreaInput from '../../atoms/inputs/TextAreaInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function ProductDescriptionInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <TextAreaInput
      label={Translator.t('components.molecules.inputs.ProductDescriptionInput.label')}
      placeholder={Translator.t('components.molecules.inputs.ProductDescriptionInput.placeholder')}
      icon="dots-horizontal"
      keyboardType="default"
      {...props}
    />
  );
}

export default ProductDescriptionInput;

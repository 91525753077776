import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Menu } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function DropdownInput(props) {
  const mode = props.hasOwnProperty('mode') ? props.mode : 'contained';
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const [visible, setVisible] = useState(false);
  const value = props.hasOwnProperty('value') ? props.value : null;
  const options = props.hasOwnProperty('options') ? props.options : {};
  const onChange = props.hasOwnProperty('onChange') ? props.onChange : null;
  const style = props.hasOwnProperty('style') ? props.style : {};
  const disabled = props.hasOwnProperty('disabled') ? props.disabled : false;

  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchor={<View style={styles.container}><Button style={style} contentStyle={styles.button} disabled={disabled} mode={mode} onPress={() => setVisible(true)}>{value === null ? Translator.t('words.open') : options[value]}</Button></View>}
      style={styles.menu}>
      {
        Object.keys(options).map(optionKey => {
          return (<Menu.Item key={optionKey} onPress={() => {
            setVisible(false);
            onChange(optionKey);
          }} title={options[optionKey]} />);
        })
      }
    </Menu>
  );
}

const styles = StyleSheet.create({
  container: {},
  button: {},
  menu: {
    marginTop: 40
  }
});

export default DropdownInput;

const countries = require('i18n-iso-countries');
const bgCountryConfig = require('i18n-iso-countries/langs/bg.json');
const enCountryConfig = require('i18n-iso-countries/langs/en.json');
const deCountryConfig = require('i18n-iso-countries/langs/de.json');
const ruCountryConfig = require('i18n-iso-countries/langs/ru.json');
const itCountryConfig = require('i18n-iso-countries/langs/it.json');
const frCountryConfig = require('i18n-iso-countries/langs/fr.json');

function Countries() {
  countries.registerLocale(bgCountryConfig);
  countries.registerLocale(enCountryConfig);
  countries.registerLocale(deCountryConfig);
  countries.registerLocale(ruCountryConfig);
  countries.registerLocale(itCountryConfig);
  countries.registerLocale(frCountryConfig);
  return countries;
}

export default Countries;

import React from 'react';
import { useSelector } from 'react-redux';

import SelectInput from '../../atoms/inputs/SelectInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function LocaleInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <SelectInput
      label={Translator.t('components.molecules.inputs.LocaleInput.label')}
      search
      {...props}
    />
  );
}

export default LocaleInput;

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

import PageReducer from './reducers/PageReducer';
import DesignReducer from './reducers/DesignReducer';
import IntlReducer from './reducers/IntlReducer';
import AuthReducer from './reducers/AuthReducer';

const reducers = combineReducers({
  page: PageReducer,
  design: DesignReducer,
  intl: IntlReducer,
  auth: AuthReducer,
});

const config = {
  key: 'Store',
  storage: AsyncStorage,
  blacklist: ['page', 'auth'],
};

const reducer = persistReducer(config, reducers);

const Store = configureStore({
  reducer,
  middleware: [thunk],
});

export default Store;

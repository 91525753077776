import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { capitalize } from 'stringulation';
import { Avatar, Button } from 'react-native-paper';

import Firebase from '../../../modules/Firebase';
import { Text } from 'react-native-paper';
import Translator from '../../../modules/Translator';
import { generateAcronym, prepareLocale } from '../../../modules/Helper';
import Config from '../../../Config';
import EmailInput from '../../molecules/inputs/EmailInput';
import firebase from 'firebase/app';
import Chip from '../elements/Chip';

function NotificationListManager(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const path = props.hasOwnProperty('path') ? props.path : null;
  const user = useSelector((state) => state.auth.user);
  const [notificationList, setNotificationList] = useState([]);
  const [users, setUsers] = useState({});
  const [newUserEmail, setNewUserEmail] = useState('');
  const [disabledForm, setDisabledForm] = useState(false);
  const handleSubmit = function () {
    setDisabledForm(true);
    Firebase.firestore().collection('user').where('email', '==', newUserEmail).get().then((usersRef) => {
      const usersDocs = usersRef.docs;
      if (usersDocs.length > 0) {
        const usersDoc = usersDocs[0];
        const newUserId = usersDoc.id;
        if (notificationList.includes(newUserId)) {
          alert(Translator.t('components.atoms.buttons.NotificationListManager.error.alreadyExists'));
          setNewUserEmail('');
          setDisabledForm(false);
          return;
        }
        Firebase.firestore().doc(path).set({
          notificationList: firebase.firestore.FieldValue.arrayUnion(newUserId)
        }, {
          merge: true,
        }).then(function () {
          setNotificationList([
            ...notificationList,
            newUserId
          ]);
          setNewUserEmail('');
          setDisabledForm(false);
        }).catch((error) => {
          alert(error.message);
          setDisabledForm(false);
        });
      }
      else {
        alert(Translator.t('components.atoms.buttons.NotificationListManager.error.notExistingUser'));
        setDisabledForm(false);
      }
    }).catch((error) => {
      alert(error.message);
      setDisabledForm(false);
    });
  };
  const onClose = function (removeUserId) {
    const newNotificationList = notificationList;
    const index = newNotificationList.indexOf(removeUserId);
    if (index > -1) {
      newNotificationList.splice(index, 1);
    }
    Firebase.firestore().doc(path).update({
      notificationList: newNotificationList
    }).catch((error) => {
      alert(error.message);
    });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().doc(path).onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const notificationList = data.hasOwnProperty('notificationList') ? data.notificationList : [];
        setNotificationList(notificationList);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let users = {};
    const unsubscribeUsers = [];
    notificationList.forEach(function (userId, userIdKey) {
      const unsubscribe = Firebase.firestore().collection('user').doc(userId).onSnapshot((userDoc) => {
        if (userDoc.exists) {
          const user = userDoc.data()
          users = {
            ...users,
            [userId]: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email
            }
          };
          setUsers(users);
        }
      });
      unsubscribeUsers.push(unsubscribe);
    });
    return () => {
      if (unsubscribeUsers.length !== 0) {
        unsubscribeUsers.forEach((unsubscribeUser, unsubscribeUsersKey) => {
          unsubscribeUser();
        });
      }
    };
  }, [notificationList]);

  return (
    <View>
      <View>
        <EmailInput value={newUserEmail} onChange={(newUserEmail) => setNewUserEmail(newUserEmail)} disabled={disabledForm} />
        <Button style={styles.newUserSubmitButton} mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.atoms.buttons.NotificationListManager.submit.title')}</Button>
      </View>
      <View style={styles.chipContainer}>
        {
          Object.keys(users).map((userId) => (
            <Chip onClose={userId !== user ? (() => {
              onClose(userId);
            }) : null} avatar={<Avatar.Text size={20} label={generateAcronym(capitalize(users[userId].firstName + ' ' + users[userId].lastName))} />} key={userId} size={30}>
              <Text>
                {users[userId].email}{user === userId ? ' (' + capitalize(Translator.t('words.you')) + ')' : ''}
              </Text>
            </Chip>
          ))
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  newUserSubmitButton: {
    marginBottom: 10
  }
});

export default NotificationListManager;

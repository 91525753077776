import React from 'react';
import { useSelector } from 'react-redux';

import LocationInput from '../../atoms/inputs/LocationInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function StoreLocationInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <LocationInput
      label={Translator.t('components.molecules.inputs.StoreLocationInput.label')}
      {...props}
    />
  );
}

export default StoreLocationInput;

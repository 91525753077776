import React from 'react';

import TextInput from '../../atoms/inputs/TextInput';

function IntInput(props) {
  let value = props.hasOwnProperty('value') && typeof props.value === 'string' ? props.value : '';
  value = value.replace(',', '.');
  value = /^\d+$/.test(value) ? value : '';

  return (
    <TextInput
      keyboardType="number-pad"
      {...props}
      value={value}
    />
  );
}

export default IntInput;

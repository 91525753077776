import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ProductList from './ProductList';
import Section from '../../atoms/layouts/Section';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { prepareLocale } from '../../../modules/Helper';

function MyProductList(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('product').where(`rights.${user}`, '>', 0).onSnapshot((productsRef) => {
      const productsDocs = productsRef.docs;
      const products = [];
      productsDocs.forEach((productsDoc) => {
        const product = productsDoc.data();
        products.push({
          cover: product.image,
          name: product.waiterName,
          productId: productsDoc.id,
          enableView: product.rights[user] >= 1,
          enableEdit: product.rights[user] >= 4,
          enableDelete: product.rights[user] >= 7,
        });
      });
      setProducts(products);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Section title={Translator.t('components.organisms.lists.MyProductList.section.title')} description={Translator.t('components.organisms.lists.MyProductList.section.description')}>
      <ProductList products={products} hasAddNewButton={true} {...props} />
    </Section>
  );
}

export default MyProductList;

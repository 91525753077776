import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import View from '../atoms/layouts/View';
import Col from '../atoms/layouts/Col';
import Section from '../atoms/layouts/Section';
import LogoutButton from '../atoms/buttons/LogoutButton';
import Translator from '../../modules/Translator';
import { capitalize } from 'stringulation';
import ThemeSelector from '../organisms/forms/ThemeSelector';
import LocaleSelector from '../organisms/forms/LocaleSelector';
import CurrencySelector from '../organisms/forms/CurrencySelector';
import TimezoneSelector from '../organisms/forms/TimezoneSelector';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import { prepareLocale } from '../../modules/Helper';

function MyAccount() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();

  return (
    <DashboardLayout>
      <View>
        <Section title={capitalize(Translator.t('words.hello')) + '! 👋'} description={capitalize(Translator.t('words.welcome')) + ' ' + Translator.t('words.again') + '!'}/>
        <Section title={Translator.t('pages.MyAccount.content.theme.title')} description={Translator.t('pages.MyAccount.content.theme.description')}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <ThemeSelector mode='contained' />
          </Col>
        </Section>
        <Section title={Translator.t('pages.MyAccount.content.locale.title')} description={Translator.t('pages.MyAccount.content.locale.description')}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <LocaleSelector mode='contained' />
          </Col>
        </Section>
        <Section title={Translator.t('pages.MyAccount.content.currency.title')} description={Translator.t('pages.MyAccount.content.currency.description')}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <CurrencySelector mode='contained' />
          </Col>
        </Section>
        <Section title={Translator.t('pages.MyAccount.content.timezone.title')} description={Translator.t('pages.MyAccount.content.timezone.description')}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <TimezoneSelector mode='contained' />
          </Col>
        </Section>
        <Section title={Translator.t('pages.MyAccount.content.update.title')} description={Translator.t('pages.MyAccount.content.update.description')}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <Button mode='contained' onPress={() => {
              navigation.push('MyAccountUpdate');
            }}>{Translator.t('words.edit')}</Button>
          </Col>
        </Section>
        <Section title={Translator.t('pages.MyAccount.content.logOut.title')} description={Translator.t('pages.MyAccount.content.logOut.description')} hideBottomDivider={true}>
          <Col xs={12} sm={12} md={6} lg={4}>
            <LogoutButton mode='outlined' />
          </Col>
        </Section>
      </View>
    </DashboardLayout>
  );
}

export default MyAccount;

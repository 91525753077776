import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import CompanyNameInput from '../../molecules/inputs/CompanyNameInput';
import { changeIsLoading, changeSelect, createSelect } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import PhoneCodes from "../../../modules/PhoneCodes";
import CompanyPhoneCodeInput from "../../molecules/inputs/CompanyPhoneCodeInput";
import CompanyPhoneNumberInput from "../../molecules/inputs/CompanyPhoneNumberInput";
import CompanyCountryInput from "../../molecules/inputs/CompanyCountryInput";
import Countries from '../../../modules/Countries';
import CompanyStateInput from "../../molecules/inputs/CompanyStateInput";
import CompanyCityInput from "../../molecules/inputs/CompanyCityInput";
import CompanyAddressLine1Input from '../../molecules/inputs/CompanyAddressLine1Input';
import CompanyAddressLine2Input from '../../molecules/inputs/CompanyAddressLine2Input';
import CompanyPostalCodeInput from '../../molecules/inputs/CompanyPostalCodeInput';

function CompanyEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const companyId = parsedDocPath.company;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const [disabledForm, setDisabledForm] = useState(true);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [errors, setErrors] = useState({});
  const countries = Countries();
  const phoneCodes = PhoneCodes();
  const validate = function () {
    const errors = {};
    if (typeof name !== 'string' || name.length === 0) {
      errors["CompanyNameInput"] = {
        "requiredField": {}
      };
    }
    if (!selects.hasOwnProperty('companyEditFormPhoneCode') || !selects.companyEditFormPhoneCode.hasOwnProperty('value') || selects.companyEditFormPhoneCode.value === null) {
      errors["CompanyPhoneCodeInput"] = {
        "requiredField": {}
      };
    }
    if (typeof phoneNumber !== 'string' || phoneNumber.length === 0) {
      errors["CompanyPhoneNumberInput"] = {
        "requiredField": {}
      };
    }
    if (!selects.hasOwnProperty('companyEditFormCountry') || !selects.companyEditFormCountry.hasOwnProperty('value') || selects.companyEditFormCountry.value === null) {
      errors["CompanyCountryInput"] = {
        "requiredField": {}
      };
    }
    if (typeof state !== 'string' || state.length === 0) {
      errors["CompanyStateInput"] = {
        "requiredField": {}
      };
    }
    if (typeof city !== 'string' || city.length === 0) {
      errors["CompanyCityInput"] = {
        "requiredField": {}
      };
    }
    if (typeof addressLine1 !== 'string' || addressLine1.length === 0) {
      errors["CompanyAddressLine1Input"] = {
        "requiredField": {}
      };
    }
    if (typeof addressLine2 !== 'string' || addressLine2.length === 0) {
      errors["CompanyAddressLine2Input"] = {
        "requiredField": {}
      };
    }
    if (typeof postalCode !== 'string' || postalCode.length === 0) {
      errors["CompanyPostalCodeInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('company').doc(companyId).set({
      name,
      country: selects.companyEditFormCountry.value,
      phoneCode: selects.companyEditFormPhoneCode.value,
      phoneNumber,
      state,
      city,
      addressLine1,
      addressLine2,
      postalCode,
    }, {
      merge: true,
    })
      .then(() => {
        alert(Translator.t('components.organisms.forms.CompanyEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const phoneCodeSelectOptions = [];
    for (const [iso2, phoneCode] of Object.entries(phoneCodes)) {
      phoneCodeSelectOptions.push({
        label: phoneCode + ' (' + iso2 + ')',
        value: phoneCode,
      });
    }
    dispatch(createSelect({
      id: 'companyEditFormPhoneCode',
      select: {
        options: phoneCodeSelectOptions,
        enableSearch: true
      },
    }));
    const countrySelectOptions = [];
    for (const [iso2, text] of Object.entries(countries.getNames(Translator.locale))) {
      countrySelectOptions.push({
        label: text,
        value: iso2,
      });
    }
    dispatch(createSelect({
      id: 'companyEditFormCountry',
      select: {
        options: countrySelectOptions,
        enableSearch: true
      },
    }));
    const unsubscribe = Firebase.firestore().collection('company').doc(companyId).onSnapshot((companyDoc) => {
      if (companyDoc.exists) {
        const company = companyDoc.data();
        if (company.rights.hasOwnProperty(user) && company.rights[user] >= 4) {
          setName(company.name);
          dispatch(changeSelect({
            id: 'companyEditFormPhoneCode',
            select: {
              value: company.phoneCode,
            },
          }));
          dispatch(changeSelect({
            id: 'companyEditFormCountry',
            select: {
              value: company.country,
            },
          }));
          setPhoneNumber(company.phoneNumber);
          setState(company.state);
          setCity(company.city);
          setAddressLine1(company.addressLine1);
          setAddressLine2(company.addressLine2);
          setPostalCode(company.postalCode);
          setDisabledForm(false);
        } else {
          setName('');
          dispatch(changeSelect({
            id: 'companyEditFormPhoneCode',
            select: {
              value: null,
            },
          }));
          dispatch(changeSelect({
            id: 'companyEditFormCountry',
            select: {
              value: null,
            },
          }));
          setPhoneNumber('');
          setState('');
          setCity('');
          setAddressLine1('');
          setAddressLine2('');
          setPostalCode('');
          setDisabledForm(true);
          alert(Translator.t('components.organisms.forms.CompanyEditForm.error.noPermissions'));
        }
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <CompanyNameInput errors={errors.hasOwnProperty('CompanyNameInput') ? errors.CompanyNameInput : {}} value={name} onChange={(name) => setName(name)} disabled={disabledForm} />
      <CompanyPhoneCodeInput errors={errors.hasOwnProperty('CompanyPhoneCodeInput') ? errors.CompanyPhoneCodeInput : {}} value={selects.hasOwnProperty('companyEditFormPhoneCode') && selects.companyEditFormPhoneCode.hasOwnProperty('value') ? selects.companyEditFormPhoneCode.value : null} id="companyEditFormPhoneCode" options={selects.hasOwnProperty('companyEditFormPhoneCode') && selects.companyEditFormPhoneCode.hasOwnProperty('options') ? selects.companyEditFormPhoneCode.options : []} disabled={disabledForm}/>
      <CompanyPhoneNumberInput errors={errors.hasOwnProperty('CompanyPhoneNumberInput') ? errors.CompanyPhoneNumberInput : {}} value={phoneNumber} onChange={(phoneNumber) => setPhoneNumber(phoneNumber)} disabled={disabledForm} />
      <CompanyCountryInput errors={errors.hasOwnProperty('CompanyCountryInput') ? errors.CompanyCountryInput : {}} value={selects.hasOwnProperty('companyEditFormCountry') && selects.companyEditFormCountry.hasOwnProperty('value') ? selects.companyEditFormCountry.value : null} id="companyEditFormCountry" options={selects.hasOwnProperty('companyEditFormCountry') && selects.companyEditFormCountry.hasOwnProperty('options') ? selects.companyEditFormCountry.options : []} disabled={disabledForm}/>
      <CompanyStateInput errors={errors.hasOwnProperty('CompanyStateInput') ? errors.CompanyStateInput : {}} value={state} onChange={(state) => setState(state)} disabled={disabledForm} />
      <CompanyCityInput errors={errors.hasOwnProperty('CompanyCityInput') ? errors.CompanyCityInput : {}} value={city} onChange={(city) => setCity(city)} disabled={disabledForm} />
      <CompanyAddressLine1Input errors={errors.hasOwnProperty('CompanyAddressLine1Input') ? errors.CompanyAddressLine1Input : {}} value={addressLine1} onChange={(addressLine1) => setAddressLine1(addressLine1)} disabled={disabledForm} />
      <CompanyAddressLine2Input errors={errors.hasOwnProperty('CompanyAddressLine2Input') ? errors.CompanyAddressLine2Input : {}} value={addressLine2} onChange={(addressLine2) => setAddressLine2(addressLine2)} disabled={disabledForm} />
      <CompanyPostalCodeInput errors={errors.hasOwnProperty('CompanyPostalCodeInput') ? errors.CompanyPostalCodeInput : {}} value={postalCode} onChange={(postalCode) => setPostalCode(postalCode)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.CompanyEditForm.submit.title')}</Button>
    </View>
  );
}

export default CompanyEditForm;

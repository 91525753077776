import { createSlice } from '@reduxjs/toolkit';

import Config from '../../Config';
import Currencies from '../../modules/Currencies';
import Timezones from '../../modules/Timezones';

export const IntlSlice = createSlice({
  name: 'intl',
  initialState: {
    locale: Config.defaultLocale,
    currency: Config.defaultCurrency,
    timezone: Config.defaultTimezone
  },
  reducers: {
    changeLocale: (state, action) => {
      state.locale = Config.supportedLocales.includes(action.payload) ? action.payload : Config.defaultLocale;
    },
    changeCurrency: (state, action) => {
      const currencies = Currencies();
      state.currency = Object.keys(currencies.getAll('en')).includes(action.payload) ? action.payload : Config.defaultCurrency;
    },
    changeTimezone: (state, action) => {
      const timezones = Timezones();
      state.timezone = timezones.includes(action.payload) ? action.payload : Config.defaultTimezone;
    }
  },
});

export const {
  changeLocale,
  changeCurrency,
  changeTimezone
} = IntlSlice.actions;

export default IntlSlice.reducer;

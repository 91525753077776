import React from 'react';

import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import View from '../atoms/layouts/View';
import UpdateAccount from '../organisms/forms/UpdateAccount';
import DashboardLayout from '../atoms/layouts/DashboardLayout';

function MyAccountUpdate() {
  return (
    <DashboardLayout>
      <View>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <UpdateAccount/>
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default MyAccountUpdate;

import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DataTable, Text, Caption } from 'react-native-paper';
import { useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Languages from '../../../modules/Languages';
import { prepareLocale } from '../../../modules/Helper';

function TranslationsView(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const languages = Languages();
  const title = props.hasOwnProperty('title') ? props.title : null;
  const description = props.hasOwnProperty('description') ? props.description : null;
  const translations = props.hasOwnProperty('translations') ? props.translations : {};

  return (
    <View style={styles.container}>
      {
        title !== null ? (
          <Text>{title}</Text>
        ) : null
      }
      {
        description !== null ? (
          <Caption>{description}</Caption>
        ) : null
      }
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>{Translator.t('pages.Translations.other.table.language.title')}</DataTable.Title>
          <DataTable.Title>{Translator.t('pages.Translations.other.table.translation.title')}</DataTable.Title>
        </DataTable.Header>
        {
          Object.keys(translations).length === 0 ? (
            <DataTable.Row>
              <DataTable.Cell>---</DataTable.Cell>
              <DataTable.Cell>---</DataTable.Cell>
            </DataTable.Row>
          ) : (
            Object.keys(translations).map((locale, index) => (
              <DataTable.Row key={index}>
                <DataTable.Cell>{languages.getName(locale, Translator.locale)}</DataTable.Cell>
                <DataTable.Cell>{translations[locale]}</DataTable.Cell>
              </DataTable.Row>
            ))
          )
        }
      </DataTable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 10
  }
});

export default TranslationsView;

import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { buildDocPath, parseDocPath, prepareLocale } from '../../modules/Helper';
import { useSelector } from 'react-redux';
import Firebase from '../../modules/Firebase';
import View from '../atoms/layouts/View';
import { Button, Title } from 'react-native-paper';
import { capitalize } from 'stringulation';
import Translator from '../../modules/Translator';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Section from '../atoms/layouts/Section';
import LocationView from '../atoms/elements/LocationView';
import TableList from '../organisms/lists/TableList';
import RightsManager from '../atoms/buttons/RightsManager';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import Card from '../atoms/elements/Card';

function StoreGetItem(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const companyId = props.route.params.companyId;
  const brandId = props.route.params.brandId;
  const storeId = props.route.params.storeId;
  const path = buildDocPath({
    company: companyId,
    brand: brandId,
    store: storeId,
  });
  const user = useSelector((state) => state.auth.user);
  const [store, setStore] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [tables, setTables] = useState([]);

  useEffect(() => {
    const unsubscribeStore = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('store').doc(storeId).onSnapshot((storeDoc) => {
      if (storeDoc.exists) {
        const store = storeDoc.data();
        setStore(store);
        setIsFound(true);
        setIsAllowed(store.rights.hasOwnProperty(user) && store.rights[user] >= 1);
        setIsEditAllowed(store.rights.hasOwnProperty(user) && store.rights[user] >= 4);
        props.navigation.setOptions({
          title: store.name
        });
      }
      setIsLoading(false);
    });
    const unsubscribeTables = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('store').doc(storeId).collection('table').where(`rights.${user}`, '>', 0).onSnapshot((tablesRef) => {
      const tablesDocs = tablesRef.docs;
      const tables = [];
      tablesDocs.forEach((tablesDoc) => {
        const tablesDocParsedPath = parseDocPath(tablesDoc.ref.path);
        const table = tablesDoc.data();
        tables.push({
          name: table.waiterName,
          companyId: tablesDocParsedPath.company,
          brandId: tablesDocParsedPath.brand,
          storeId: tablesDocParsedPath.store,
          tableId: tablesDoc.id,
          enableView: table.rights[user] >= 1,
          enableEdit: table.rights[user] >= 4,
          enableDelete: table.rights[user] >= 7,
        });
      });
      setTables(tables);
    });
    return () => {
      unsubscribeStore();
      unsubscribeTables();
    };
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isFound) {
    return (
      <DashboardLayout>
        <View>
          <Title>{capitalize(Translator.t('words.notFound'), true)}</Title>
        </View>
      </DashboardLayout>
    );
  }

  if (!isAllowed) {
    return (
      <DashboardLayout>
        <View>
          <Title>{Translator.t('messages.notAllowed')}</Title>
        </View>
      </DashboardLayout>
    );
  }

  const isCashPaymentSupported = store.hasOwnProperty('isCashPaymentSupported') && store.isCashPaymentSupported === true;
  const isCardTerminalPaymentSupported = store.hasOwnProperty('isCardTerminalPaymentSupported') && store.isCardTerminalPaymentSupported === true;
  const isCardAppPaymentSupported = store.hasOwnProperty('isCardAppPaymentSupported') && store.isCardAppPaymentSupported === true;

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Section title={Translator.t('pages.StoreGetItem.content.paymentInformation.title')} description={Translator.t('pages.StoreGetItem.content.paymentInformation.description')}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.StoreGetItem.content.paymentInformation.isCashPaymentSupported.title')} subtitle={Translator.t('pages.StoreGetItem.content.paymentInformation.isCashPaymentSupported.description')} icon={isCashPaymentSupported ? "check" : "close"} iconBgColor={isCashPaymentSupported ? "green" : "red"} />
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.StoreGetItem.content.paymentInformation.isCardTerminalPaymentSupported.title')} subtitle={Translator.t('pages.StoreGetItem.content.paymentInformation.isCardTerminalPaymentSupported.description')} icon={isCardTerminalPaymentSupported ? "check" : "close"} iconBgColor={isCardTerminalPaymentSupported ? "green" : "red"} />
              </Col>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.StoreGetItem.content.paymentInformation.isCardAppPaymentSupported.title')} subtitle={Translator.t('pages.StoreGetItem.content.paymentInformation.isCardAppPaymentSupported.description')} icon={isCardAppPaymentSupported ? "check" : "close"} iconBgColor={isCardAppPaymentSupported ? "green" : "red"} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.StoreGetItem.content.location.title')} description={Translator.t('pages.StoreGetItem.content.location.description')}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <LocationView location={store.location}/>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.StoreGetItem.content.management.title')} description={Translator.t('pages.StoreGetItem.content.management.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Button mode='contained' onPress={() => {
                  navigation.push('StoreUpdateItem', {
                    companyId,
                    brandId,
                    storeId,
                  });
                }}>{Translator.t('words.edit')}</Button>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.StoreGetItem.content.rights.title')} description={Translator.t('pages.StoreGetItem.content.rights.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <RightsManager path={path} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.StoreGetItem.content.tables.title')} description={Translator.t('pages.StoreGetItem.content.tables.description')} hideBottomDivider={true}>
            <TableList tables={tables} hasAddNewButton={true} addNewButtonParams={{
              path: path
            }} {...props} />
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default StoreGetItem;

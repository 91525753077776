import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Config from '../../../Config';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import DropdownInput from '../../atoms/inputs/DropdownInput';
import Currencies from '../../../modules/Currencies';
import { prepareLocale } from '../../../modules/Helper';

function CurrencySelector(props) {
  const mode = props.hasOwnProperty('mode') ? props.mode : 'contained';
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const user = useSelector((state) => state.auth.user);
  const [currency, setCurrency] = useState(Config.defaultCurrency);
  const options = {};
  const currencies = Currencies();
  const onChange = function (currency) {
    Firebase.firestore().collection('user').doc(user).set({
      currency: currency
    }, {
      merge: true
    });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('user').doc(user).onSnapshot((userDoc) => {
      const user = userDoc.data();
      setCurrency(user.hasOwnProperty('currency') ? user.currency : Config.defaultCurrency);
    });
    return () => unsubscribe();
  }, []);

  options['auto'] = Translator.t('currencies.auto');

  for (const [index, currency] of Object.entries(currencies.getAll(Translator.locale))) {
    options[currency.code] = currency.name + ' (' + currency.symbol + ')';
  }

  return (
    <DropdownInput mode={mode} value={currency} options={options} onChange={onChange}/>
  );
}

export default CurrencySelector;

import React from 'react';
import { useSelector } from 'react-redux';

import TextInput from '../../atoms/inputs/TextInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function CompanyStateInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <TextInput
      label={Translator.t('components.molecules.inputs.CompanyStateInput.label')}
      placeholder={Translator.t('components.molecules.inputs.CompanyStateInput.placeholder')}
      icon="dots-horizontal"
      keyboardType="default"
      {...props}
    />
  );
}

export default CompanyStateInput;

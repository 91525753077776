import { createSlice } from '@reduxjs/toolkit';

export const PageSlice = createSlice({
  name: 'page',
  initialState: {
    isLoading: false,
    selectedValues: {},
    selects: {},
    translations: {},
    locations: {},
    modal: null
  },
  reducers: {
    changeIsLoading: (state, action) => {
      state.isLoading = typeof action.payload === 'boolean' ? action.payload : false;
    },
    createSelect: (state, action) => {
      const requiredKeys = ['id', 'select'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        state.selects[action.payload.id] = action.payload.select;
      }
    },
    changeSelect: (state, action) => {
      const requiredKeys = ['id', 'select'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey)) && state.selects.hasOwnProperty(action.payload.id)) {
        state.selects[action.payload.id] = { ...state.selects[action.payload.id], ...action.payload.select };
      }
    },
    removeSelect: (state, action) => {
      const requiredKeys = ['id'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        delete state.selects[action.payload.id];
      }
    },
    createTranslations: (state, action) => {
      const requiredKeys = ['id', 'translations'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        state.translations[action.payload.id] = action.payload.translations;
      }
    },
    changeTranslations: (state, action) => {
      const requiredKeys = ['id', 'translations'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey)) && state.translations.hasOwnProperty(action.payload.id)) {
        state.translations[action.payload.id] = { ...state.translations[action.payload.id], ...action.payload.translations };
      }
    },
    removeTranslations: (state, action) => {
      const requiredKeys = ['id'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        delete state.translations[action.payload.id];
      }
    },
    createLocation: (state, action) => {
      const requiredKeys = ['id', 'location'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        state.locations[action.payload.id] = action.payload.location;
      }
    },
    changeLocation: (state, action) => {
      const requiredKeys = ['id', 'location'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey)) && state.locations.hasOwnProperty(action.payload.id)) {
        state.locations[action.payload.id] = { ...state.locations[action.payload.id], ...action.payload.location };
      }
    },
    removeLocation: (state, action) => {
      const requiredKeys = ['id'];
      if (requiredKeys.every((requiredKey) => action.payload.hasOwnProperty(requiredKey))) {
        delete state.locations[action.payload.id];
      }
    },
    changeModal: (state, action) => {
      state.modal = action.payload;
    }
  },
});

export const {
  changeIsLoading,
  createSelect,
  changeSelect,
  removeSelect,
  createTranslations,
  changeTranslations,
  removeTranslations,
  createLocation,
  changeLocation,
  removeLocation,
  changeModal
} = PageSlice.actions;

export default PageSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { Button, Title } from 'react-native-paper';
import { capitalize } from 'stringulation';

import { buildDocPath, parseDocPath, prepareLocale } from '../../modules/Helper';
import View from '../atoms/layouts/View';
import Translator from '../../modules/Translator';
import Firebase from '../../modules/Firebase';
import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import Section from '../atoms/layouts/Section';
import StoreList from '../organisms/lists/StoreList';
import MenuList from '../organisms/lists/MenuList';
import Card from '../atoms/elements/Card';
import ImageView from '../atoms/elements/ImageView';
import TagsView from '../atoms/elements/TagsView';
import RightsManager from '../atoms/buttons/RightsManager';
import DashboardLayout from '../atoms/layouts/DashboardLayout';

function BrandGetItem(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const navigation = useNavigation();
  const companyId = props.route.params.companyId;
  const brandId = props.route.params.brandId;
  const path = buildDocPath({
    company: companyId,
    brand: brandId,
  });
  const user = useSelector((state) => state.auth.user);
  const [brand, setBrand] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [stores, setStores] = useState([]);
  const [menus, setMenus] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);

  useEffect(() => {
    let unsubscribeActiveMenu = null;
    const unsubscribeCompany = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).onSnapshot((brandDoc) => {
      if (brandDoc.exists) {
        const brand = brandDoc.data();
        setBrand(brand);
        setIsFound(true);
        setIsAllowed(brand.rights.hasOwnProperty(user) && brand.rights[user] >= 1);
        setIsEditAllowed(brand.rights.hasOwnProperty(user) && brand.rights[user] >= 4);
        props.navigation.setOptions({
          title: brand.name
        });
        if (brand.hasOwnProperty('activeMenu') && brand.activeMenu !== null) {
          unsubscribeActiveMenu = Firebase.firestore().doc(brand.activeMenu).onSnapshot((menuDoc) => {
            if (menuDoc.exists) {
              const menu = menuDoc.data();
              setActiveMenu(menu);
            }
          });
        }
        else {
          setActiveMenu(null);
        }
      }
      setIsLoading(false);
    });
    const unsubscribeStores = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('store').where(`rights.${user}`, '>', 0).onSnapshot((storesRef) => {
      const storesDocs = storesRef.docs;
      const stores = [];
      storesDocs.forEach((storesDoc) => {
        const storesDocParsedPath = parseDocPath(storesDoc.ref.path);
        const store = storesDoc.data();
        stores.push({
          name: store.name,
          companyId: storesDocParsedPath.company,
          brandId: storesDocParsedPath.brand,
          storeId: storesDoc.id,
          enableView: store.rights[user] >= 1,
          enableEdit: store.rights[user] >= 4,
          enableDelete: store.rights[user] >= 7,
        });
      });
      setStores(stores);
    });
    const unsubscribeMenus = Firebase.firestore().collection('company').doc(companyId).collection('brand').doc(brandId).collection('menu').where(`rights.${user}`, '>', 0).onSnapshot((menusRef) => {
      const menusDocs = menusRef.docs;
      const menus = [];
      menusDocs.forEach((menusDoc) => {
        const menusDocParsedPath = parseDocPath(menusDoc.ref.path);
        const menu = menusDoc.data();
        menus.push({
          name: menu.waiterName,
          companyId: menusDocParsedPath.company,
          brandId: menusDocParsedPath.brand,
          menuId: menusDoc.id,
          enableView: menu.rights[user] >= 1,
          enableEdit: menu.rights[user] >= 4,
          enableDelete: menu.rights[user] >= 7,
        });
      });
      setMenus(menus);
    });
    return () => {
      unsubscribeCompany();
      unsubscribeStores();
      unsubscribeMenus();
      if (unsubscribeActiveMenu !== null) {
        unsubscribeActiveMenu();
      }
    };
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isFound) {
    return (
      <DashboardLayout>
        <View>
          <Title>{capitalize(Translator.t('words.notFound'), true)}</Title>
        </View>
      </DashboardLayout>
    );
  }

  if (!isAllowed) {
    return (
      <DashboardLayout>
        <View>
          <Title>{Translator.t('messages.notAllowed')}</Title>
        </View>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Section title={Translator.t('pages.BrandGetItem.content.generalInformation.title')} description={Translator.t('pages.BrandGetItem.content.generalInformation.description')}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <ImageView path={path} property='logo' avatar={true} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Card title={Translator.t('pages.BrandGetItem.content.generalInformation.tags.title')} subtitle={Translator.t('pages.BrandGetItem.content.generalInformation.tags.description')} icon="tag">
                  <TagsView tags={brand.tags}/>
                </Card>
              </Col>
              {
                activeMenu !== null ? (
                  <Col xs={12} sm={12} md={6} lg={4}>
                    <Card title={Translator.t('pages.BrandGetItem.content.generalInformation.activeMenu.title')} subtitle={Translator.t('pages.BrandGetItem.content.generalInformation.activeMenu.description')} icon="book-open">
                      <Title>{activeMenu.waiterName}</Title>
                    </Card>
                  </Col>
                ) : null
              }
            </Row>
          </Section>
          <Section title={Translator.t('pages.BrandGetItem.content.management.title')} description={Translator.t('pages.BrandGetItem.content.management.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={4}>
                <Button mode='contained' onPress={() => {
                  navigation.push('BrandUpdateItem', {
                    companyId,
                    brandId,
                  });
                }}>{Translator.t('words.edit')}</Button>
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.BrandGetItem.content.rights.title')} description={Translator.t('pages.BrandGetItem.content.rights.description')} hidden={!isEditAllowed}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <RightsManager path={path} />
              </Col>
            </Row>
          </Section>
          <Section title={Translator.t('pages.BrandGetItem.content.stores.title')} description={Translator.t('pages.BrandGetItem.content.stores.description')}>
            <StoreList stores={stores} hasAddNewButton={true} addNewButtonParams={{
              path: path
            }} {...props} />
          </Section>
          <Section title={Translator.t('pages.BrandGetItem.content.menus.title')} description={Translator.t('pages.BrandGetItem.content.menus.description')} hideBottomDivider={true}>
            <MenuList menus={menus} hasAddNewButton={true} addNewButtonParams={{
              path: path
            }} {...props} />
          </Section>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default BrandGetItem;

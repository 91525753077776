import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, View, Platform } from 'react-native';
import { Avatar, Button, useTheme } from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

import Row from '../layouts/Row';
import Col from '../layouts/Col';
import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function ImageManager(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const theme = useTheme();
  const path = props.hasOwnProperty('path') ? props.path : null;
  const property = props.hasOwnProperty('property') ? props.property : null;
  if (path === null || property === null) {
    return null;
  }
  const parsedDocPath = parseDocPath(path);
  const showAsAvatar = props.hasOwnProperty('avatar') && props.avatar === true;
  const label = props.hasOwnProperty('label') ? props.label : Translator.t('components.atoms.buttons.ImageManager.upload.submit.title');
  const [disabledForm, setDisabledForm] = useState(true);
  const [image, setImage] = useState(null);
  const [meta, setMeta] = useState(null);
  const uploadImage = async function () {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setDisabledForm(true);
      const response = await fetch(result.uri);
      const blob = await response.blob();
      const entity = Object.keys(parsedDocPath)[Object.keys(parsedDocPath).length - 1];
      const entityId = parsedDocPath[Object.keys(parsedDocPath)[Object.keys(parsedDocPath).length - 1]];
      const ref = Firebase.storage().ref().child(`images/${entity}/${entityId}`);
      ref.put(blob).then(function (task) {
        task.ref.getDownloadURL().then(function (url) {
          Firebase.firestore().doc(path).set({
            [property]: url,
          }, {
            merge: true,
          }).then(function () {
            alert(Translator.t('components.atoms.buttons.ImageManager.upload.success.title'));
            setDisabledForm(false);
          }).catch((error) => {
            alert(error.message);
          });
        }).catch((error) => {
          alert(error.message);
        });
      }).catch((error) => {
        alert(error.message);
      });
    }
  };
  const deleteImage = function () {
    const entity = Object.keys(parsedDocPath)[Object.keys(parsedDocPath).length - 1];
    const entityId = parsedDocPath[Object.keys(parsedDocPath)[Object.keys(parsedDocPath).length - 1]];

    setDisabledForm(true);

    Firebase.firestore().doc(path).set({
      [property]: null
    }, {
      merge: true,
    }).then(function () {
      Firebase.storage().ref().child(`images/${entity}/${entityId}`).delete().then(function () {
        alert(Translator.t('components.atoms.buttons.ImageManager.delete.success.title'));
        setImage(null);
        setMeta(null);
        setDisabledForm(false);
      }).catch((error) => {
        alert(error.message);
      });
    }).catch((error) => {
      alert(error.message);
    });
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    imageContainer: {
      marginBottom: 10,
    },
    avatar: {
      width: null,
      height: null,
      backgroundColor: null,
      borderWidth: 1,
      borderColor: theme.colors.primary,
    },
    image: {
      width: 128,
      height: meta && meta.height / (meta.width / 128),
      resizeMode: 'stretch',
      backgroundColor: null,
      borderWidth: 1,
      borderColor: theme.colors.primary,
      borderRadius: 10,
    },
  });

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert(Translator.t('components.atoms.buttons.ImageManager.upload.error.noAccess'));
        }
      }
    })();
    const unsubscribe = Firebase.firestore().doc(path).onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data[property] !== null) {
          Image.getSize(data[property], (width, height) => {
            setImage(data[property]);
            setMeta({
              width: width,
              height: height
            });
          });
        }
        setDisabledForm(false);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <View style={styles.container}>
      { image !== null ? (
        <View style={styles.imageContainer}>
          { showAsAvatar ? (
            <Avatar.Image size={128} source={{ uri: image }} style={styles.avatar} />
          ) : (
            <Image source={{ uri: image }} style={styles.image} />
          ) }
        </View>
      ) : null }
      <Row>
        <Col xs={image !== null ? 9 : 12} sm={image !== null ? 9 : 12} md={image !== null ? 9 : 12} lg={image !== null ? 9 : 12}>
          <Button mode="contained" onPress={uploadImage} {...props} disabled={disabledForm}>{ label }</Button>
        </Col>
        {
          image !== null && (
            <Col xs={3} sm={3} md={3} lg={3}>
              <Button mode="outlined" onPress={deleteImage} {...props} disabled={disabledForm}><MaterialCommunityIcons name="delete" size={16} /></Button>
            </Col>
          )
        }
      </Row>
    </View>
  );
}

export default ImageManager;

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import TableWaiterNameInput from '../../molecules/inputs/TableWaiterNameInput';
import TableSeatsInput from '../../molecules/inputs/TableSeatsInput';
import { changeIsLoading } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';

function TableEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const companyId = parsedDocPath.company;
  const brandId = parsedDocPath.brand;
  const storeId = parsedDocPath.store;
  const tableId = parsedDocPath.table;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [disabledForm, setDisabledForm] = useState(true);
  const [waiterName, setWaiterName] = useState('');
  const [seats, setSeats] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["TableWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (!/^\d+$/.test(seats)) {
      errors["TableSeatsInput"] = {
        "requiredField": {}
      };
    }
    else if (parseInt(seats, 10) > 50 || parseInt(seats, 10) <= 0) {
      errors["TableSeatsInput"] = {
        "notValidValue": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('store')
      .doc(storeId)
      .collection('table')
      .doc(tableId)
      .set({
        waiterName,
        seats: parseInt(seats, 10),
      }, {
        merge: true,
      })
      .then(() => {
        alert(Translator.t('components.organisms.forms.TableEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribe = Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .collection('store')
      .doc(storeId)
      .collection('table')
      .doc(tableId)
      .onSnapshot((tableDoc) => {
        if (tableDoc.exists) {
          const table = tableDoc.data();
          if (table.rights.hasOwnProperty(user) && table.rights[user] >= 4) {
            setWaiterName(table.waiterName);
            setSeats(table.seats.toString());
            setDisabledForm(false);
          } else {
            setWaiterName('');
            setSeats('');
            setDisabledForm(true);
            alert(Translator.t('components.organisms.forms.TableEditForm.error.noPermissions'));
          }
        }
      });
    return () => unsubscribe();
  }, []);

  return (
    <View>
      <TableWaiterNameInput errors={errors.hasOwnProperty('TableWaiterNameInput') ? errors.TableWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <TableSeatsInput errors={errors.hasOwnProperty('TableSeatsInput') ? errors.TableSeatsInput : {}} value={seats} onChange={(seats) => setSeats(seats)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.TableEditForm.submit.title')}</Button>
    </View>
  );
}

export default TableEditForm;

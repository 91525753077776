import React from 'react';
import { useSelector } from 'react-redux';

import ImageInput from '../../atoms/inputs/ImageInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function ProductImageInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <ImageInput
      label={Translator.t('components.molecules.inputs.ProductImageInput.label')}
      avatar
      {...props}
    />
  );
}

export default ProductImageInput;

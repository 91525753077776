import firebase from 'firebase';
import * as FirebaseCore from 'expo-firebase-core';

const firebaseConfig = {
  apiKey: FirebaseCore.DEFAULT_APP_OPTIONS.apiKey,
  authDomain: FirebaseCore.DEFAULT_APP_OPTIONS.authDomain,
  projectId: FirebaseCore.DEFAULT_APP_OPTIONS.projectId,
  storageBucket: FirebaseCore.DEFAULT_APP_OPTIONS.storageBucket,
  messagingSenderId: FirebaseCore.DEFAULT_APP_OPTIONS.messagingSenderId,
  appId: FirebaseCore.DEFAULT_APP_OPTIONS.appId,
  measurementId: FirebaseCore.DEFAULT_APP_OPTIONS.measurementId,
};

const Firebase = firebase.initializeApp(firebaseConfig);

export default Firebase;

import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import CompanyNameInput from '../../molecules/inputs/CompanyNameInput';
import {changeIsLoading, changeSelect, createSelect} from '../../../redux/reducers/PageReducer';
import CompanyCityInput from "../../molecules/inputs/CompanyCityInput";
import CompanyAddressLine1Input from "../../molecules/inputs/CompanyAddressLine1Input";
import CompanyAddressLine2Input from "../../molecules/inputs/CompanyAddressLine2Input";
import CompanyStateInput from "../../molecules/inputs/CompanyStateInput";
import CompanyPostalCodeInput from "../../molecules/inputs/CompanyPostalCodeInput";
import Countries from "../../../modules/Countries";
import PhoneCodes from "../../../modules/PhoneCodes";
import CompanyCountryInput from "../../molecules/inputs/CompanyCountryInput";
import CompanyPhoneCodeInput from "../../molecules/inputs/CompanyPhoneCodeInput";
import CompanyPhoneNumberInput from "../../molecules/inputs/CompanyPhoneNumberInput";
import { prepareLocale } from '../../../modules/Helper';

function CompanyCreateForm() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const [disabledForm, setDisabledForm] = useState(false);
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({});
  const countries = Countries();
  const phoneCodes = PhoneCodes();
  const validate = function () {
    const errors = {};
    if (typeof name !== 'string' || name.length === 0) {
      errors["CompanyNameInput"] = {
        "requiredField": {}
      };
    }
    if (!selects.hasOwnProperty('companyCreateFormPhoneCode') || !selects.companyCreateFormPhoneCode.hasOwnProperty('value') || selects.companyCreateFormPhoneCode.value === null) {
      errors["CompanyPhoneCodeInput"] = {
        "requiredField": {}
      };
    }
    if (typeof phoneNumber !== 'string' || phoneNumber.length === 0) {
      errors["CompanyPhoneNumberInput"] = {
        "requiredField": {}
      };
    }
    if (!selects.hasOwnProperty('companyCreateFormCountry') || !selects.companyCreateFormCountry.hasOwnProperty('value') || selects.companyCreateFormCountry.value === null) {
      errors["CompanyCountryInput"] = {
        "requiredField": {}
      };
    }
    if (typeof state !== 'string' || state.length === 0) {
      errors["CompanyStateInput"] = {
        "requiredField": {}
      };
    }
    if (typeof city !== 'string' || city.length === 0) {
      errors["CompanyCityInput"] = {
        "requiredField": {}
      };
    }
    if (typeof addressLine1 !== 'string' || addressLine1.length === 0) {
      errors["CompanyAddressLine1Input"] = {
        "requiredField": {}
      };
    }
    if (typeof addressLine2 !== 'string' || addressLine2.length === 0) {
      errors["CompanyAddressLine2Input"] = {
        "requiredField": {}
      };
    }
    if (typeof postalCode !== 'string' || postalCode.length === 0) {
      errors["CompanyPostalCodeInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('company').doc().set({
      name,
      state,
      city,
      addressLine1,
      addressLine2,
      postalCode,
      country: selects.companyCreateFormCountry.value,
      phoneCode: selects.companyCreateFormPhoneCode.value,
      phoneNumber,
      rights: {
        [user]: 7,
      },
    })
      .then(() => {
        setName('');
        setState('');
        setCity('');
        setAddressLine1('');
        setAddressLine2('');
        setPostalCode('');
        dispatch(changeSelect({
          id: 'companyCreateFormCountry',
          select: {
            value: null,
          },
        }));
        dispatch(changeSelect({
          id: 'companyCreateFormPhoneCode',
          select: {
            value: null,
          },
        }));
        setPhoneNumber('');
        alert(Translator.t('components.organisms.forms.CompanyCreateForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const countrySelectOptions = [];
    for (const [iso2, text] of Object.entries(countries.getNames(Translator.locale))) {
      countrySelectOptions.push({
        label: text,
        value: iso2,
      });
    }
    dispatch(createSelect({
      id: 'companyCreateFormCountry',
      select: {
        options: countrySelectOptions,
        enableSearch: true
      },
    }));
    const phoneCodeSelectOptions = [];
    for (const [iso2, phoneCode] of Object.entries(phoneCodes)) {
      phoneCodeSelectOptions.push({
        label: phoneCode + ' (' + iso2 + ')',
        value: phoneCode,
      });
    }
    dispatch(createSelect({
      id: 'companyCreateFormPhoneCode',
      select: {
        options: phoneCodeSelectOptions,
        enableSearch: true
      },
    }));
  }, []);

  return (
    <View>
      <CompanyNameInput errors={errors.hasOwnProperty('CompanyNameInput') ? errors.CompanyNameInput : {}} value={name} onChange={(name) => setName(name)} disabled={disabledForm} />
      <CompanyPhoneCodeInput errors={errors.hasOwnProperty('CompanyPhoneCodeInput') ? errors.CompanyPhoneCodeInput : {}} value={selects.hasOwnProperty('companyCreateFormPhoneCode') && selects.companyCreateFormPhoneCode.hasOwnProperty('value') ? selects.companyCreateFormPhoneCode.value : null} id="companyCreateFormPhoneCode" options={selects.hasOwnProperty('companyCreateFormPhoneCode') && selects.companyCreateFormPhoneCode.hasOwnProperty('options') ? selects.companyCreateFormPhoneCode.options : []} disabled={disabledForm}/>
      <CompanyPhoneNumberInput errors={errors.hasOwnProperty('CompanyPhoneNumberInput') ? errors.CompanyPhoneNumberInput : {}} value={phoneNumber} onChange={(phoneNumber) => setPhoneNumber(phoneNumber)} disabled={disabledForm} />
      <CompanyCountryInput errors={errors.hasOwnProperty('CompanyCountryInput') ? errors.CompanyCountryInput : {}} value={selects.hasOwnProperty('companyCreateFormCountry') && selects.companyCreateFormCountry.hasOwnProperty('value') ? selects.companyCreateFormCountry.value : null} id="companyCreateFormCountry" options={selects.hasOwnProperty('companyCreateFormCountry') && selects.companyCreateFormCountry.hasOwnProperty('options') ? selects.companyCreateFormCountry.options : []} disabled={disabledForm}/>
      <CompanyStateInput errors={errors.hasOwnProperty('CompanyStateInput') ? errors.CompanyStateInput : {}} value={state} onChange={(state) => setState(state)} disabled={disabledForm} />
      <CompanyCityInput errors={errors.hasOwnProperty('CompanyCityInput') ? errors.CompanyCityInput : {}} value={city} onChange={(city) => setCity(city)} disabled={disabledForm} />
      <CompanyAddressLine1Input errors={errors.hasOwnProperty('CompanyAddressLine1Input') ? errors.CompanyAddressLine1Input : {}} value={addressLine1} onChange={(addressLine1) => setAddressLine1(addressLine1)} disabled={disabledForm} />
      <CompanyAddressLine2Input errors={errors.hasOwnProperty('CompanyAddressLine2Input') ? errors.CompanyAddressLine2Input : {}} value={addressLine2} onChange={(addressLine2) => setAddressLine2(addressLine2)} disabled={disabledForm} />
      <CompanyPostalCodeInput errors={errors.hasOwnProperty('CompanyPostalCodeInput') ? errors.CompanyPostalCodeInput : {}} value={postalCode} onChange={(postalCode) => setPostalCode(postalCode)} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.CompanyCreateForm.submit.title')}</Button>
    </View>
  );
}

export default CompanyCreateForm;

import React from 'react';

import Row from '../atoms/layouts/Row';
import Col from '../atoms/layouts/Col';
import View from '../atoms/layouts/View';
import TableEditForm from '../organisms/forms/TableEditForm';
import DashboardLayout from '../atoms/layouts/DashboardLayout';
import { buildDocPath } from '../../modules/Helper';

function TableUpdateItem(props) {
  const companyId = props.route.params.companyId;
  const brandId = props.route.params.brandId;
  const storeId = props.route.params.storeId;
  const tableId = props.route.params.tableId;
  const path = buildDocPath({
    company: companyId,
    brand: brandId,
    store: storeId,
    table: tableId,
  });

  return (
    <DashboardLayout>
      <View>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <TableEditForm path={path} />
          </Col>
        </Row>
      </View>
    </DashboardLayout>
  );
}

export default TableUpdateItem;

import { DefaultTheme as PaperLightTheme, DarkTheme as PaperDarkTheme, configureFonts } from 'react-native-paper';
import { DefaultTheme as NavigationLightTheme, DarkTheme as NavigationDarkTheme } from '@react-navigation/native';
import merge from 'deepmerge';
import color from 'color';
import { black, pinkA100, pinkA400, white } from 'react-native-paper/src/styles/colors';

let LightTheme = merge(PaperLightTheme, NavigationLightTheme);
let DarkTheme = merge(PaperDarkTheme, NavigationDarkTheme);

const fontConfig = {
  web: {
    thin: {
      fontFamily: 'Inter_300Light',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Inter_400Regular',
      fontWeight: 'normal',
    },
    regular: {
      fontFamily: 'Inter_500Medium',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Inter_600SemiBold',
      fontWeight: 'normal',
    }
  },
  ios: {
    thin: {
      fontFamily: 'Inter_300Light',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Inter_400Regular',
      fontWeight: 'normal',
    },
    regular: {
      fontFamily: 'Inter_500Medium',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Inter_600SemiBold',
      fontWeight: 'normal',
    }
  },
  android: {
    thin: {
      fontFamily: 'Inter_300Light',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Inter_400Regular',
      fontWeight: 'normal',
    },
    regular: {
      fontFamily: 'Inter_500Medium',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Inter_600SemiBold',
      fontWeight: 'normal',
    }
  }
};

LightTheme = {
  ...PaperLightTheme,
  colors: {
    ...PaperLightTheme.colors,
    primary: '#377dff',
    accent: '#03dac4',
    background: '#f8fcfc',
    surface: white,
    error: '#B00020',
    text: black,
    onSurface: '#000000',
    disabled: color(black).alpha(0.26).rgb().string(),
    placeholder: color(black).alpha(0.54).rgb().string(),
    backdrop: color(black).alpha(0.5).rgb().string(),
    notification: pinkA400,
    border: color('#000000').alpha(0.12).rgb().string()
  },
  fonts: configureFonts(fontConfig),
};

DarkTheme = {
  ...PaperDarkTheme,
  colors: {
    ...PaperDarkTheme.colors,
    primary: '#377dff',
    accent: '#03dac6',
    background: '#20243c',
    surface: '#222B45',
    error: '#CF6679',
    onSurface: '#FFFFFF',
    text: white,
    disabled: color(white).alpha(0.38).rgb().string(),
    placeholder: color(white).alpha(0.54).rgb().string(),
    backdrop: color(black).alpha(0.5).rgb().string(),
    notification: pinkA100,
    border: color('#FFFFFF').alpha(0.12).rgb().string()
  },
  fonts: configureFonts(fontConfig),
};

export { LightTheme, DarkTheme };

const languages = require('@cospired/i18n-iso-languages');
const bgLanguageConfig = require('../localization/bg-ext.json');
const enLanguageConfig = require('@cospired/i18n-iso-languages/langs/en.json');
const deLanguageConfig = require('@cospired/i18n-iso-languages/langs/de.json');
const ruLanguageConfig = require('@cospired/i18n-iso-languages/langs/ru.json');
const itLanguageConfig = require('@cospired/i18n-iso-languages/langs/it.json');
const frLanguageConfig = require('@cospired/i18n-iso-languages/langs/fr.json');

function Languages() {
  languages.registerLocale(bgLanguageConfig);
  languages.registerLocale(enLanguageConfig);
  languages.registerLocale(deLanguageConfig);
  languages.registerLocale(ruLanguageConfig);
  languages.registerLocale(itLanguageConfig);
  languages.registerLocale(frLanguageConfig);
  return languages;
}

export default Languages;

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import SectionWaiterNameInput from '../../molecules/inputs/SectionWaiterNameInput';
import MenuInput from '../../molecules/inputs/MenuInput';
import {
  changeIsLoading,
  changeSelect,
  changeTranslations,
  createSelect,
  createTranslations
} from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import SectionNameInput from '../../molecules/inputs/SectionNameInput';
import SectionDescriptionInput from '../../molecules/inputs/SectionDescriptionInput';
import SectionNameTranslationsInput from '../../molecules/inputs/SectionNameTranslationsInput';
import SectionDescriptionTranslationsInput
  from '../../molecules/inputs/SectionDescriptionTranslationsInput';

function SectionCreateForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const translations = useSelector((state) => state.page.translations);
  const parentPath = props.hasOwnProperty('path') && props.path !== null ? props.path : null;
  const [disabledForm, setDisabledForm] = useState(true);
  const [waiterName, setWaiterName] = useState('');
  const [errors, setErrors] = useState({});
  const [countSubmitted, setCountSubmitted] = useState(0);
  const validate = function () {
    const errors = {};
    if (typeof waiterName !== 'string' || waiterName.length === 0) {
      errors["SectionWaiterNameInput"] = {
        "requiredField": {}
      };
    }
    if (Object.keys(translations.sectionCreateFormSectionName.hasOwnProperty('value') ? translations.sectionCreateFormSectionName.value : []).length === 0) {
      errors["SectionNameTranslationsInput"] = {
        "requiredTranslation": {}
      };
    }
    if (!selects.hasOwnProperty('sectionCreateFormMenu') || !selects.sectionCreateFormMenu.hasOwnProperty('value') || selects.sectionCreateFormMenu.value === null) {
      errors["MenuInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    const sectionNameTranslations = translations.sectionCreateFormSectionName.hasOwnProperty('value') ? translations.sectionCreateFormSectionName.value : [];
    const sectionDescriptionTranslations = translations.sectionCreateFormSectionDescription.hasOwnProperty('value') ? translations.sectionCreateFormSectionDescription.value : [];
    const menuDocParsedPath = parseDocPath(selects.sectionCreateFormMenu.value);
    Firebase.firestore().collection('company').doc(menuDocParsedPath.company).collection('brand')
      .doc(menuDocParsedPath.brand)
      .collection('menu')
      .doc(menuDocParsedPath.menu)
      .collection('section').get().then(function (sectionsRef) {
      const sectionsCount = sectionsRef.size;
      if (sectionsCount < 10) {
        Firebase.firestore().collection('company').doc(menuDocParsedPath.company).collection('brand')
          .doc(menuDocParsedPath.brand)
          .collection('menu')
          .doc(menuDocParsedPath.menu)
          .collection('section')
          .doc()
          .set({
            waiterName,
            nameTranslations: sectionNameTranslations,
            descriptionTranslations: sectionDescriptionTranslations,
            rights: {
              [user]: 7,
            },
          })
          .then(() => {
            setWaiterName('');
            dispatch(changeSelect({
              id: 'sectionCreateFormMenu',
              select: {
                value: parentPath,
              },
            }));
            dispatch(changeTranslations({
              id: 'sectionCreateFormSectionName',
              translations: {
                value: null,
              },
            }));
            dispatch(changeTranslations({
              id: 'sectionCreateFormSectionDescription',
              translations: {
                value: null,
              },
            }));
            setCountSubmitted(countSubmitted + 1);
            alert(Translator.t('components.organisms.forms.SectionCreateForm.success.title'));
          })
          .catch((error) => {
            alert(error.message);
          })
          .then(() => {
            setDisabledForm(false);
            dispatch(changeIsLoading(false));
          });
      }
      else {
        alert(Translator.t('components.organisms.forms.SectionCreateForm.error.sectionsMaxCount'));
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      }
    });
  };

  useEffect(() => {
    dispatch(createTranslations({
      id: 'sectionCreateFormSectionName',
      translations: {
        input: SectionNameInput,
      },
    }));
    dispatch(createTranslations({
      id: 'sectionCreateFormSectionDescription',
      translations: {
        input: SectionDescriptionInput,
      },
    }));
    const unsubscribe = Firebase.firestore().collectionGroup('menu').where(`rights.${user}`, '>=', 7).onSnapshot((menusRef) => {
      const menusDocs = menusRef.docs;
      const menuSelectOptions = [];
      menusDocs.forEach((menusDoc) => {
        const menuDocPath = menusDoc.ref.path;
        const menu = menusDoc.data();
        menusDoc.ref.collection('section').get().then(function (sectionsRef) {
          const sectionsCount = sectionsRef.size;
          if (sectionsCount > 9 && menuSelectOptions.filter((menuSelectOption) => menuSelectOption.hasOwnProperty('value') && menuSelectOption.value === menuDocPath)) {
            const indexToRemove = menuSelectOptions.findIndex(menuSelectOption => menuSelectOption.hasOwnProperty('value') && menuSelectOption.value === menuDocPath);
            if (indexToRemove > -1) {
              const newMenuSelectOptions = [...menuSelectOptions];
              newMenuSelectOptions[indexToRemove] = {
                disabled: true,
                error: Translator.t('components.organisms.forms.SectionCreateForm.error.sectionsMaxCount'),
                ...menuSelectOptions[indexToRemove]
              };
              dispatch(changeSelect({
                id: 'sectionCreateFormMenu',
                select: {
                  options: newMenuSelectOptions
                },
              }));
            }
          }
        });
        menuSelectOptions.push({
          icon: 'book-open',
          label: menu.waiterName,
          description: `ID: #${menusDoc.id}`,
          value: menuDocPath,
        });
      });
      dispatch(createSelect({
        id: 'sectionCreateFormMenu',
        select: {
          options: menuSelectOptions,
          value: parentPath
        },
      }));
      setDisabledForm(false);
    });
    return () => unsubscribe();
  }, [countSubmitted]);

  return (
    <View>
      <SectionWaiterNameInput errors={errors.hasOwnProperty('SectionWaiterNameInput') ? errors.SectionWaiterNameInput : {}} value={waiterName} onChange={(waiterName) => setWaiterName(waiterName)} disabled={disabledForm} />
      <SectionNameTranslationsInput errors={errors.hasOwnProperty('SectionNameTranslationsInput') ? errors.SectionNameTranslationsInput : {}} value={translations.hasOwnProperty('sectionCreateFormSectionName') && translations.sectionCreateFormSectionName.hasOwnProperty('value') ? translations.sectionCreateFormSectionName.value : null} id="sectionCreateFormSectionName" disabled={disabledForm} />
      <SectionDescriptionTranslationsInput value={translations.hasOwnProperty('sectionCreateFormSectionDescription') && translations.sectionCreateFormSectionDescription.hasOwnProperty('value') ? translations.sectionCreateFormSectionDescription.value : null} id="sectionCreateFormSectionDescription" disabled={disabledForm} />
      <MenuInput errors={errors.hasOwnProperty('MenuInput') ? errors.MenuInput : {}} value={selects.hasOwnProperty('sectionCreateFormMenu') && selects.sectionCreateFormMenu.hasOwnProperty('value') ? selects.sectionCreateFormMenu.value : null} id="sectionCreateFormMenu" options={selects.hasOwnProperty('sectionCreateFormMenu') && selects.sectionCreateFormMenu.hasOwnProperty('options') ? selects.sectionCreateFormMenu.options : []} disabled={disabledForm || parentPath !== null} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.SectionCreateForm.submit.title')}</Button>
    </View>
  );
}

export default SectionCreateForm;

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import Translator from '../../../modules/Translator';
import Firebase from '../../../modules/Firebase';
import BrandNameInput from '../../molecules/inputs/BrandNameInput';
import { changeIsLoading, changeSelect, createSelect } from '../../../redux/reducers/PageReducer';
import { parseDocPath, prepareLocale } from '../../../modules/Helper';
import BrandTagsInput from '../../molecules/inputs/BrandTagsInput';
import ImageManager from '../../atoms/buttons/ImageManager';
import CompanyInput from '../../molecules/inputs/CompanyInput';
import MenuInput from '../../molecules/inputs/MenuInput';

function BrandEditForm(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const parsedDocPath = parseDocPath(props.path);
  const companyId = parsedDocPath.company;
  const brandId = parsedDocPath.brand;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selects = useSelector((state) => state.page.selects);
  const [disabledForm, setDisabledForm] = useState(true);
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState('');
  const [tags, setTags] = useState('');
  const [errors, setErrors] = useState({});
  const validate = function () {
    const errors = {};
    if (typeof name !== 'string' || name.length === 0) {
      errors["BrandNameInput"] = {
        "requiredField": {}
      };
    }
    setErrors(errors);
    return errors;
  };
  const handleSubmit = function () {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    }
    const activeMenu = selects.brandEditFormMenu.hasOwnProperty('value') ? selects.brandEditFormMenu.value : null;
    dispatch(changeIsLoading(true));
    setDisabledForm(true);
    Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .set({
        name,
        tags: tags.trim().split(',').filter((tag) => tag.length > 0),
        activeMenu
      }, {
        merge: true,
      })
      .then(() => {
        alert(Translator.t('components.organisms.forms.BrandEditForm.success.title'));
      })
      .catch((error) => {
        alert(error.message);
      })
      .then(() => {
        setDisabledForm(false);
        dispatch(changeIsLoading(false));
      });
  };

  useEffect(() => {
    const unsubscribeMenus = Firebase.firestore().doc(props.path).collection('menu').where(`rights.${user}`, '>=', 7).onSnapshot((menusRef) => {
      const menusDocs = menusRef.docs;
      const menuSelectOptions = [];
      menusDocs.forEach((menusDoc) => {
        const menuDocPath = menusDoc.ref.path;
        const menu = menusDoc.data();
        menuSelectOptions.push({
          icon: 'file-document',
          label: menu.waiterName,
          description: `ID: #${menusDoc.id}`,
          value: menuDocPath,
        });
      });
      dispatch(createSelect({
        id: 'brandEditFormMenu',
        select: {
          options: menuSelectOptions
        },
      }));
    });
    const unsubscribe = Firebase.firestore().collection('company').doc(companyId).collection('brand')
      .doc(brandId)
      .onSnapshot((brandDoc) => {
        if (brandDoc.exists) {
          const brand = brandDoc.data();
          if (brand.rights.hasOwnProperty(user) && brand.rights[user] >= 4) {
            setLogo(brand.logo);
            setName(brand.name);
            setTags(brand.tags.join(','));
            dispatch(changeSelect({
              id: 'brandEditFormMenu',
              select: {
                value: brand.hasOwnProperty('activeMenu') ? brand.activeMenu : null
              },
            }));
            setDisabledForm(false);
          } else {
            setLogo(null);
            setName('');
            setTags('');
            dispatch(changeSelect({
              id: 'brandEditFormMenu',
              select: {
                value: null
              },
            }));
            setDisabledForm(true);
            alert(Translator.t('components.organisms.forms.BrandEditForm.error.noPermissions'));
          }
        }
      });
    return () => {
      unsubscribeMenus();
      unsubscribe();
    };
  }, []);

  return (
    <View>
      <ImageManager path={props.path} property='logo' avatar={true}/>
      <BrandNameInput errors={errors.hasOwnProperty('BrandNameInput') ? errors.BrandNameInput : {}} value={name} onChange={(name) => setName(name)} disabled={disabledForm} />
      <BrandTagsInput value={tags} onChange={(tags) => setTags(tags)} disabled={disabledForm} />
      <MenuInput value={selects.hasOwnProperty('brandEditFormMenu') && selects.brandEditFormMenu.hasOwnProperty('value') ? selects.brandEditFormMenu.value : null} id="brandEditFormMenu" options={selects.hasOwnProperty('brandEditFormMenu') && selects.brandEditFormMenu.hasOwnProperty('options') ? selects.brandEditFormMenu.options : []} disabled={disabledForm} />
      <Button mode="contained" onPress={handleSubmit} disabled={disabledForm}>{Translator.t('components.organisms.forms.BrandEditForm.submit.title')}</Button>
    </View>
  );
}

export default BrandEditForm;

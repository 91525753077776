import React from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Modal as DefaultModal, Headline, Caption, useTheme, Button } from 'react-native-paper';

import Row from '../layouts/Row';
import Col from '../layouts/Col';
import Translator from '../../../modules/Translator';
import { changeModal } from '../../../redux/reducers/PageReducer';
import Config from '../../../Config';
import { prepareLocale } from '../../../modules/Helper';

function Modal() {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.page.modal);
  const theme = useTheme();
  const width = function () {
    if (Dimensions.get('window').width <= Config.dimensions.sm) {
      return '96%';
    }
    else if (Dimensions.get('window').width > Config.dimensions.sm && Dimensions.get('window').width <= Config.dimensions.md) {
      return '90%';
    }
    else if (Dimensions.get('window').width > Config.dimensions.md && Dimensions.get('window').width <= Config.dimensions.lg) {
      return '86%';
    }
    else if (Dimensions.get('window').width > Config.dimensions.lg && Dimensions.get('window').width <= Config.dimensions.xl) {
      return '80%';
    }
    else if (Dimensions.get('window').width > Config.dimensions.xl && Dimensions.get('window').width <= Config.dimensions.xxl) {
      return '70%';
    }
    else {
      return '60%';
    }
  };
  const styles = StyleSheet.create({
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    modalContent: {
      backgroundColor: theme.colors.background,
      borderRadius: 5,
      width: width(),
      paddingLeft: '10%',
      paddingRight: '10%',
      paddingTop: 50,
      paddingBottom: 50
    },
    buttonsContainer: {
      marginTop: 25
    },
    buttonCol: {
      paddingLeft: 2,
      paddingRight: 2,
    },
  });

  const confirmActions = [{
    "title": Translator.t('components.atoms.elements.modal.templates.confirmModal.actions.no.title'),
    "mode": "outlined",
    "onPress": function () {
      dispatch(changeModal(null));
    }
  }];
  if (modal !== null && modal.hasOwnProperty('data') && modal.data.hasOwnProperty('confirmed')) {
    confirmActions.unshift({
      "title": Translator.t('components.atoms.elements.modal.templates.confirmModal.actions.yes.title'),
      "mode": "contained",
      "onPress": function () {
        modal.data.confirmed();
      }
    });
  }
  const confirmModal = {
    "title": Translator.t('components.atoms.elements.modal.templates.confirmModal.title'),
    "description": Translator.t('components.atoms.elements.modal.templates.confirmModal.description'),
    "actions": confirmActions
  };

  let data = null;

  if (modal !== null && modal.hasOwnProperty('type') && modal.type === "confirmModal") {
    data = confirmModal;
  }
  else {
    data = modal;
  }

  return (
    <DefaultModal visible={data !== null} onDismiss={() => changeModal(null)} style={styles.modalContainer} contentContainerStyle={styles.modalContent}>
      {
        modal !== null ? (
          <View>
            <Headline>{data.title}</Headline>
            <Caption>{data.description}</Caption>
            { data.hasOwnProperty('actions') && data.actions.length > 0 ? (
              <Row style={styles.buttonsContainer}>
                { data.actions.map((action, actionKey) => (
                  <Col style={styles.buttonCol} xs={12 / data.actions.length} sm={12 / data.actions.length} md={12 / data.actions.length} lg={12 / data.actions.length} key={actionKey}>
                    <Button mode={action.mode} onPress={action.onPress}>{ action.title }</Button>
                  </Col>
                )) }
              </Row>
            ) : null}
          </View>
        ) : null
      }
    </DefaultModal>
  );
}

export default Modal;

import React from 'react';
import { useSelector } from 'react-redux';

import FloatInput from '../../atoms/inputs/FloatInput';
import Translator from '../../../modules/Translator';
import { prepareLocale } from '../../../modules/Helper';

function ProductPriceInput(props) {
  Translator.locale = prepareLocale(useSelector((state) => state.intl.locale));

  return (
    <FloatInput
      label={Translator.t('components.molecules.inputs.ProductPriceInput.label')}
      placeholder={Translator.t('components.molecules.inputs.ProductPriceInput.placeholder')}
      icon="dots-horizontal"
      {...props}
    />
  );
}

export default ProductPriceInput;
